import React, { useEffect, useState } from "react";
import "./videoUseCase.css";
import {
  Button,
  Col,
  ConfigProvider,
  Flex,
  Input,
  Row,
  Skeleton,
  Steps,
  FloatButton,
} from "antd";
import { PRIMARY_COLOR } from "../../utils/colors";
import { useOutletContext, useParams } from "react-router-dom";
import {
  getAudioProfiles,
  getUseCaseAudio,
  getVideoUseCaseById,
  updateUseCase,
} from "../../api/adminService";
import ScriptUpdation from "../../components/videoUseCases/scriptUpdation/scriptUpdation";
import AudioUpdation from "../../components/videoUseCases/audioUpdation/audioUpdation";
import VideoGeneration from "../../components/videoUseCases/videoGeneration/videoGeneration";
import DIYQuestions from "../../components/videoUseCases/DIYQuestions/DIYQuestions";
import { ReactComponent as Edit } from "../../assets/images/editPen.svg";
import ChatBot from "../../components/chatbot/chatbot";
import {
  createGeneralChannel,
  getGeneralChannel,
} from "../../api/profileServices";
import {
  CommentOutlined,
  CloseOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import { endAt, limitToLast, onChildAdded, ref } from "firebase/database";
import { dataBase } from "../../components/firebase/firebase";
import { query } from "firebase/firestore";
import {
  audioSection,
  audioStages,
  capitalizeInput,
  videoStages,
} from "../../utils/format";

const CustomHeader = ({
  title,
  titleDisabled,
  setTitleDisabled,
  setTitle,
  updateTitle,
  usecaseData,
}) => {
  return (
    <Flex flex={1} justify="space-between" align="center" gap={50}>
      {titleDisabled ? (
        <span className="usecase-header-title">{title}</span>
      ) : (
        <Input
          value={title}
          className={`usecase-title-input ${!titleDisabled ? "active" : ""}  ${
            title.error ? "is-invalid" : ""
          }`}
          disabled={titleDisabled}
          defaultValue={""}
          id="journey-title"
          onChange={(e) => setTitle(e.target.value)}
        />
      )}
      {titleDisabled ? (
        <Button
          className="edit-title"
          onClick={() => setTitleDisabled(false)}
          icon={<Edit />}
          color="white"
        >
          Edit Title
        </Button>
      ) : (
        <Flex gap={20}>
          <Button
            className="edit-title"
            onClick={() => updateTitle()}
            color="white"
          >
            <CheckOutlined />
          </Button>
          <Button
            className="edit-title"
            onClick={() => {
              setTitleDisabled(true);
              setTitle(usecaseData?.title);
            }}
            color="white"
          >
            <CloseOutlined />
          </Button>
        </Flex>
      )}
    </Flex>
  );
};

const VideoUseCase = () => {
  const params = useParams();
  const { type, useCaseId } = params;
  const { Step } = Steps;
  const [current, setCurrent] = useState(0);
  const [, , setDisplayName] = useOutletContext();
  const [channel, setchannel] = useState("");
  const [usecaseData, setUsecaseData] = useState();
  const [title, setTitle] = useState();
  const [titleDisabled, setTitleDisabled] = useState(true);
  const [, , , , userDetails] = useOutletContext();
  const [isChatbotOpen, setIsChatbotOpen] = useState(false);
  const [scriptParts, setScriptParts] = useState();
  const [videoId, setVideoId] = useState();
  const [status, setStatus] = useState();
  const [videoStatus, setVideoStatus] = useState(0);
  const [selectedAudioProfile, setSelectedAudioProfile] = useState(null);
  const [selectedAvatarProfile, setSelectedAvatarProfile] = useState(null);
  const [audioProfiles, setAudioProfiles] = useState([]);
  const [voiceSetting, setVoiceSetting] = useState();

  const commonSteps = [
    {
      key: "script",
      title: "Script",
      content: (
        <ScriptUpdation
          type={type}
          usecaseData={usecaseData}
          title={title || usecaseData?.title}
          setTitleDisabled={setTitleDisabled}
          titleDisabled={titleDisabled}
          setTitle={setTitle}
          useCaseId={useCaseId}
          setUsecaseData={setUsecaseData}
          status={status}
          setStatus={setStatus}
          setCurrent={setCurrent}
          setScriptParts={setScriptParts}
          selectedAudioProfile={selectedAudioProfile}
          setSelectedAudioProfile={setSelectedAudioProfile}
          audioProfiles={audioProfiles}
        />
      ),
    },
    {
      key: "audio",
      title: "Audio",
      content: (
        <AudioUpdation
          type={type}
          usecaseData={usecaseData}
          scriptParts={scriptParts}
          useCaseId={useCaseId}
          setScriptParts={setScriptParts}
          setStatus={setStatus}
          status={status}
          setCurrent={setCurrent}
          selectedAudioProfile={selectedAudioProfile}
          setSelectedAudioProfile={setSelectedAudioProfile}
          selectedAvatarProfile={selectedAvatarProfile}
          setSelectedAvatarProfile={setSelectedAvatarProfile}
          audioProfiles={audioProfiles}
          setVoiceSetting={setVoiceSetting}
          voiceSetting={voiceSetting}
        />
      ),
    },
    {
      key: "video",
      title: "Video",
      content: (
        <VideoGeneration
          type={type}
          usecaseData={usecaseData}
          status={status}
          videoStatus={videoStatus}
          useCaseId={useCaseId}
          selectedAvatarProfile={selectedAvatarProfile}
          videoId={videoId}
        />
      ),
    },
  ];

  const steps = {
    diy: [
      {
        key: "question",
        title: "DIY questions",
        content: (
          <DIYQuestions
            usecaseData={usecaseData}
            title={title}
            useCaseId={useCaseId}
            setUsecaseData={setUsecaseData}
            setCurrent={setCurrent}
          />
        ),
      },
      ...commonSteps,
    ],
    training: commonSteps,
    personalized: commonSteps,
  };

  const currentSteps = steps[type];

  const onChange = (value) => {
    setCurrent(value);
  };

  const handleChange = (status, finalScript) => {
    if (status === "CREATED" && type === "diy" && finalScript) {
      setCurrent(1);
    } else if (audioSection.includes(status)) {
      setCurrent(type === "diy" ? 2 : 1);
    } else if (videoStages.includes(status)) {
      setCurrent(type === "diy" ? 3 : 2);
    } else {
      setCurrent(0);
    }
  };

  const updateTitle = () => {
    updateUseCase(useCaseId, { title: title })
      .then((res) => {
        setTitleDisabled(true);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    const fetchAudioProfiles = async () => {
      try {
        const res = await getAudioProfiles();
        setAudioProfiles(res.data);
      } catch (error) {
        console.error("Error fetching audio profiles:", error);
      }
    };
    fetchAudioProfiles();
  }, []);

  useEffect(() => {
    if (useCaseId) {
      getVideoUseCaseById(useCaseId)
        .then((res) => {
          setUsecaseData(res?.data);
          setDisplayName([capitalizeInput(res?.data?.type), res?.data?.title]);
          setVideoId(res?.data?.videoId);
          setStatus(res?.data?.state);
          setTitle(res?.data?.title);
          setVoiceSetting(res?.info?.voiceSettings);
          handleChange(res?.data?.state, res?.data?.finalScript);
          if (res.data?.audioData && res.data?.audioData?.length > 0) {
            const parts = JSON.parse(JSON.stringify(res.data?.audioData));
            const updatedParts = parts.map((part) => ({
              ...part,
              type: part?.segmentType,
            }));
            setScriptParts(updatedParts);
          } else if (
            res.data?.scriptParts &&
            res.data?.scriptParts?.length > 0
          ) {
            const parts = JSON.parse(JSON.stringify(res.data?.scriptParts));
            setScriptParts(parts);
          }
        })
        .catch((err) => {});
    }
  }, [useCaseId]);

  useEffect(() => {
    getGeneralChannel()
      .then((res) => {
        setchannel(res.data._id);
      })
      .catch((err) => {
        console.log(err);
        if (err?.response?.data?.message === "Failed to fetch the channel") {
          createGeneralChannel()
            .then((res) => {
              setchannel(res.data._id);
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
  }, []);

  useEffect(() => {
    let unsubscribe;

    if (videoId && status === "AUDIO_GENERATION_INPROGRESS") {
      const channelRef = query(
        ref(dataBase, `audio/${videoId}`),
        endAt,
        limitToLast(1)
      );
      unsubscribe = onChildAdded(channelRef, (snapshot) => {
        const message = snapshot.val();
        if (message?.status === "AUDIO_GENERATED") {
          setTimeout(() => {
            getUseCaseAudio(useCaseId)
              .then((res) => {
                const audioData = res?.data?.map((result) => ({
                  url: result.url,
                  _id: result._id,
                }));
                const updatedScriptParts = scriptParts.map((part, index) => ({
                  ...part,
                  url: audioData[index]?.url,
                  _id: audioData[index]?._id,
                }));

                setScriptParts(updatedScriptParts);
                setStatus("AUDIO_GENERATION_COMPLETED");
              })
              .catch((err) => {});
          }, 5000);
        } else {
        }
      });
    }
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [videoId, useCaseId, status]);

  useEffect(() => {
    let unsubscribe;
    if (videoId) {
      const channelRef = query(
        ref(dataBase, `leads/videos/${videoId}`),
        endAt,
        limitToLast(1)
      );
      unsubscribe = onChildAdded(channelRef, (snapshot) => {
        const message = snapshot.val();
        console.log(message);
        if (message?.status === "completed") {
          setTimeout(() => {
            setUsecaseData((prev) => ({
              ...prev,
              videoData: { url: message?.url },
            }));

            setStatus("VIDEO_GENERATION_COMPLETED");
            setVideoStatus("completed");
          }, 5000);
        } else {
          setVideoStatus(message.status);
        }
      });
    }
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [videoId]);

  const isStepDisabled = (status, index, data) => {
    if (status === "AUDIO_GENERATION_INPROGRESS") {
      return true;
    }
    if (index === 0) {
      return false;
    }
    if (index === 1) {
      if (type !== "diy") {
        return !audioStages.includes(status);
      } else {
        return !data.finalscript;
      }
    }
    if (index === 2) {
      if (type !== "diy") {
        return !videoStages.includes(status);
      } else {
        return !audioStages.includes(status);
      }
    }
    if (index === 3) {
      return type !== "diy" || !videoStages.includes(status);
    }
    return true;
  };

  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: '"Poppins", sans-serif',
          Input: {
            colorBorder: "transparent",
            colorBgContainerDisabled: "transparent",
            hoverBorderColor: PRIMARY_COLOR,
            activeBorderColor: PRIMARY_COLOR,
            activeShadow: "none",
            controlHeight: 45,
            colorTextPlaceholder: "#9EA5AD",
          },
          InputNumber: {
            colorBorder: "transparent",
            colorBgContainerDisabled: "transparent",
            hoverBorderColor: PRIMARY_COLOR,
            activeBorderColor: PRIMARY_COLOR,
            activeShadow: "none",
            controlHeight: 45,
            colorTextPlaceholder: "#9EA5AD",
            controlWidth: 250,
          },
          Button: {
            colorPrimary: PRIMARY_COLOR,
            colorPrimaryHover: PRIMARY_COLOR,
            colorPrimaryActive: PRIMARY_COLOR,
            primaryShadow: "none",
            controlHeight: 45,
          },
        },
        components: {
          Layout: {
            headerBg: "#ffffff",
            headerPadding: "20px",
          },
          Steps: {
            colorPrimary: PRIMARY_COLOR,
            padding: 0,
          },
          Slider: {
            colorPrimary: PRIMARY_COLOR,
            handleColor: PRIMARY_COLOR,
            handleHoverColor: PRIMARY_COLOR,
            handleActiveColor: PRIMARY_COLOR,
            trackBg: PRIMARY_COLOR,
            trackHoverBg: PRIMARY_COLOR,
            handleActiveOutlineColor: PRIMARY_COLOR,
            colorPrimaryBorderHover: PRIMARY_COLOR,
            dotActiveBorderColor: PRIMARY_COLOR,
          },
          Checkbox: {
            colorPrimary: PRIMARY_COLOR,
            colorPrimaryHover: PRIMARY_COLOR,
            controlInteractiveSize: 25,
          },
        },
      }}
    >
      <Row className="video-usecase" align="center">
        {usecaseData ? (
          <Col span={20} className="use-case">
            <Row align="left" className="">
              <Col span={15}>
                {
                  <Steps current={current} onChange={onChange}>
                    {currentSteps?.map((step, index) => (
                      <Step
                        key={step.title}
                        title={step.title}
                        responsive={false}
                        disabled={isStepDisabled(status, index, usecaseData)}
                      />
                    ))}
                  </Steps>
                }
              </Col>
            </Row>
            {currentSteps[current].key !== "script" ? (
              <Row className="usecase-content" align="center">
                <Col className="usecase-content-wrapper" span={24}>
                  <Row>
                    <Col className="usecase-header" span={24}>
                      {usecaseData?.title ? (
                        <CustomHeader
                          title={title}
                          setTitleDisabled={setTitleDisabled}
                          titleDisabled={titleDisabled}
                          setTitle={setTitle}
                          updateTitle={updateTitle}
                          usecaseData={usecaseData}
                        />
                      ) : (
                        <Skeleton.Input />
                      )}
                    </Col>
                  </Row>

                  {currentSteps[current].content}
                </Col>
              </Row>
            ) : (
              currentSteps[current].content
            )}
          </Col>
        ) : (
          <Flex
            flex={1}
            className="course-content"
            justify="center"
            align="center"
            vertical
          >
            <h3>Loading video details...</h3>
            <p>Please wait while we retrieve the video details.</p>
            <div
              className="video-loader"
              style={{ width: "100%", marginTop: "20px" }}
            >
              <div className="loader"></div>
            </div>
          </Flex>
        )}
      </Row>

      <FloatButton
        icon={<CommentOutlined />}
        tooltip={<div>Chat</div>}
        className="chatbot-button"
        onClick={() => setIsChatbotOpen(!isChatbotOpen)}
      />

      <div className={`chatbot-popup ${isChatbotOpen ? "open" : "closed"}`}>
        <ChatBot
          channel={channel}
          type={"Video"}
          userDetails={userDetails || {}}
        />
      </div>
    </ConfigProvider>
  );
};

export default VideoUseCase;
