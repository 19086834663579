import AxiosInstance from "./axiosInstance";
import { setToken } from "../services/storageService";
import axios from "axios";
import VoiceSettings from "../components/modals/voiceSettings";

export const loginService = async (email = "", password = "") => {
  try {
    const response = await AxiosInstance.post(
      "/admin/auth/login",
      {
        email,
        password,
      },
      { headers: { "Content-Type": "application/json" } }
    );
    if (response?.data?.data) {
      setToken(
        response?.data?.data?.accessToken,
        response?.data?.data?.refreshToken
      );
      return response.data;
    } else {
      throw new Error("Login failed");
    }
  } catch (error) {
    throw error;
  }
};
let source;
export const getCategories = async (
  searchKey,
  status,
  limit,
  pageNo,
  sortField,
  sortOrder
) => {
  let query = "?";
  if (searchKey?.length > 0) {
    query = query + `searchKey=${searchKey}&`;
  }
  if (status) {
    query = query + `status=${status}&`;
  }
  if (limit) {
    query = query + `limit=${limit}&`;
  }
  if (pageNo > 0) {
    query = query + `pageNo=${pageNo}&`;
  }
  if (sortField) {
    query = query + `sortField=${sortField}&`;
  }
  if (sortOrder) {
    query = query + `sortOrder=${sortOrder}`;
  }
  if (source && searchKey) {
    source.cancel();
  }
  const CancelToken = axios.CancelToken;
  source = CancelToken.source();
  try {
    const response = await AxiosInstance.get(`/admin/category` + query, {
      cancelToken: source.token,
    });
    if (response?.data) {
      return response.data;
    } else {
      throw new Error("category fetch failed");
    }
  } catch (error) {
    throw error;
  }
};
export const journeyConfig = async (data) => {
  try {
    const response = await AxiosInstance.post("/admin/journey-config", data);
    if (response?.data) {
      return response.data;
    } else {
      throw new Error("journey config failed");
    }
  } catch (error) {
    throw error;
  }
};
export const updateJourneyConfig = async (journeyId, data) => {
  try {
    const response = await AxiosInstance.put(
      `/admin/journey-config/${journeyId}`,
      data
    );
    if (response?.data) {
      return response.data;
    } else {
      throw new Error("journey config failed");
    }
  } catch (error) {
    throw error;
  }
};
export const addNewCategoryItem = async (data) => {
  try {
    const response = await AxiosInstance.post("/admin/category", data);
    if (response?.data) {
      return response.data;
    } else {
      throw new Error("Login failed");
    }
  } catch (error) {
    throw error;
  }
};
export const getJourneys = async (
  searchKey,
  status,
  type,
  category,
  limit,
  pageNo,
  sortField,
  sortOrder,
  all = true
) => {
  let query = "?";
  if (searchKey?.length > 0) {
    query = query + `searchKey=${searchKey}&`;
  }
  if (status) {
    query = query + `status=${status}&`;
  }
  if (type) {
    query = query + `type=${type}&`;
  }
  if (category.length > 0 && all) {
    category.forEach((item) => {
      query = query + `categoryId=${item}&`;
    });
  }
  if (category && !all) {
    query = query + `categoryId=${category}&`;
  }
  if (limit) {
    query = query + `limit=${limit}&`;
  }
  if (pageNo > 0) {
    query = query + `pageNo=${pageNo}&`;
  }
  if (sortField) {
    query = query + `sortField=${sortField}&`;
  }
  if (sortOrder) {
    query = query + `sortOrder=${sortOrder}`;
  }
  try {
    const response = await AxiosInstance.get(`/admin/journey-config` + query);
    if (response?.data) {
      return response.data;
    } else {
      throw new Error("category fetch failed");
    }
  } catch (error) {
    throw error;
  }
};
export const getAllVideos = async (
  searchKey,
  state,
  type,
  limit,
  pageNo,
  sortField,
  sortOrder,
  all = true
) => {
  let query = "?";
  if (searchKey?.length > 0) {
    query = query + `searchKey=${searchKey}&`;
  }
  if (state?.length > 0) {
    state.forEach((s) => {
      query += `state=${s}&`; // Add each state to the query string
    });
  }

  if (type?.length > 0) {
    type.forEach((s) => {
      query += `type=${s}&`; // Add each state to the query string
    });
  }
  if (limit) {
    query = query + `limit=${limit}&`;
  }
  if (pageNo > 0) {
    query = query + `pageNo=${pageNo}&`;
  }
  if (sortField) {
    query = query + `sortField=${sortField}&`;
  }
  if (sortOrder) {
    query = query + `sortOrder=${sortOrder}`;
  }
  try {
    const response = await AxiosInstance.get(`/admin/use-case` + query);
    if (response?.data) {
      return response.data;
    } else {
      throw new Error("videos fetch failed");
    }
  } catch (error) {
    throw error;
  }
};
export const getJourneyDetails = async (id) => {
  try {
    const response = await AxiosInstance.get(`/admin/journey-config/${id}`);
    if (response?.data) {
      return response.data;
    } else {
      throw new Error("journey config failed");
    }
  } catch (error) {
    throw error;
  }
};
export const createChannel = async (id) => {
  try {
    const response = await AxiosInstance.post(
      `/admin/journey-config/${id}/channel`
    );
    if (response?.data) {
      return response.data;
    } else {
      throw new Error("journey config failed");
    }
  } catch (error) {
    throw error;
  }
};
export const getChannel = async (id) => {
  try {
    const response = await AxiosInstance.get(
      `/admin/journey-config/${id}/channel`
    );
    if (response?.data) {
      return response.data;
    } else {
      throw new Error("channel id fetch failed");
    }
  } catch (error) {
    throw error;
  }
};
export const sendMessage = async (id, channelId, thread, data) => {
  let query = "?";
  if (thread) {
    query = query + `threadCtxId=${thread}`;
  }
  try {
    const response = await AxiosInstance.post(
      `/admin/journey-config/${id}/channel/${channelId}/message` + query,
      data
    );
    if (response?.data) {
      return response.data;
    } else {
      throw new Error("sending message failed");
    }
  } catch (error) {
    throw error;
  }
};
export const getPreviousMessages = async (
  id,
  channelId,
  thread,
  limit,
  pageNo
) => {
  let query = "?";
  if (thread) {
    query = query + `threadCtxId=${thread}&`;
  }
  if (limit) {
    query = query + `limit=${limit}&`;
  }
  if (pageNo > 0) {
    query = query + `pageNo=${pageNo}&`;
  }
  try {
    const response = await AxiosInstance.get(
      `/admin/journey-config/${id}/channel/${channelId}/thread` + query
    );
    if (response?.data) {
      return response.data;
    } else {
      throw new Error("message fetchfailed");
    }
  } catch (error) {
    throw error;
  }
};
export const getLatestMessages = async (id, channelId, messageId) => {
  try {
    const response = await AxiosInstance.get(
      `/admin/journey-config/${id}/channel/${channelId}/message/${messageId}`
    );
    if (response?.data) {
      return response.data;
    } else {
      throw new Error("message fetch failed");
    }
  } catch (error) {
    throw error;
  }
};
export const deleteCategory = async (id) => {
  try {
    const response = await AxiosInstance.delete(`/admin/category/${id}`);
    if (response?.data) {
      return response.data;
    } else {
      throw new Error("category delete failed");
    }
  } catch (error) {
    throw error;
  }
};
export const updateCategory = async (id, data) => {
  try {
    const response = await AxiosInstance.put(`/admin/category/${id}`, data);
    if (response?.data) {
      return response.data;
    } else {
      throw new Error("category delete failed");
    }
  } catch (error) {
    throw error;
  }
};
export const deleteJourney = async (id) => {
  try {
    const response = await AxiosInstance.delete(`/admin/journey-config/${id}`);
    if (response?.data) {
      return response.data;
    } else {
      throw new Error("journey deletion failed");
    }
  } catch (error) {
    throw error;
  }
};
export const deleteSelectedJourneys = async (id) => {
  let query = "?journeyConfigIds=";
  if (id.length > 0) {
    id.forEach((item, index) => {
      query += `${item}${index !== id.length - 1 ? "," : ""}`;
    });
  }
  try {
    const response = await AxiosInstance.delete(
      `/admin/journey-config` + query
    );
    if (response?.data) {
      return response.data;
    } else {
      throw new Error("journey deletion failed");
    }
  } catch (error) {
    throw error;
  }
};
export const uploadImage = async (id, data) => {
  try {
    const response = await AxiosInstance.post(
      `/admin/journey-config/${id}/thumbnail`,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    if (response?.data) {
      return response.data;
    } else {
      throw new Error("thumbnail upload failed");
    }
  } catch (error) {
    throw error;
  }
};
export const deleteImage = async (id, data) => {
  try {
    const response = await AxiosInstance.delete(
      `/admin/journey-config/${id}/thumbnail`
    );
    if (response?.data) {
      return response.data;
    } else {
      throw new Error("thumbnail upload failed");
    }
  } catch (error) {
    throw error;
  }
};
let sources;
const CancelTokens = axios.CancelToken;
sources = CancelTokens.source();
export const createFileRecord = async (data) => {
  try {
    const response = await AxiosInstance.post(`/admin/files/create`, data, {
      cancelToken: sources.token,
    });
    if (response?.data) {
      return response.data;
    } else {
      throw new Error("file record creation failed");
    }
  } catch (error) {
    throw error;
  }
};

export const UploadFile = async (id) => {
  try {
    const response = await AxiosInstance.get(
      `/admin/files/${id}/upload-signature`,
      {
        cancelToken: sources.token,
      }
    );
    if (response?.data) {
      return response.data;
    } else {
      throw new Error("file record creation failed");
    }
  } catch (error) {
    throw error;
  }
};
export const getFileUrl = async (id) => {
  let query = "?fileIds=";
  if (id.length > 0) {
    id.forEach((item, index) => {
      query += `${item}${index !== id.length - 1 ? "," : ""}`;
    });
  }
  try {
    const response = await AxiosInstance.get(`/admin/files` + query);
    if (response?.data) {
      return response.data;
    } else {
      throw new Error("file record creation failed");
    }
  } catch (error) {
    throw error;
  }
};
export const cancelToken = () => {
  sources.cancel();
};
export const updateProgress = async (id, data) => {
  try {
    const response = await AxiosInstance.patch(
      `/admin/files/${id}/upload-progress`,
      data
    );
    if (response?.data) {
      return response.data;
    } else {
      throw new Error("file record creation failed");
    }
  } catch (error) {
    throw error;
  }
};
export const uploadFiles = async (url, data) => {
  try {
    const response = await AxiosInstance.put(url, data, {
      cancelToken: sources.token,
    });
    if (response?.data) {
      return response.data;
    } else {
      throw new Error("file record creation failed");
    }
  } catch (error) {
    throw error;
  }
};
export const getUsers = async (
  searchKey,
  status,
  organizationId,
  appRole,
  organizationRole,
  limit,
  pageNo,
  sortField,
  sortOrder,
  all = true
) => {
  let query = "?";
  if (searchKey?.length > 0) {
    query = query + `searchKey=${searchKey}&`;
  }
  if (status) {
    query = query + `status=${status}&`;
  }
  if (organizationId.length > 0 && all) {
    organizationId.forEach((item) => {
      query = query + `organizationId=${item}&`;
    });
  }
  if (appRole) {
    query = query + `appRole=${appRole}&`;
  }
  if (organizationRole) {
    query = query + `organizationRole=${organizationRole}&`;
  }
  if (limit) {
    query = query + `limit=${limit}&`;
  }
  if (pageNo > 0) {
    query = query + `pageNo=${pageNo}&`;
  }
  if (sortField) {
    query = query + `sortField=${sortField}&`;
  }
  if (sortOrder) {
    query = query + `sortOrder=${sortOrder}`;
  }
  try {
    const response = await AxiosInstance.get(`/admin/staff` + query);
    if (response?.data) {
      return response.data;
    } else {
      throw new Error("category fetch failed");
    }
  } catch (error) {
    throw error;
  }
};
export const getOrganizations = async (
  searchKey,
  status,
  limit,
  pageNo,
  sortField,
  sortOrder
) => {
  let query = "?";
  if (searchKey?.length > 0) {
    query = query + `searchKey=${searchKey}&`;
  }
  if (status) {
    query = query + `status=${status}&`;
  }
  if (limit) {
    query = query + `limit=${limit}&`;
  }
  if (pageNo > 0) {
    query = query + `pageNo=${pageNo}&`;
  }
  if (sortField > 0) {
    query = query + `sortField=${sortField}&`;
  }
  if (sortOrder > 0) {
    query = query + `sortOrder=${sortOrder}`;
  }
  if (source && searchKey) {
    source.cancel();
  }
  const CancelToken = axios.CancelToken;
  source = CancelToken.source();
  try {
    const response = await AxiosInstance.get(`/admin/organizations` + query, {
      cancelToken: source.token,
    });
    if (response?.data) {
      return response.data;
    } else {
      throw new Error("category fetch failed");
    }
  } catch (error) {
    throw error;
  }
};
export const addNewUser = async (id, data) => {
  try {
    const response = await AxiosInstance.post(
      `/admin/organizations/${id}/staff`,
      data
    );
    if (response?.data) {
      return response.data;
    } else {
      throw new Error("file record creation failed");
    }
  } catch (error) {
    throw error;
  }
};

export const resetPasswordToken = async (email = "") => {
  try {
    const response = await AxiosInstance.post(
      "/admin/auth/reset-password/token",
      {
        email,
      },
      { headers: { "Content-Type": "application/json" } }
    );
    if (response?.data?.data) {
      return response.data;
    } else {
      throw new Error("reset password request failed");
    }
  } catch (error) {
    throw error;
  }
};
export const validateToken = async (token) => {
  try {
    const response = await AxiosInstance.post(
      "/admin/auth/reset-password/token-validate",
      {
        token: token,
      }
    );
    if (response?.data?.data) {
      return response.data;
    } else {
      throw new Error("reset password request failed");
    }
  } catch (error) {
    throw error;
  }
};
export const resetPassword = async (password, token) => {
  try {
    const response = await AxiosInstance.post(
      "/admin/auth/reset-password",
      {
        password: password,
        token: token,
      },
      { headers: { "Content-Type": "application/json" } }
    );
    if (response?.data?.data) {
      return response.data;
    } else {
      throw new Error("reset password request failed");
    }
  } catch (error) {
    throw error;
  }
};
export const getVideoUrl = async (type, id) => {
  let query = `type=${type}?id=${id}`;
  try {
    const response = await AxiosInstance.get(`/admin/files` + query);
    if (response?.data) {
      return response.data;
    } else {
      throw new Error("file record creation failed");
    }
  } catch (error) {
    throw error;
  }
};
export const getJourneyThreads = async (id, channelId) => {
  try {
    const response = await AxiosInstance.get(
      `/admin/journey-config/${id}/channel/${channelId}`
    );
    if (response?.data) {
      return response.data;
    } else {
      throw new Error("message fetchfailed");
    }
  } catch (error) {
    throw error;
  }
};
export const getVideoThreads = async (id, channelId) => {
  try {
    const response = await AxiosInstance.get(
      `/admin/my-account/channel/${channelId}`
    );
    if (response?.data) {
      return response.data;
    } else {
      throw new Error("message fetchfailed");
    }
  } catch (error) {
    throw error;
  }
};
export const getCourseBriefingQuestions = async (project) => {
  let query = "?";
  if (project) {
    query = `?type=PROJECT`;
  }
  try {
    const response = await AxiosInstance.get(`/admin/course/questions` + query);
    if (response?.data) {
      return response.data;
    } else {
      throw new Error("questions fetch failed");
    }
  } catch (error) {
    throw error;
  }
};
export const submitCourseBriefingAnswers = async (data, courseId) => {
  let query = "?";
  if (courseId) {
    query = query + `courseId=${courseId}`;
  }
  try {
    const response = await AxiosInstance.post(`/admin/course` + query, data);
    if (response?.data) {
      return response.data;
    } else {
      throw new Error("questions submission failed");
    }
  } catch (error) {
    throw error;
  }
};
export const submitCourseBriefingScript = async (script, id) => {
  try {
    const response = await AxiosInstance.post(`/admin/course/${id}/submit`, {
      courseBriefing: script,
    });
    if (response?.data) {
      return response.data;
    } else {
      throw new Error("script submission failed");
    }
  } catch (error) {
    throw error;
  }
};
export const getCourseDetails = async (courseId) => {
  try {
    const response = await AxiosInstance.get(`/admin/course/${courseId}`);
    if (response?.data) {
      return response.data;
    } else {
      throw new Error("questions fetch failed");
    }
  } catch (error) {
    throw error;
  }
};
export const getLessonBriefingQuestions = async (courseId) => {
  try {
    const response = await AxiosInstance.get(
      `/admin/course/${courseId}/lessons/messages`
    );
    if (response?.data) {
      return response.data;
    } else {
      throw new Error("questions fetch failed");
    }
  } catch (error) {
    throw error;
  }
};
export const uploadLessonDoc = async (courseId, lessonId, data) => {
  try {
    const response = await AxiosInstance.post(
      `/admin/use-case/${lessonId}/doc`,
      data
    );
    if (response?.data) {
      return response.data;
    } else {
      throw new Error("script submission failed");
    }
  } catch (error) {
    throw error;
  }
};
export const getLessonDetails = async (courseId, lessonId) => {
  try {
    const response = await AxiosInstance.get(`/admin/use-case/${lessonId}`);
    if (response?.data) {
      return response.data;
    } else {
      throw new Error("questions fetch failed");
    }
  } catch (error) {
    throw error;
  }
};

export const updateLessonDetails = async (courseId, lessonId, data) => {
  try {
    const response = await AxiosInstance.patch(
      `/admin/use-case/${lessonId}`,
      data
    );
    if (response?.data) {
      return response.data;
    } else {
      throw new Error("questions fetch failed");
    }
  } catch (error) {
    throw error;
  }
};

export const generateLessonScript = async (courseId, lessonId, data) => {
  try {
    const response = await AxiosInstance.post(
      `/admin/use-case/${lessonId}/generate`,
      { ...data, type: "LESSON", courseId: courseId }
    );
    if (response?.data) {
      return response.data;
    } else {
      throw new Error("questions fetch failed");
    }
  } catch (error) {
    throw error;
  }
};

export const SubmitLessonScript = async (courseId, lessonId) => {
  try {
    const response = await AxiosInstance.post(
      `/admin/use-case/${lessonId}/course/${courseId}/script`
    );
    if (response?.data) {
      return response.data;
    } else {
      throw new Error("questions fetch failed");
    }
  } catch (error) {
    throw error;
  }
};
export const submitLessonAudios = async (courseId, lessonId, data) => {
  try {
    const response = await AxiosInstance.post(
      `/admin/course/${courseId}/lessons/${lessonId}/generate-audio-batch`,
      data
    );
    if (response?.data) {
      return response.data;
    } else {
      throw new Error("questions fetch failed");
    }
  } catch (error) {
    throw error;
  }
};
export const updateLessonAudios = async (courseId, lessonId, data) => {
  try {
    const response = await AxiosInstance.patch(
      `/admin/course/${courseId}/lessons/${lessonId}/audio`,
      data
    );
    if (response?.data) {
      return response.data;
    } else {
      throw new Error("questions fetch failed");
    }
  } catch (error) {
    throw error;
  }
};
export const getLessonAudios = async (courseId, lessonId) => {
  try {
    const response = await AxiosInstance.get(
      `/admin/course/${courseId}/lessons/${lessonId}/audio`
    );
    if (response?.data) {
      return response.data;
    } else {
      throw new Error("questions fetch failed");
    }
  } catch (error) {
    throw error;
  }
};
export const getAllCourses = async (pageNo, limit) => {
  let query = "?";
  if (pageNo) {
    query = query + `pageNo=${pageNo}&`;
  }
  if (limit) {
    query = query + `limit=${limit}&`;
  }

  try {
    const response = await AxiosInstance.get(`/admin/course` + query);

    if (response?.data) {
      return response.data;
    } else {
      throw new Error("questions fetch failed");
    }
  } catch (error) {
    throw error;
  }
};

export const downloadLessonVideo = async (videoId) => {
  try {
    const response = await AxiosInstance.get(
      `/admin/leads/video/download?videoId=${videoId}`
    );
    if (response?.data) {
      return response.data;
    } else {
      throw new Error("video fetch failed");
    }
  } catch (error) {
    throw error;
  }
};
export const updateScriptParts = async (courseId, lessonId, data) => {
  try {
    const response = await AxiosInstance.patch(
      `/admin/course/${courseId}/lessons/${lessonId}/scriptParts`,
      data
    );
    if (response?.data) {
      return response.data;
    } else {
      throw new Error("questions fetch failed");
    }
  } catch (error) {
    throw error;
  }
};
export const createVideoUseCase = async (data) => {
  try {
    const response = await AxiosInstance.post(`/admin/use-case`, data);
    if (response?.data) {
      return response.data;
    } else {
      throw new Error("video creation failed");
    }
  } catch (error) {
    throw error;
  }
};
export const getVideoUseCaseById = async (id) => {
  try {
    const response = await AxiosInstance.get(`/admin/use-case/${id}`);
    if (response?.data) {
      return response.data;
    } else {
      throw new Error("video creation failed");
    }
  } catch (error) {
    throw error;
  }
};

export const updateUsecaseScript = async (id, data) => {
  try {
    const response = await AxiosInstance.patch(
      `/admin/use-case/${id}/scriptParts`,
      data
    );
    if (response?.data) {
      return response.data;
    } else {
      throw new Error("video creation failed");
    }
  } catch (error) {
    throw error;
  }
};

export const uploadUseCasePPT = async (useCaseId, data) => {
  try {
    const response = await AxiosInstance.post(
      `/admin/use-case/${useCaseId}/upload/ppt`,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    if (response?.data) {
      return response.data;
    } else {
      throw new Error("thumbnail upload failed");
    }
  } catch (error) {
    throw error;
  }
};
export const getStockVideos = async (id, data) => {
  try {
    const response = await AxiosInstance.post(
      `/admin/videos/${id}/stock`,
      data
    );
    if (response?.data) {
      return response.data;
    } else {
      throw new Error("video creation failed");
    }
  } catch (error) {
    throw error;
  }
};
export const getAvatarForVideo = async () => {
  try {
    const response = await AxiosInstance.get(`/admin/avatars/heygen`, {
      syncData: true,
    });
    if (response) {
      return response.data;
    } else {
      throw new Error("avatar fetching failed");
    }
  } catch (error) {
    throw error;
  }
};
export const getAudioProfiles = async () => {
  try {
    const response = await AxiosInstance.get(`/admin/voices`, {
      syncData: false,
    });
    if (response?.data) {
      return response.data;
    } else {
      throw new Error("audio profile fetching failed");
    }
  } catch (error) {
    throw error;
  }
};
export const generateUseCaseAudio = async (
  id,
  selectedAudioProfile,
  settings
) => {
  try {
    const data = {
      voiceId: selectedAudioProfile?.voiceId,
      voiceType: selectedAudioProfile?.category,
      VoiceSettings: settings ? settings : null,
    };
    const response = await AxiosInstance.post(`/admin/audio/${id}`, data);
    if (response?.data) {
      return response.data;
    } else {
      throw new Error("video creation failed");
    }
  } catch (error) {
    throw error;
  }
};
export const getUseCaseAudio = async (id) => {
  try {
    const response = await AxiosInstance.get(`/admin/audio/${id}`);
    if (response?.data) {
      return response.data;
    } else {
      throw new Error("video creation failed");
    }
  } catch (error) {
    throw error;
  }
};
export const updateUseCaseAudio = async (id, data, selectedAudioProfile) => {
  try {
    const voiceId = selectedAudioProfile?.voiceId;
    const voiceType = selectedAudioProfile?.category;
    const response = await AxiosInstance.patch(`/admin/audio/${id}`, {
      ...data,
      voiceId,
      voiceType,
    });
    if (response?.data) {
      return response.data;
    } else {
      throw new Error("video creation failed");
    }
  } catch (error) {
    throw error;
  }
};
export const updateUseCase = async (id, data) => {
  try {
    const response = await AxiosInstance.patch(`/admin/use-case/${id}`, data);
    if (response?.data) {
      return response.data;
    } else {
      throw new Error("video creation failed");
    }
  } catch (error) {
    throw error;
  }
};
export const getUseCaseVideo = async (id, selectedAvatarProfile) => {
  try {
    const data = {
      avatarId: selectedAvatarProfile?.avatarId,
    };
    const response = await AxiosInstance.post(`/admin/videos/${id}`, data);
    if (response?.data) {
      return response.data;
    } else {
      throw new Error("video creation failed");
    }
  } catch (error) {
    throw error;
  }
};
export const getMyAvatars = async () => {
  try {
    const response = await AxiosInstance.get(`/admin/avatars`);
    if (response?.data) {
      return response.data;
    } else {
      throw new Error("video creation failed");
    }
  } catch (error) {
    throw error;
  }
};
export const createAvatar = async (data) => {
  try {
    const response = await AxiosInstance.post(`/admin/avatars`, data);
    if (response?.data) {
      return response.data;
    } else {
      throw new Error("video creation failed");
    }
  } catch (error) {
    throw error;
  }
};
export const getMyAvatarsById = async (avatarId) => {
  try {
    const response = await AxiosInstance.get(`/admin/avatars/${avatarId}`);
    if (response?.data) {
      return response.data;
    } else {
      throw new Error("video creation failed");
    }
  } catch (error) {
    throw error;
  }
};
export const updateMyAvatarsById = async (avatarId, data) => {
  try {
    const response = await AxiosInstance.patch(
      `/admin/avatars/${avatarId}`,
      data
    );
    if (response?.data) {
      return response.data;
    } else {
      throw new Error("video creation failed");
    }
  } catch (error) {
    throw error;
  }
};

export const deleteMyAvatarsById = async (avatarId) => {
  try {
    const response = await AxiosInstance.delete(`/admin/avatars/${avatarId}`);
    if (response?.data) {
      return response.data;
    } else {
      throw new Error("video creation failed");
    }
  } catch (error) {
    throw error;
  }
};
