import React from "react";
import "./login.css";
import { Form, Input, Button, ConfigProvider } from "antd";
import { resetPasswordToken } from "../../api/adminService";
import { ReactComponent as Logo } from "../../assets/images/logo.svg";
import { ReactComponent as Email } from "../../assets/images/email.svg";
import NotificationInstance from "../../services/notificationServices";
import { PRIMARY_COLOR } from "../../utils/colors";

const UpdatePassword = () => {
  const onFinish = (data) => {
    resetPasswordToken(data.email)
      .then((res) => {
        NotificationInstance.success({
          message: "Password Reset Email Sent",
          description:
            "An email with password reset link has been sent to your registered email address.",
        });
      })
      .catch((error) => {
        NotificationInstance.error({
          description: "Please verify & try again.",
        });
      });
  };
  return (
    <div className="login-container">
      <div className="login-box">
        <div className="leaproad-logo">
          <Logo />
        </div>
        {/* <p className="login-content">
          Log in to Leap Road using email id and password
        </p> */}
        <ConfigProvider
          theme={{
            token: {
              fontFamily: '"Poppins", sans-serif',
              Button: {
                colorPrimary: PRIMARY_COLOR,
                colorPrimaryHover: PRIMARY_COLOR,
                colorPrimaryActive: PRIMARY_COLOR,
                primaryShadow: "none",
                controlHeight: 45,
              },
              Input: {
                colorBorder: "transparent",
                hoverBorderColor: PRIMARY_COLOR,
                activeBorderColor: PRIMARY_COLOR,
                activeShadow: "none",
                controlHeight: 45,
                colorTextPlaceholder: "#9EA5AD",
              },
            },
          }}
        >
          <Form
            name="login-form"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            layout="vertical"
            className="login-form-container"
          >
            <Form.Item
              required={false}
              name="email"
              label="Email"
              rules={[
                {
                  required: true,
                  message: "Please enter your email or username",
                },
                {
                  type: "email",
                  message: "Please enter a valid email",
                },
              ]}
            >
              <Input
                prefix={<Email />}
                placeholder="user@leaproad.com"
                width={350}
              />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="submit-button"
                block
              >
                Send Reset Link
              </Button>
            </Form.Item>
          </Form>
        </ConfigProvider>
      </div>
    </div>
  );
};

export default UpdatePassword;
