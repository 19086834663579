import React, { useEffect, useState } from "react";
import "./categoryJourneys.css";
import {
  Avatar,
  Badge,
  Button,
  ConfigProvider,
  Input,
  Select,
  Skeleton,
  Tooltip,
} from "antd";

import { ReactComponent as Search } from "../../assets/images/search.svg";
import { ReactComponent as Logo } from "../../assets/images/searchPrefix.svg";
import { ReactComponent as Delete } from "../../assets/images/trash.svg";
import { ReactComponent as Edit } from "../../assets/images/editPen.svg";
import { ReactComponent as Check } from "../../assets/images/checkMark.svg";
import AddJourneyModal from "../../components/modals/addJourneyModal";
import {
  Link,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
import {
  deleteCategory,
  deleteSelectedJourneys,
  getJourneys,
  updateCategory,
} from "../../api/adminService";
import { timeCreatedOn, timeSinceLastUpdated } from "../../utils/format";
import { StatusTag, TypeTag } from "../../components/tags/tags";
import FilterDrawer from "../../components/modals/filterDrawer";
import CategoryJourneyTable from "../../components/categoryJourneyList/categoryJourneyList";
import TableActions from "../../components/actions/tableActions";
import { PRIMARY_COLOR } from "../../utils/colors";
import CustomPagination from "../../components/customPagination/customPagination";
import DeleteConfirmModal from "../../components/modals/deleteConfirmModal";
import NotificationInstance from "../../services/notificationServices";
import AddCategoryModal from "../../components/modals/addCategoryModal";
import PublishModal from "../../components/modals/publishModal";
import UnpublishModal from "../../components/modals/unPublishModal";

const TitleDiv = ({ name, id, image }) => {
  return (
    <Link to={`view/${id}`}>
      <div className="journey-title-wrapper">
        <Avatar
          shape={"circle"}
          src={
            image ??
            `https://ui-avatars.com/api/?name=${name}&background=9EA5AD&color=fff`
          }
          size={40}
        />
        <span className="title-name">{name}</span>
      </div>
    </Link>
  );
};

const sortOptions = [
  {
    value: "title.ASC",
    label: "Name (A to Z)",
  },
  {
    value: "title.DESC",
    label: "Name (Z to A)",
  },
  {
    value: "createdAt.ASC",
    label: "Created On (oldest first)",
  },
  {
    value: "createdAt.DESC",
    label: "Created On (newest first)",
  },
  {
    value: "updatedAt.ASC",
    label: "Last Updated (oldest first)",
  },
  {
    value: "updatedAt.DESC",
    label: "Last Updated (newest first)",
  },
];
const CategoryJourneys = () => {
  const [categoryItems, , setDisplayName, setCategory] = useOutletContext();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [data, setData] = useState([]);
  const [searchKey, setSearchKey] = useState("");
  const [sortField, setSortField] = useState("");
  const [filter, setfilter] = useState({
    type: "",
    status: "",
    active: false,
  });
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [sortOrder, setsortOrder] = useState("");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [pageSize] = useState(8);
  const [currentPage, setCurrentPage] = useState(1);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [isPublishModalOpen, setIsPublishModalOpen] = useState(false);
  const [isUnpublishModalOpen, setIsUnpublishModalOpen] = useState(false);
  const [isJourneyDeleteModalOpen, setIsJourneyDeleteModalOpen] =
    useState(false);
  const [refresh, setRefresh] = useState(false);
  const [selectedJourney, setSelectedJourney] = useState();
  const [categoryName, setCategoryName] = useState({
    value: "",
    error: false,
  });
  const [defaultStatus, setdefaultStatus] = useState("");
  const [label, setlabel] = useState("");
  const [isMultipleDeleteModalOpen, setisMultipleDeleteModalOpen] =
    useState(false);
  const params = useParams();
  const navigate = useNavigate();
  let debounceTimeout;
  const debouncedSearch = (input) => {
    clearTimeout(debounceTimeout);

    debounceTimeout = setTimeout(() => {
      setCurrentPage(1);
      setSearchKey(input);
    }, 500);
  };
  const updateLabels = (value, items) => {
    const categoryItem = items?.find((item) => item._id === value);
    if (categoryItem) {
      setDisplayName([categoryItem.name]);
      setdefaultStatus(categoryItem?.status);
      setlabel(categoryItem.name);
      setCategoryName({ ...categoryName, value: categoryItem.name });
    } else if (categoryItems.length > 1 && !categoryItem) {
      navigate("/");
    }
    return categoryItem ? categoryItem.name : null;
  };
  useEffect(() => {
    updateLabels(params.categoryId, categoryItems);
  }, [params.categoryId, categoryItems]);
  const onSort = (val) => {
    setCurrentPage(1);
    const value = val?.split(".");
    setSortField(value[0]);
    setsortOrder(value[1]);
  };
  const onSearch = (event) => {
    debouncedSearch(event.target.value);
  };
  const handleOk = (journeyId) => {
    navigate(`create/${journeyId}`);
    handleCancel();
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleCancelDrawer = () => {
    setIsDrawerOpen(false);
  };
  const handleDelete = () => {
    setIsDeleteModalOpen(false);
    deleteCategory(params?.categoryId)
      .then((res) => {
        NotificationInstance.success({
          message: "Successful",
          description: "Successfully deleted category",
        });
        setCategory((prevCategoryItems) => {
          return prevCategoryItems.filter(
            (item) => item.value !== params?.categoryId
          );
        });
        navigate("/");
      })
      .catch((error) => {
        NotificationInstance.error({
          message:
            error?.response?.data?.message ?? "Please verify & try again.",
        });
      });
  };
  const handleUpdate = (status) => {
    const request = {
      name: categoryName.value,
      status: status,
    };
    updateCategory(params?.categoryId, request)
      .then((res) => {
        setIsUpdateModalOpen(false);
        setIsUnpublishModalOpen(false);
        setIsPublishModalOpen(false);
        setdefaultStatus(request?.status);
        NotificationInstance.success({
          message: "Successful",
          description: "Successfully updated category",
        });
        setCategory((prevCategoryItems) => {
          const updatedCategoryItems = [...prevCategoryItems];
          const index = updatedCategoryItems.findIndex(
            (item) => item.value === params.categoryId
          );
          if (index !== -1) {
            updatedCategoryItems[index] = {
              ...updatedCategoryItems[index],
              label: categoryName.value,
              status: status,
            };
          }
          return updatedCategoryItems;
        });
      })
      .catch((error) => {
        setIsUnpublishModalOpen(false);
        setIsPublishModalOpen(false);
        NotificationInstance.error({
          message:
            error?.response?.data?.message ?? "Please verify & try again.",
        });
      });
  };
  const handleDeleteJourney = (id) => {
    setIsJourneyDeleteModalOpen(false);
    deleteSelectedJourneys([id])
      .then((res) => {
        NotificationInstance.success({
          message: "Successful",
          description: "Successfully deleted journey",
        });
        setCurrentPage(1);
        setSelectedRowKeys(selectedRowKeys?.filter((item) => item !== id));
        setRefresh(!refresh);
      })
      .catch((error) => {
        NotificationInstance.error({
          message:
            error?.response?.data?.message ?? "Please verify & try again.",
        });
      });
  };
  const handleDeleteselectedJourney = (id) => {
    setisMultipleDeleteModalOpen(false);
    deleteSelectedJourneys(id)
      .then((res) => {
        NotificationInstance.success({
          message: "Successful",
          description: "Successfully deleted journeys",
        });
        setCurrentPage(1);
        setSelectedRowKeys([]);
        setRefresh(!refresh);
      })
      .catch((error) => {
        NotificationInstance.error({
          message:
            error?.response?.data?.message ?? "Please verify & try again.",
        });
      });
  };
  useEffect(() => {
    if (params.categoryId) {
      setSelectedRowKeys([]);
      setCurrentPage(1);
    }
  }, [params]);
  useEffect(() => {
    setLoading(true);
    const handleEdit = (id) => {
      navigate(`edit/${id}`);
    };
    getJourneys(
      searchKey,
      filter?.status,
      filter.type,
      params.categoryId,
      pageSize,
      currentPage,
      sortField,
      sortOrder,
      false
    )
      .then((res) => {
        setLoading(false);
        const responseData = res?.data.map((item) => ({
          key: item._id,
          name: (
            <TitleDiv name={item.title} id={item?._id} image={item?.image} />
          ),
          lastUpdated: timeSinceLastUpdated(item.updatedAt),
          createdOn: timeCreatedOn(item.createdAt),
          image: item.image,
          status: <StatusTag status={item.status} />,
          type: <TypeTag type={item.type} />,
          action: (
            <TableActions
              handleEdit={() => handleEdit(item?._id)}
              handleDelete={() => {
                setSelectedJourney(item?._id);
                setIsJourneyDeleteModalOpen(true);
              }}
            />
          ),
        }));
        setData(responseData);
        setTotal(res?.info?.totalCount);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
        navigate("/");
      });
  }, [
    searchKey,
    sortField,
    sortOrder,
    filter,
    params.categoryId,
    pageSize,
    currentPage,
    refresh,
    navigate,
  ]);
  return (
    <div className="category-journeys">
      <div className="category-header">
        <div className="category-title">
          {label ? <h2>{label}</h2> : <Skeleton.Input />}
          {defaultStatus ? (
            <StatusTag status={defaultStatus} />
          ) : (
            <Skeleton.Button size="small" />
          )}
        </div>
        <div className="category-actions">
          <span className="total-journeys">{total} Journeys</span>
          <Tooltip title="Delete Category">
            <Delete onClick={() => setIsDeleteModalOpen(true)} />
          </Tooltip>
          <DeleteConfirmModal
            isModalOpen={isDeleteModalOpen}
            handleCancel={() => setIsDeleteModalOpen(false)}
            handleOk={handleDelete}
            type={"Category"}
          />
          <Tooltip title="Edit Category">
            <Edit onClick={() => setIsUpdateModalOpen(true)} />
          </Tooltip>
          <AddCategoryModal
            isModalOpen={isUpdateModalOpen}
            handleCancel={() => setIsUpdateModalOpen(false)}
            handleOk={handleUpdate}
            category={categoryName}
            setCategory={setCategoryName}
            update={true}
            label={label}
            defaultStatus={defaultStatus}
          />
          {defaultStatus === "DRAFT" ? (
            <Button
              onClick={() => {
                setIsPublishModalOpen(true);
              }}
              className="filter-button"
              color="white"
            >
              Publish Category
            </Button>
          ) : (
            <Button
              onClick={() => {
                setIsUnpublishModalOpen(true);
              }}
              className="filter-button"
              color="white"
            >
              Unpublish Category
            </Button>
          )}
          <PublishModal
            isModalOpen={isPublishModalOpen}
            handleCancel={() => setIsPublishModalOpen(false)}
            handleOk={() => {
              handleUpdate("PUBLISHED");
            }}
            type="Category"
          />
          <UnpublishModal
            isModalOpen={isUnpublishModalOpen}
            handleCancel={() => setIsUnpublishModalOpen(false)}
            handleOk={() => {
              handleUpdate("DRAFT");
            }}
            type="Category"
          />
          <DeleteConfirmModal
            isModalOpen={isJourneyDeleteModalOpen}
            handleCancel={() => setIsJourneyDeleteModalOpen(false)}
            handleOk={handleDeleteJourney}
            type={"Journey"}
            selectedJourney={selectedJourney}
          />
        </div>
      </div>
      <div className="category-journey-wrapper">
        <div className="journey-header">
          <div className="journey-title">
            <h3>Journeys</h3>
          </div>
          <ConfigProvider
            theme={{
              token: {
                fontFamily: '"Poppins", sans-serif',
                Input: {
                  colorBorder: "transparent",
                  hoverBorderColor: PRIMARY_COLOR,
                  activeBorderColor: PRIMARY_COLOR,
                  activeShadow: "none",
                  controlHeight: 45,
                  colorTextPlaceholder: "#9EA5AD",
                },
                Button: {
                  colorPrimary: PRIMARY_COLOR,
                  colorPrimaryHover: PRIMARY_COLOR,
                  colorPrimaryActive: PRIMARY_COLOR,
                  primaryShadow: "none",
                  controlHeight: 45,
                },
              },
              components: {
                Select: {
                  controlHeight: 45,
                  colorTextPlaceholder: "#9EA5AD",
                },
                Radio: {
                  colorPrimary: PRIMARY_COLOR,
                },
                Checkbox: {
                  colorPrimary: PRIMARY_COLOR,
                  colorPrimaryHover: PRIMARY_COLOR,
                },
                Drawer: {
                  footerPaddingBlock: 30,
                },
                Badge: {
                  dotSize: 10,
                },
              },
            }}
          >
            {selectedRowKeys.length > 0 ? (
              <div className="journey-action">
                <span className="total-journeys">
                  {selectedRowKeys.length} Journey selected
                </span>
                <Button
                  onClick={() => setisMultipleDeleteModalOpen(true)}
                  className="filter-button"
                  color="white"
                  icon={<Delete />}
                >
                  Delete Selected Journeys
                </Button>
                <DeleteConfirmModal
                  isModalOpen={isMultipleDeleteModalOpen}
                  handleCancel={() => setisMultipleDeleteModalOpen(false)}
                  handleOk={handleDeleteselectedJourney}
                  type={"Journeys"}
                  selectedJourney={selectedRowKeys}
                />
              </div>
            ) : (
              <div className="journey-action">
                <div className="sort-action">
                  <span className="sort-prefix">Sort : </span>
                  <Select
                    placeholder="Select a sort"
                    optionFilterProp="children"
                    onSelect={onSort}
                    variant="borderless"
                    popupMatchSelectWidth={true}
                    defaultValue={"updatedAt.DESC"}
                    popupClassName="sort-options"
                    title="label"
                    options={sortOptions}
                    menuItemSelectedIcon={<Check />}
                    listHeight={270}
                  />
                </div>

                <Input
                  width={200}
                  placeholder="Search"
                  prefix={<Search />}
                  suffix={<Logo />}
                  className="search-input"
                  onChange={onSearch}
                />
                <Badge dot={filter.active}>
                  <Button
                    onClick={() => setIsDrawerOpen(true)}
                    className="filter-button"
                    color="white"
                  >
                    Filter
                  </Button>
                </Badge>
                <Button
                  type="primary"
                  onClick={() => setIsModalOpen(true)}
                  className="create-button"
                >
                  Create New Journey
                </Button>
                <FilterDrawer
                  isDrawerOpen={isDrawerOpen}
                  handleCancel={handleCancelDrawer}
                  setfilter={setfilter}
                  typeFilter={filter.type}
                  statusFilter={filter.status}
                  active={filter.active}
                  all={false}
                  setJourneyPage={setCurrentPage}
                />
                <AddJourneyModal
                  isModalOpen={isModalOpen}
                  handleCancel={handleCancel}
                  handleOk={handleOk}
                  categoryList={true}
                  categoryItem={params.categoryId}
                />
              </div>
            )}
          </ConfigProvider>
        </div>
        <CategoryJourneyTable
          data={data}
          loading={loading}
          selectedRowKeys={selectedRowKeys}
          setSelectedRowKeys={setSelectedRowKeys}
        />
        {total > pageSize && (
          <CustomPagination
            pageSize={pageSize}
            currentPage={currentPage}
            total={total}
            setCurrentPage={setCurrentPage}
          />
        )}
      </div>
    </div>
  );
};

export default CategoryJourneys;
