import React, { useEffect, useState } from "react";
import "./allJourneys.css";
import { Avatar, Badge, Button, ConfigProvider, Input, Select } from "antd";
import { ReactComponent as Search } from "../../assets/images/search.svg";
import { ReactComponent as Logo } from "../../assets/images/searchPrefix.svg";
import { ReactComponent as Check } from "../../assets/images/checkMark.svg";
import { ReactComponent as Delete } from "../../assets/images/trash.svg";
import AddJourneyModal from "../../components/modals/addJourneyModal";
import { Link, useNavigate } from "react-router-dom";
import { deleteSelectedJourneys, getJourneys } from "../../api/adminService";
import { timeCreatedOn, timeSinceLastUpdated } from "../../utils/format";
import { CategoryTag, StatusTag, TypeTag } from "../../components/tags/tags";
import FilterDrawer from "../../components/modals/filterDrawer";
import { PRIMARY_COLOR } from "../../utils/colors";
import TableActions from "../../components/actions/tableActions";
import NotificationInstance from "../../services/notificationServices";
import DeleteConfirmModal from "../../components/modals/deleteConfirmModal";
import CustomTable from "../../components/customTable/customTable";

const TitleDiv = ({ name, id, category, image }) => {
  return (
    <Link to={`${category}/view/${id}`}>
      <div className="journey-title-wrapper">
        <Avatar
          shape={"circle"}
          src={
            image ??
            `https://ui-avatars.com/api/?name=${name}&background=9EA5AD&color=fff`
          }
          size={40}
          // icon={<Logo />}
        />
        <span className="title-name">{name}</span>
      </div>
    </Link>
  );
};

const sortOptions = [
  {
    value: "title.ASC",
    label: "Name (A to Z)",
  },
  {
    value: "title.DESC",
    label: "Name (Z to A)",
  },
  {
    value: "createdAt.ASC",
    label: "Created On (oldest first)",
  },
  {
    value: "createdAt.DESC",
    label: "Created On (newest first)",
  },
  {
    value: "updatedAt.ASC",
    label: "Last Updated (oldest first)",
  },
  {
    value: "updatedAt.DESC",
    label: "Last Updated (newest first)",
  },
];

const columns = [
  {
    title: "Name",
    dataIndex: "name",
    width: "25%",
  },
  {
    title: "Last Updated",
    dataIndex: "lastUpdated",
    width: "13%",
  },
  {
    title: "Created on",
    dataIndex: "createdOn",
    width: "13%",
  },
  {
    title: "Category",
    dataIndex: "category",
    width: "13%",
  },
  {
    title: "Status",
    dataIndex: "status",
    width: "13%",
  },
  {
    title: "Type",
    dataIndex: "type",
    width: "13%",
  },
  {
    title: "",
    dataIndex: "action",
    width: "10%",
  },
];

const AllJourneys = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [data, setData] = useState([]);
  const [searchKey, setSearchKey] = useState("");
  const [sortField, setSortField] = useState();
  const [filter, setfilter] = useState({
    type: "",
    status: "",
    category: [],
    active: false,
  });
  const [loading, setLoading] = useState(false);
  const [sortOrder, setsortOrder] = useState("");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [pageSize] = useState(8);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [isJourneyDeleteModalOpen, setIsJourneyDeleteModalOpen] =
    useState(false);
  const [refresh, setRefresh] = useState(false);
  const [selectedJourney, setSelectedJourney] = useState();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const navigate = useNavigate();
  let debounceTimeout;
  const debouncedSearch = (input) => {
    clearTimeout(debounceTimeout);

    debounceTimeout = setTimeout(() => {
      setCurrentPage(1);
      setSearchKey(input);
    }, 500);
  };
  const onSort = (val) => {
    setCurrentPage(1);
    const value = val?.split(".");
    setSortField(value[0]);
    setsortOrder(value[1]);
  };
  const onSearch = (event) => {
    debouncedSearch(event.target.value);
  };
  const handleOk = (journeyId, categoryId) => {
    navigate(`/${categoryId}/create/${journeyId}`);
    handleCancel();
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleCancelDrawer = () => {
    setIsDrawerOpen(false);
  };
  const handleDeleteJourney = (id) => {
    setIsJourneyDeleteModalOpen(false);
    deleteSelectedJourneys([id])
      .then((res) => {
        NotificationInstance.success({
          message: "Successful",
          description: "Successfully deleted journey",
        });
        setCurrentPage(1);
        setSelectedRowKeys(selectedRowKeys?.filter((item) => item !== id));
        setRefresh(!refresh);
      })
      .catch((error) => {
        NotificationInstance.error({
          message:
            error?.response?.data?.message ?? "Please verify & try again.",
        });
      });
  };
  const handleDeleteselectedJourney = (id) => {
    setIsDeleteModalOpen(false);
    deleteSelectedJourneys(id)
      .then((res) => {
        NotificationInstance.success({
          message: "Successful",
          description: "Successfully deleted journeys",
        });
        setCurrentPage(1);
        setSelectedRowKeys([]);
        setRefresh(!refresh);
      })
      .catch((error) => {
        NotificationInstance.error({
          message:
            error?.response?.data?.message ?? "Please verify & try again.",
        });
      });
  };
  useEffect(() => {
    setLoading(true);
    const handleEdit = (category, id) => {
      navigate(`${category}/edit/${id}`);
    };
    getJourneys(
      searchKey,
      filter?.status,
      filter.type,
      filter.category,
      pageSize,
      currentPage,
      sortField,
      sortOrder
    )
      .then((res) => {
        setLoading(false);
        const responseData = res?.data.map((item) => ({
          key: item._id,
          name: (
            <TitleDiv
              name={item.title}
              id={item?._id}
              category={item?.categoryInfo?._id}
              image={item?.image}
            />
          ),
          lastUpdated: timeSinceLastUpdated(item.updatedAt),
          createdOn: timeCreatedOn(item.createdAt),
          image: item.image,
          category: <CategoryTag category={item?.categoryInfo?.name} />,
          status: <StatusTag status={item.status} />,
          type: <TypeTag type={item.type} />,
          action: (
            <TableActions
              handleEdit={() => handleEdit(item?.categoryInfo?._id, item?._id)}
              handleDelete={() => {
                setSelectedJourney(item?._id);
                setIsJourneyDeleteModalOpen(true);
              }}
            />
          ),
        }));
        setData(responseData);
        setTotal(res?.info?.totalCount ?? 0);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }, [
    searchKey,
    sortField,
    sortOrder,
    filter,
    pageSize,
    currentPage,
    refresh,
    navigate,
  ]);

  return (
    <div>
      <div className="journey-header">
        <div className="journey-title">
          <h1>All Journeys</h1>
        </div>
        <ConfigProvider
          theme={{
            token: {
              fontFamily: '"Poppins", sans-serif',
              Input: {
                colorBorder: "transparent",
                hoverBorderColor: PRIMARY_COLOR,
                activeBorderColor: PRIMARY_COLOR,
                activeShadow: "none",
                controlHeight: 45,
                colorTextPlaceholder: "#9EA5AD",
              },
              Button: {
                colorPrimary: PRIMARY_COLOR,
                colorPrimaryHover: PRIMARY_COLOR,
                colorPrimaryActive: PRIMARY_COLOR,
                primaryShadow: "none",
                controlHeight: 45,
              },
            },
            components: {
              Select: {
                controlHeight: 45,
                colorTextPlaceholder: "#9EA5AD",
              },
              Radio: {
                colorPrimary: PRIMARY_COLOR,
              },
              Checkbox: {
                colorPrimary: PRIMARY_COLOR,
                colorPrimaryHover: PRIMARY_COLOR,
              },
              Drawer: {
                footerPaddingBlock: 30,
              },
              Badge: {
                dotSize: 10,
              },
              Menu: {
                colorPrimary: PRIMARY_COLOR,
              },
            },
          }}
        >
          {selectedRowKeys.length > 0 ? (
            <div className="journey-action">
              <span className="total-journeys">
                {selectedRowKeys.length} Journey selected
              </span>
              <Button
                onClick={() => setIsDeleteModalOpen(true)}
                className="filter-button"
                color="white"
                icon={<Delete />}
              >
                Delete Selected Journeys
              </Button>

              <DeleteConfirmModal
                isModalOpen={isDeleteModalOpen}
                handleCancel={() => setIsDeleteModalOpen(false)}
                handleOk={handleDeleteselectedJourney}
                type={"Journeys"}
                selectedJourney={selectedRowKeys}
              />
            </div>
          ) : (
            <div className="journey-action">
              <div className="sort-action">
                <span className="sort-prefix">Sort : </span>
                <Select
                  placeholder="Select a sort"
                  optionFilterProp="children"
                  onSelect={onSort}
                  variant="borderless"
                  popupMatchSelectWidth={true}
                  defaultValue={"updatedAt.DESC"}
                  popupClassName="sort-options"
                  title="label"
                  options={sortOptions}
                  menuItemSelectedIcon={<Check />}
                  listHeight={270}
                />
              </div>

              <Input
                width={200}
                placeholder="Search"
                prefix={<Search />}
                suffix={<Logo />}
                className="search-input"
                onChange={onSearch}
              />
              <Badge dot={filter.active}>
                <Button
                  onClick={() => setIsDrawerOpen(true)}
                  className="filter-button"
                  color="white"
                >
                  Filter
                </Button>
              </Badge>
              <Button
                type="primary"
                onClick={() => setIsModalOpen(true)}
                className="create-button"
              >
                Create New Journey
              </Button>
              <FilterDrawer
                isDrawerOpen={isDrawerOpen}
                handleCancel={handleCancelDrawer}
                setfilter={setfilter}
                typeFilter={filter.type}
                statusFilter={filter.status}
                categoryFilter={filter.category}
                active={filter.active}
                setJourneyPage={setCurrentPage}
              />
              <AddJourneyModal
                isModalOpen={isModalOpen}
                handleCancel={handleCancel}
                handleOk={handleOk}
              />
            </div>
          )}
          <DeleteConfirmModal
            isModalOpen={isJourneyDeleteModalOpen}
            handleCancel={() => setIsJourneyDeleteModalOpen(false)}
            handleOk={handleDeleteJourney}
            type={"Journey"}
            selectedJourney={selectedJourney}
          />
        </ConfigProvider>
      </div>
      <CustomTable
        data={data}
        loading={loading}
        selectedRowKeys={selectedRowKeys}
        setSelectedRowKeys={setSelectedRowKeys}
        columns={columns}
        total={total}
        pageSize={pageSize}
        item={"Journey"}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
      />
    </div>
  );
};

export default AllJourneys;
