import { Button, Col, Empty, Flex, Form, Input, Row, Upload } from "antd";
import React, { useRef, useState } from "react";
import { CloseOutlined } from "@ant-design/icons";

import "./lessonScript.css";
import { scriptUpdateStages } from "../../../utils/format";
import NotificationInstance from "../../../services/notificationServices";
const LessonScript = ({
  selectedScript,
  setLesson,
  setSelectedScript,
  onUpdate,
  setSection,
  generateScript,
  currentStatus,
  steps,
}) => {
  const { TextArea } = Input;
  function hasNextStep(currentStep) {
    const stepKeys = Object.keys(steps); // Get the keys of the steps object
    const currentIndex = stepKeys.indexOf(currentStep); // Find the index of the current step

    // Check if there's a valid next step with a value
    if (currentIndex !== -1 && currentIndex < stepKeys.length - 1) {
      const nextStepKey = stepKeys[currentIndex + 1];
      return steps[nextStepKey]; // Returns the value of the next step if it exists
    }

    return false; // Return false if no valid next step exists
  }

  const onChange = (e) => {
    const { value } = e.target;

    // Update the lesson's step results
    setLesson((prev) => ({
      ...prev,
      stepResults: {
        ...prev.stepResults,
        [selectedScript?.section]: value, // Assuming stepResults contains lessonStep
      },
    }));

    // Update the selected script state
    setSelectedScript((prev) => ({ ...prev, script: value }));
  };
  const handleGenerate = () => {
    if (selectedScript?.script && selectedScript?.script?.trim() !== "") {
      setSelectedScript((prev) => ({ ...prev, status: "REGENERATING" }));
      setSection(selectedScript.section);
      generateScript(selectedScript.section, true);
    } else {
      NotificationInstance.info({
        message: `Please enter the ${selectedScript.section}`,
      });
    }
  };
  const handleConfirm = (section) => {
    if (selectedScript?.script && selectedScript?.script?.trim() !== "") {
      setSelectedScript((prev) => ({ ...prev, status: "SUBMITTING" }));
      onUpdate(section);
    } else {
      NotificationInstance.info({
        message: `Please enter the ${selectedScript.section}`,
      });
    }
  };

  const renderButtonContent = () => {
    if (selectedScript?.status === "SUBMITTING") {
      return "Confirming Script";
    }

    if (selectedScript.section === "STORY") {
      return "Confirm Script";
    }

    if (hasNextStep(selectedScript.section)) {
      return "Confirm Script";
    }
    return "Confirm & Generate Next Script";
  };

  return (
    <Row className="lesson-script-detail">
      <Col
        span={24}
        style={{
          height: "100%",
        }}
      >
        <Flex vertical className="course-detail-container">
          <Flex
            className="course-detail-header"
            justify="space-between"
            vertical
            gap={5}
          >
            <span>
              <b>{selectedScript?.step}</b>
            </span>
            <span>{selectedScript?.question}</span>
          </Flex>
          <Flex flex={1} className="course-content">
            {selectedScript?.status === "COMPLETED" ? (
              <TextArea
                readOnly={scriptUpdateStages?.includes(currentStatus)}
                spellCheck={false}
                onChange={onChange}
                className="answer-input"
                value={selectedScript?.script}
                style={{ resize: "none" }}
              />
            ) : (
              <Flex flex={1} justify="center" align="center" vertical>
                {selectedScript?.status === "SUBMITTING" && (
                  <>
                    <span>Submitting Script...</span>
                    <p>
                      Please wait while we submit your script for confirmation.
                    </p>
                  </>
                )}

                {selectedScript?.status === "REGENERATING" && (
                  <>
                    <span>
                      Regenerating Script for <b>{selectedScript?.section}</b>
                      ...
                    </span>
                    <p>
                      We are regenerating the content. Please wait for the
                      process to complete.
                    </p>
                  </>
                )}

                {selectedScript?.status === "GENERATING" && (
                  <>
                    <span>
                      Generating Script for <b>{selectedScript?.section}</b>...
                    </span>
                    <p>
                      Your content is being generated. This might take a moment.
                    </p>
                  </>
                )}

                <div
                  className="video-loader"
                  style={{ width: "100%", marginTop: "20px" }}
                >
                  <div className="loader"></div>
                </div>
              </Flex>
            )}
          </Flex>
          {selectedScript?.status !== "GENERATING" &&
            !scriptUpdateStages?.includes(currentStatus) && (
              <Flex
                className="course-detail-footer"
                justify="flex-end"
                wrap="wrap"
                gap={10}
                align="flex-end"
              >
                <Button
                  onClick={() => handleGenerate()}
                  loading={selectedScript?.status === "REGENERATING"}
                  disabled={selectedScript?.status === "SUBMITTING"}
                >
                  {selectedScript?.status === "REGENERATING"
                    ? "Regenerating"
                    : "Regenerate"}
                </Button>
                <Button
                  type="primary"
                  onClick={() => handleConfirm(selectedScript.section)}
                  loading={selectedScript?.status === "SUBMITTING"}
                  disabled={selectedScript?.status === "REGENERATING"}
                >
                  {renderButtonContent()}
                </Button>
              </Flex>
            )}
        </Flex>
      </Col>
    </Row>
  );
};

export default LessonScript;
