import { Button, Flex, Form, Input, Modal, Radio, Select } from "antd";
import React, { useEffect, useState } from "react";
import "./modal.css";
import { addNewUser, getOrganizations } from "../../api/adminService";
import NotificationInstance from "../../services/notificationServices";

const AddUserModal = ({ isModalOpen, handleOk, handleCancel }) => {
  const [organizations, setOrganizations] = useState([]);
  const [category, setCategory] = useState({
    value: null,
    error: false,
  });
  const [total, setTotal] = useState();
  const [currentPage, setcurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [initial, setinitial] = useState(true);
  const [type, setType] = useState({
    value: "SUPER_ADMIN",
    error: false,
  });
  const [orgRole, setOrgRole] = useState({
    value: "ADMIN",
    error: false,
  });
  const [disable, setDisable] = useState(false);
  const [form] = Form.useForm();
  const onSubmit = () => {
    if (!category.value) {
      setCategory({ value: null, error: true });
    }
    form
      .validateFields()
      .then((val) => {
        if (category.value) {
          setDisable(true);
          const data = {
            ...val,
            appRole: type.value,
            organizationRole: orgRole.value,
          };
          addNewUser(category.value, data)
            .then((res) => {
              console.log(res);
              setDisable(false);
              form.resetFields();
              handleOk();
              NotificationInstance.success({
                message: "Successful",
                description: "Successfully created new user",
              });
            })
            .catch((err) => {
              console.log(err);
              setDisable(false);
              NotificationInstance.error({
                message: "User creation failed",
                description: "Please try again.",
              });
            });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const onTypeChange = (event) => {
    setType({
      value: event.target.value,
      error: event.target.value ? false : true,
    });
  };
  const onCategoryChange = (value) => {
    setCategory({
      value: value,
      error: value ? false : true,
    });
  };
  const onModalClose = () => {
    setType({ value: "SUPER_ADMIN", error: false });
    setOrgRole({ value: "ADMIN", error: false });
    setCategory({
      value: null,
      error: false,
    });
    form.resetFields();
    handleCancel();
  };
  const fetchOrganizations = () => {
    getOrganizations("", "", "", currentPage)
      .then((res) => {
        const responseData = res?.data.map((item) => ({
          label: item.name,
          value: item._id,
        }));
        if (initial) {
          setOrganizations(responseData);
        } else {
          setOrganizations((prevCategories) => [
            ...prevCategories,
            ...responseData,
          ]);
        }
        setTotal(res?.info?.totalCount);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
    setinitial(false);
  };
  useEffect(() => {
    setLoading(true);
    fetchOrganizations();
  }, [currentPage]);

  const onscroll = (e) => {
    const { target } = e;
    const bottom =
      target.scrollHeight - target.scrollTop === target.clientHeight;
    if (bottom && organizations?.length < total) {
      setcurrentPage((prev) => prev + 1);
    }
  };
  return (
    <Modal
      centered
      width={500}
      title="Add New User"
      open={isModalOpen}
      onCancel={onModalClose}
      footer={() => (
        <div className="action-buttons">
          <Button
            type="primary"
            onClick={onSubmit}
            disabled={disable}
            loading={disable}
          >
            Add User
          </Button>
          <Button className="cancel-button" onClick={onModalClose}>
            Cancel
          </Button>
        </div>
      )}
    >
      <div className="title-input-wrapper">
        <Form
          form={form}
          onFinish={onSubmit}
          layout="vertical"
          className="add-user"
        >
          <Flex gap={20}>
            <Form.Item
              name="firstName"
              label="First Name"
              required={false}
              rules={[
                {
                  required: true,
                  message: "Please enter first name",
                  validateTrigger: onsubmit,
                },
              ]}
            >
              <Input placeholder="Enter First Name" />
            </Form.Item>
            <Form.Item
              name="lastName"
              label="Last Name"
              required={false}
              rules={[
                {
                  required: true,
                  message: "Please enter last name",
                  validateTrigger: onsubmit,
                },
              ]}
            >
              <Input placeholder="Enter Last Name" />
            </Form.Item>
          </Flex>
          <Form.Item
            name="email"
            label="Email"
            required={false}
            rules={[
              {
                required: true,
                message: "Please enter email",
                validateTrigger: onsubmit,
              },
              {
                type: "email",
                message: "Please enter a valid email",
              },
            ]}
          >
            <Input
              autoComplete="off"
              aria-autocomplete="off"
              placeholder="Enter email here"
            />
          </Form.Item>
          <Form.Item
            required={false}
            name="password"
            label="Password"
            rules={[
              {
                required: true,
                message: "Please enter a password",
              },
              {
                min: 8,
                message: "Password must be at least 8 characters",
              },
              {
                validator: (_, value) => {
                  const allowedPattern = /^[a-zA-Z\d!@#$%^&*]+$/;
                  console.log(value, !allowedPattern.test(value));

                  if (value && !allowedPattern.test(value)) {
                    return Promise.reject(
                      "Password contains invalid characters. Only letters, numbers, and !@#$%^&* are allowed."
                    );
                  }
                  const requiredPattern =
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])/;
                  if (value && !requiredPattern.test(value)) {
                    return Promise.reject(
                      "Password must include at least 1 number, 1 uppercase letter, 1 lowercase letter, and 1 special character (!@#$%^&*)."
                    );
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input.Password
              className="password-input"
              autoComplete="new-password"
              placeholder="Enter password here"
            />
          </Form.Item>

          <Flex vertical gap={0}>
            <span className="input-label">App Role</span>
            <Radio.Group onChange={onTypeChange} value={type.value}>
              <div
                className={`journey-type ${
                  type.value === "SUPER_ADMIN" && "active"
                }`}
                onClick={() =>
                  setType({
                    value: "SUPER_ADMIN",
                    error: false,
                  })
                }
              >
                <Radio value="SUPER_ADMIN">Super Admin</Radio>
              </div>
              <div
                className={`journey-type ${type.value === "STAFF" && "active"}`}
                onClick={() =>
                  setType({
                    value: "STAFF",
                    error: false,
                  })
                }
              >
                <Radio value="STAFF">Staff</Radio>
              </div>
            </Radio.Group>
          </Flex>
          <Flex vertical gap={0}>
            <span className="input-label">Organization Role</span>
            <Radio.Group onChange={onTypeChange} value={orgRole.value}>
              <div
                className={`journey-type ${
                  orgRole.value === "ADMIN" && "active"
                }`}
                onClick={() =>
                  setOrgRole({
                    value: "ADMIN",
                    error: false,
                  })
                }
              >
                <Radio value="ADMIN">Admin</Radio>
              </div>
              <div
                className={`journey-type ${
                  orgRole.value === "JOURNEY_DESIGNER" && "active"
                }`}
                onClick={() =>
                  setOrgRole({
                    value: "JOURNEY_DESIGNER",
                    error: false,
                  })
                }
              >
                <Radio value="JOURNEY_DESIGNER">Journey Designer</Radio>
              </div>
            </Radio.Group>
          </Flex>
          <Flex vertical>
            <span className="input-label">Organization</span>
            <Select
              placeholder="Select a organization"
              optionFilterProp="children"
              onChange={onCategoryChange}
              variant="borderless"
              popupMatchSelectWidth={true}
              allowClear
              popupClassName="sort-options"
              options={organizations}
              onPopupScroll={onscroll}
              value={category.value}
              loading={loading}
            />
            {category.error && (
              <span className="error-message">
                Please select a organization
              </span>
            )}
          </Flex>
        </Form>
      </div>
    </Modal>
  );
};

export default AddUserModal;
