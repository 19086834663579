import { Button, Flex, Input, Tooltip } from "antd";
import { ReactComponent as Edit } from "../../../assets/images/editPen.svg";
import { ReactComponent as Video } from "../../../assets/images/videoPrompt.svg";
import { ReactComponent as Subtitle } from "../../../assets/images/subtitle.svg";
import { ReactComponent as Slide } from "../../../assets/images/slide.svg";
import { capitalizeInput, videoStages } from "../../../utils/format";
import { CloseOutlined, CheckOutlined } from "@ant-design/icons";

const CustomHeader = ({
  title,
  titleDisabled,
  setTitleDisabled,
  setTitle,
  handleEntityTypeChange,
  selectedTexts,
  buttonsRef,
  updateTitle,
  status,
  usecaseData,
}) => {
  const items = [
    { type: "SUBTITLE", icon: <Subtitle /> },
    { type: "VIDEO", icon: <Video /> },
    { type: "SLIDE", icon: <Slide /> },
  ];

  return (
    <Flex flex={1} justify="space-between" align="center" gap={50}>
      {titleDisabled ? (
        <span className="usecase-header-title">{title}</span>
      ) : (
        <Input
          value={title}
          className={`usecase-title-input ${!titleDisabled ? "active" : ""}  ${
            title.error ? "is-invalid" : ""
          }`}
          disabled={titleDisabled}
          defaultValue={""}
          id="journey-title"
          onChange={(e) => setTitle(e.target.value)}
        />
      )}
      <Flex gap={20}>
        {titleDisabled ? (
          <Button
            className="edit-title"
            onClick={() => setTitleDisabled(false)}
            icon={<Edit />}
            color="white"
          >
            Edit Title
          </Button>
        ) : (
          <Flex gap={10}>
            <Button
              className="edit-title"
              onClick={() => updateTitle()}
              color="white"
            >
              <CheckOutlined />
            </Button>
            <Button
              className="edit-title"
              onClick={() => {
                setTitleDisabled(true);
                setTitle(usecaseData?.title);
              }}
              color="white"
            >
              <CloseOutlined />
            </Button>
          </Flex>
        )}
        {!videoStages.includes(status) && (
          <Flex gap={20} ref={buttonsRef}>
            {items.map((item) => (
              <Tooltip
                title={`${
                  selectedTexts ? "" : "Please select at least one sentence"
                }`}
              >
                <Button
                  className="edit-title"
                  disabled={!selectedTexts}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleEntityTypeChange(item.type);
                  }}
                  icon={item.icon}
                  color="white"
                >
                  Add {capitalizeInput(item.type)}
                </Button>
              </Tooltip>
            ))}
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};
export default CustomHeader;
