import React, { useEffect, useState } from "react";
import JourneyConfig from "../../components/journeyConfig/journeyConfig";
import JourneyHeader from "../../components/header/journeyHeader";
import ChatBot from "../../components/chatbot/chatbot";
import "./journey.css";
import { Col, ConfigProvider, Layout, Row } from "antd";
import { PRIMARY_COLOR } from "../../utils/colors";
import { useOutletContext, useParams } from "react-router-dom";
import JourneyDetail from "../../components/journeyDetail/journeyDetail";
import { getUserDetails } from "../../api/profileServices";
import Breadcrumbs from "../../components/breadcrumbs/breadcrumbs";
const { Content } = Layout;
const Journey = () => {
  // const [displayName, setDisplayName] = useState("");
  const [, displayName, setDisplayName] = useOutletContext();
  const [userDetails, setuserDetails] = useState();
  const [channel, setchannel] = useState("");
  const [collapse, setCollapse] = useState("");

  const params = useParams();

  useEffect(() => {
    getUserDetails()
      .then((res) => {
        console.log(res);
        setuserDetails(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: '"Poppins", sans-serif',

          Input: {
            colorBorder: "transparent",
            colorBgContainerDisabled: "#FFFFFF",
            hoverBorderColor: PRIMARY_COLOR,
            activeBorderColor: PRIMARY_COLOR,
            activeShadow: "none",
            controlHeight: 45,
            colorTextPlaceholder: "#9EA5AD",
            colorTextDisabled: PRIMARY_COLOR,
          },
          InputNumber: {
            colorBorder: "transparent",
            colorBgContainerDisabled: "transparent",
            hoverBorderColor: PRIMARY_COLOR,
            activeBorderColor: PRIMARY_COLOR,
            activeShadow: "none",
            controlHeight: 45,
            colorTextPlaceholder: "#9EA5AD",
            controlWidth: 250,
          },
          Button: {
            colorPrimary: PRIMARY_COLOR,
            colorPrimaryHover: PRIMARY_COLOR,
            colorPrimaryActive: PRIMARY_COLOR,
            primaryShadow: "none",
            controlHeight: 40,
          },
        },
        components: {
          Select: {
            colorTextPlaceholder: "#9EA5AD",
          },
          Layout: {
            headerBg: "#ffffff",
            headerPadding: "0 20px",
          },
          Tag: {
            defaultColor: PRIMARY_COLOR,
            defaultBg: "#B1F4DC",
          },
          Collapse: {
            contentBg: "#F5F7FA",
            borderRadiusLG: 8,
            headerPadding: 20,
            headerBg: "#f2f4f7",
            colorBorder: "transparent",
            contentPadding: "16px 0 0",
          },
          Menu: {
            darkItemBg: "#FFFFFF",
            darkItemColor: PRIMARY_COLOR,
            darkItemSelectedBg: PRIMARY_COLOR,
            darkItemSelectedColor: "#FFFFFF",
            itemMarginInline: "0",
            itemPaddingInline: 10,
            fontSize: 12,
            fontWeightStrong: 400,
            darkItemHoverColor: PRIMARY_COLOR,
            darkItemHoverBg: "#0000000f",
          },
          Drawer: {
            // footerPaddingBlock: 30,
            paddingLG: 10,
          },
        },
      }}
    >
      {/* <Layout>
        <JourneyHeader displayName={displayName} userDetails={userDetails}/>
        <Content
          style={{
            overflow: "initial",
          }}
        > */}
      {params.operation && params.operation !== "view" ? (
        <Row className="journey-container">
          <Col span={collapse ? 6 : 10} className="chatbot-container">
            <ChatBot
              channel={channel}
              type={"Journey"}
              userDetails={userDetails}
              collapse={collapse}
              setCollapse={setCollapse}
            />
          </Col>
          <Col span={collapse ? 18 : 14} className="journey-config-container">
            <JourneyConfig
              setDisplayName={setDisplayName}
              setchannel={setchannel}
              displayName={displayName}
            />
          </Col>
        </Row>
      ) : (
        <div className="jorney-container">
          <JourneyDetail setDisplayName={setDisplayName} />
        </div>
      )}
      {/* </Content>
      </Layout> */}
    </ConfigProvider>
  );
};

export default Journey;
