import React, { useCallback, useEffect, useState } from "react";
import "./diyQuestions.css";
import { Button, Col, Flex, Form, Input, Row, Skeleton } from "antd";
import { endAt, limitToLast, onChildAdded, ref } from "firebase/database";
import { dataBase } from "../../firebase/firebase";
import { query } from "firebase/firestore";
import {
  getScripts,
  submitDemoScriptResult,
  submitScriptResult,
} from "../../../api/hubSpotService";
import NotificationInstance from "../../../services/notificationServices";
import {
  GoogleReCaptcha,
  GoogleReCaptchaProvider,
} from "react-google-recaptcha-v3";
const { TextArea } = Input;

const DiyQuestions = ({
  script,
  setScript,
  questions,
  loading,
  formData,
  setFormData,
  setCurrent,
  title,
  demoId,
  token,
  setVideoId,
  lang,
  diy,
}) => {
  const [completed, setCompleted] = useState(false);
  const [generating, setGenerating] = useState(false);
  const [step, setStep] = useState(1);
  const [reCaptchatoken, setreCaptchaToken] = useState("");
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
  const [form] = Form.useForm();

  const handleFinish = (values) => {
    setGenerating(true);
    const { email, name } = values;
    const answers = Object.keys(formData)
      .filter((key) => key !== "email")
      .map((key) => ({
        questionId: key,
        answer: formData[key],
      }));
    const newFormData = { title, answers, email, name };

    submitDemoScriptResult(newFormData, demoId, token, lang)
      .then((res) => {
        setVideoId(res?.data?.videoId);
        setCompleted(true);
        setFormData(values);
        setCurrent((prev) => prev + 1);
      })
      .catch((err) => {
        setGenerating(false);
        NotificationInstance.info({ message: "Please try again later" });
      });
  };
  const handleFinishFailed = ({ errorFields }) => {
    if (errorFields?.length > 0) {
      const errorf = errorFields[0]?.name[0];
      form.scrollToField(errorf, {
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    }
  };

  const handleNext = async () => {
    try {
      await form.validateFields().then((values) => {
        setFormData((prevData) => ({ ...prevData, ...values }));
      });
      setStep((prev) => prev + 1);
    } catch (error) {
      console.log("Validation failed:", error);
    }
  };
  const handlePrevious = async () => {
    if (step === 1) {
      setCurrent((prev) => prev - 1);
    } else {
      setStep((prev) => prev - 1);
    }
  };
  const onVerify = useCallback((token) => {
    setreCaptchaToken(token);
  }, []);
  return (
    <Row align="center" className="diy-question-container">
      <Col span={24} className="diy-container">
        {loading ? (
          <Flex vertical gap={30} align="flex-start" className="question-form">
            <Flex vertical style={{ width: "100%" }} gap={20}>
              <Flex gap={20} flex={1}>
                {Array.from({ length: 10 }).map((_, index) => (
                  <Skeleton.Button
                    className="skeleton-loader"
                    block
                    active
                    size={6}
                    style={{ width: "100%" }}
                  />
                ))}
              </Flex>
              <Skeleton
                block
                active
                size={80}
                style={{ width: "100%" }}
                paragraph={{ rows: 1 }}
              />
              <Skeleton.Button
                block
                active
                size={100}
                style={{ width: "100%" }}
              />
              <Flex gap={20}>
                <Skeleton.Button
                  block
                  active
                  size={45}
                  style={{ width: "100%" }}
                />{" "}
                <Skeleton.Button
                  block
                  active
                  size={45}
                  style={{ width: "100%" }}
                />
              </Flex>
            </Flex>
          </Flex>
        ) : (
          <>
            <Form
              form={form}
              onFinish={handleFinish}
              layout="vertical"
              className="question-form"
              onFinishFailed={handleFinishFailed}
              initialValues={formData}
            >
              <Flex gap={5}>
                {Array.from({ length: questions?.length + 1 }).map(
                  (_, index) => (
                    <div
                      className={`question-progress ${
                        index < step ? "completed" : ""
                      }`}
                    />
                  )
                )}
              </Flex>
              <Flex vertical gap={10}>
                {questions
                  ?.filter((item, index) => step === index + 1)
                  .map((question, index) => {
                    return (
                      <Form.Item
                        required={false}
                        key={index}
                        label={
                          <h2 className="diy-question">
                            {lang === "de"
                              ? question?.questionTextDe
                              : question?.questionText}
                          </h2>
                        }
                        name={question?._id}
                        rules={[
                          {
                            required: true,
                            message: diy[lang].videoQuestion.error,
                          },
                        ]}
                      >
                        <TextArea
                          spellCheck={false}
                          rows={5}
                          className="answer-input diy-answer"
                          placeholder={diy[lang].videoQuestion.placeholder}
                        />
                      </Form.Item>
                    );
                  })}
                {questions?.length + 1 === step && (
                  <Flex vertical className="diy-email">
                    <div className="diy-email-input">
                      <Flex justify="center" vertical align="center">
                        <span className="celeb">
                          🎉🎉 {diy[lang].videoSubmit.description1}
                        </span>
                        <p className="email-desc">
                          {diy[lang].videoSubmit.description2}
                        </p>
                        <span className="email-input">
                          {diy[lang].videoSubmit.label}
                        </span>
                      </Flex>
                      <Form.Item
                        required={false}
                        className="name"
                        label={""}
                        name={"name"}
                        rules={[
                          {
                            required: true,
                            message: diy[lang].videoSubmit.error,
                          },
                        ]}
                      >
                        <Input
                          type="name"
                          className="answer-input"
                          placeholder={diy[lang].videoSubmit.placeholder}
                        />
                      </Form.Item>
                      <Form.Item
                        required={false}
                        className="email"
                        label={""}
                        name={"email"}
                        rules={[
                          {
                            required: true,
                            message: diy[lang].videoSubmit.errorEmail,
                          },
                        ]}
                      >
                        <Input
                          type="email"
                          className="answer-input"
                          placeholder={diy[lang].videoSubmit.placeholderEmail}
                        />
                      </Form.Item>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: diy[lang].videoSubmit.description3,
                        }}
                      />
                    </div>
                  </Flex>
                )}
                <Flex
                  justify="flex-end"
                  flex={1}
                  gap={20}
                  className="question-actions"
                >
                  {step !== questions?.length + 1 && (
                    <Button onClick={handlePrevious}>
                      {diy[lang].button.previous}
                    </Button>
                  )}
                  {step === questions?.length + 1 && (
                    <Form.Item style={{ width: "100%" }}>
                      <Button htmlType="submit" block type="primary">
                        {!generating ? (
                          diy[lang].button.submit
                        ) : (
                          <div className="chat-loader"></div>
                        )}
                      </Button>
                    </Form.Item>
                  )}
                  {step !== questions?.length + 1 && (
                    <Button type="primary" onClick={handleNext}>
                      {diy[lang].button.next}
                    </Button>
                  )}
                </Flex>
              </Flex>
            </Form>
          </>
        )}
      </Col>
    </Row>
  );
};

export default DiyQuestions;
