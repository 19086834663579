import { Button, Modal, Progress, Upload } from "antd";
import React, { useState } from "react";
import "./modal.css";
import { ReactComponent as UploadIcon } from "../../assets/images/upload.svg";
import { ReactComponent as Image } from "../../assets/images/placeholder.svg";
import NotificationInstance from "../../services/notificationServices";

const AddImageModal = ({
  isModalOpen,
  handleOk,
  handleCancel,
  imageUrl,
  type,
}) => {
  const [file, setFile] = useState({
    image: imageUrl ? imageUrl : null,
    updated: false,
  });
  const [previewImage, setPreviewImage] = useState(imageUrl ? imageUrl : "");
  const [progress, setProgress] = useState("");
  const [uploading, setUploading] = useState(false);
  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  const handleChange = async (info) => {
    if (!info.file.type.match(/image\/(jpeg|png)/)) {
      return;
    }
    const fileSize = info.file.size / 1024 / 1024; // Size in MB
    if (fileSize > 5) {
      return;
    }
    setFile({ image: info.file, updated: true });
    if (!info.file.url && !info.file.preview) {
      info.file.preview = await getBase64(info.file.originFileObj);
      setPreviewImage(info.file.preview);
    } else {
      setPreviewImage(info.file.url || info.file.preview);
    }
    if (info.file.status === "uploading") {
      setUploading(true);
      setProgress(info.file.percent);
    }
    if (info.file.status === "done") {
      setProgress(100);
      setTimeout(() => {
        setUploading(false);
      }, 500);
    } else if (info.file.status === "error") {
      setUploading(false);
      setProgress(0);
    }
  };

  const customRequest = async ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };
  const beforeUpload = (file) => {
    const isImage = file.type === "image/jpeg" || file.type === "image/png";
    const fileSize = file.size / 1024 / 1024;
    if (!isImage) {
      NotificationInstance.warning({
        message: "Please upload only JPG, JPEG or PNG image files!",
      });
      return false;
    }
    if (fileSize > 4) {
      NotificationInstance.warning({
        message: "The image size exceeds 5MB. Please select a smaller image.",
      });
      return false;
    }

    return true;
  };
  const onSubmit = () => {
    if (file.image) {
      if (file.updated) {
        handleOk(file.image, "object");
        handleCancel();
      } else {
        handleCancel();
      }
    } else {
      NotificationInstance.warning({
        message: "Please upload a JPG, JPEG or PNG image!",
      });
    }
  };
  const formatSize = (size) => {
    if (size < 1024) {
      return `${size}B`;
    } else if (size < 1024 * 1024) {
      return `${Math.floor(size / 1024)}Kb`;
    } else {
      return `${Math.floor(size / (1024 * 1024))}Mb`;
    }
  };
  const onModalClose = () => {
    setFile({ image: imageUrl ? imageUrl : null, updated: false });
    setPreviewImage(
      imageUrl ? (type === "object" ? imageUrl?.preview : imageUrl) : ""
    );
    handleCancel();
  };
  return (
    <Modal
      centered
      width={450}
      title="Add Thumbnail image"
      open={isModalOpen}
      onCancel={onModalClose}
      footer={() => (
        <div className="action-buttons">
          <Button type="primary" onClick={onSubmit}>
            Add Image
          </Button>
          <Button className="cancel-button" onClick={onModalClose}>
            Cancel
          </Button>
        </div>
      )}
    >
      <div className="thumbnail-upload">
        {uploading ? (
          <>
            <Image />
            <div className="image-details">
              {file?.image?.name && (
                <span className="image-name">{file?.image?.name}</span>
              )}
              {file?.image?.size && (
                <span className="image-size">
                  {formatSize(file?.image?.size)}
                </span>
              )}
            </div>
            <Progress
              showInfo={false}
              strokeColor={"#262528"}
              percent={progress}
            />
          </>
        ) : (
          <>
            {previewImage ? (
              <>
                <img className="preview-thumbnail" src={previewImage} alt="thumbnail"/>
                {file?.image?.name && (
                  <span className="image-name">{file?.image?.name}</span>
                )}
                {file?.image?.size && (
                  <span className="image-size">
                    {formatSize(file?.image?.size)}
                  </span>
                )}
              </>
            ) : (
              <>
                <UploadIcon />
                <span className="browse">Browse and Upload an image</span>
                <span className="supported-files">
                  JPEG or PNG formats, up to 5MB
                </span>
              </>
            )}
            <Upload
              listType="picture"
              maxCount={1}
              multiple={false}
              onChange={handleChange}
              customRequest={customRequest}
              beforeUpload={beforeUpload}
              showUploadList={false}
              accept=".png,.jpg,.jpeg"
            >
              <Button>{file.image ? "Change Image" : "Browse File"}</Button>
            </Upload>
          </>
        )}
      </div>
    </Modal>
  );
};

export default AddImageModal;
