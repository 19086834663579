import { Upload } from "antd";
import React, { useState } from "react";
import NotificationInstance from "../../../services/notificationServices";
import { uploadLessonDoc } from "../../../api/adminService";

const LessonDocument = ({
  file,
  courseId,
  lessonId,
  updateLesson,
}) => {
  const [uploading, setUploading] = useState(false);
  const { Dragger } = Upload;
  const handleChange = async (info) => {
    const file = info.file;
    const isSupportedFileType =
      file.type === "application/msword" || // DOC
      file.type ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document" || // DOCX
      file.type === "text/plain" || // TXT
      file.type === "application/pdf"; // PDF
    if (!isSupportedFileType) {
      return;
    }
    const fileSize = file.size / 1024 / 1024;
    if (fileSize > 10) {
      NotificationInstance.warning({
        message: "File must be smaller than 10MB!",
      });
      return;
    }
  };

  const customRequest = async ({ file }) => {
    setUploading(true);
    const fileData = new FormData();
    fileData.append("lessonBrief", file);
    uploadLessonDoc(courseId, lessonId, fileData)
      .then((res) => {
        setUploading(false);
        updateLesson(res?.data?.userContent);
      })
      .catch((err) => {
        setUploading(false);
        console.log(err);
      });
  };

  const beforeUpload = (file) => {
    const isSupportedFileType =
      file.type === "application/msword" || // DOC
      file.type ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document" || // DOCX
      file.type === "text/plain" || // TXT
      file.type === "application/pdf"; // PDF

    const fileSize = file.size / 1024 / 1024;

    if (!isSupportedFileType) {
      NotificationInstance.warning({
        message: "Please upload only DOC, DOCX, TXT, or PDF files!",
      });
      return false;
    }

    if (fileSize > 10) {
      NotificationInstance.warning({
        message: "File must be smaller than 10MB!",
      });
      return false;
    }

    return true;
  };

  return (
    <Dragger
      listType="picture"
      maxCount={1}
      multiple={false}
      onChange={handleChange}
      customRequest={customRequest}
      beforeUpload={beforeUpload}
      showUploadList={false}
      accept=".doc,.docx,.txt,.pdf"
      disabled={uploading}
    >
      {uploading ? (
        <p className="ant-upload-text">Uploading lesson content...</p>
      ) : (
        <p className="ant-upload-text">
          Click or drag file to this area to upload
        </p>
      )}
    </Dragger>
  );
};

export default LessonDocument;
