import { notification } from "antd";
const NotificationInstance = notification;

NotificationInstance.config({
  placement: "top",
  maxCount: 2,
  duration: 3,
});

export default NotificationInstance;
