import {
  Avatar,
  Button,
  Flex,
  Form,
  Input,
  Modal,
  Radio,
  Select,
  Skeleton,
} from "antd";
import React, { useEffect, useState } from "react";
import "./modal.css";
import { createVideoUseCase } from "../../api/adminService";
import NotificationInstance from "../../services/notificationServices";
import { videoTypes } from "../../utils/format";
import { getContacts } from "../../api/hubSpotService";
import { getProjectDocumentById } from "../../api/projectServices";

const customOption = (option) => {
  return (
    <Flex gap={10} align="center">
      <Avatar
        shape={"circle"}
        src={`https://ui-avatars.com/api/?name=${option.data.label}&background=101A24&color=fff`}
        size={30}
      />
      <Flex vertical>
        <b>{option.data.label}</b>
        <span role="img" aria-label={option.data.label}>
          {option.data.email}
        </span>
      </Flex>
    </Flex>
  );
};

const CourseDocument = ({
  isModalOpen,
  handleOk,
  handleCancel,
  projectId,
  fileId,
  setFileIId,
}) => {
  const [document, setDocument] = useState();
  const [documentLoading, setDocumentLoading] = useState();

  useEffect(() => {
    if (fileId && projectId) {
      setDocumentLoading(true);
      getProjectDocumentById(projectId, fileId)
        .then((res) => {
          setDocumentLoading(false);
          setDocument(res.data);
        })
        .catch((err) => {
          setDocumentLoading(false);
        });
    }
  }, [projectId, fileId]);

  const onModalClose = () => {
    setDocument();
    handleCancel();
    setFileIId();
  };
  return (
    <Modal
      centered
      width={700}
      className="project-document"
      title={documentLoading ? <Skeleton.Input /> : document?.title}
      open={isModalOpen}
      onCancel={onModalClose}
      footer={() => (
        <Flex justify="flex-end">
          <Button className="cancel-button" onClick={onModalClose}>
            Close
          </Button>
        </Flex>
      )}
    >
      {documentLoading ? (
        <div
          className="video-loader"
          style={{ width: "100%", marginTop: "20px" }}
        >
          <div className="loader"></div>
        </div>
      ) : (
        <Input.TextArea
          readOnly={true}
          value={document?.textContent}
          autoSize={{ minRows: 20, maxRows: 20 }}
        />
      )}
    </Modal>
  );
};

export default CourseDocument;
