// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getDatabase } from "firebase/database";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
//dev
const firebaseConfig = {
  apiKey: "AIzaSyAd_AY5l5Erx-mMFubSmIgqqnVK7C5nSa0",
  authDomain: "leaproad-chat.firebaseapp.com",
  databaseURL: "https://leaproad-chat-default-rtdb.firebaseio.com",
  projectId: "leaproad-chat",
  storageBucket: "leaproad-chat.appspot.com",
  messagingSenderId: "342617625232",
  appId: "1:342617625232:web:e15847890f415fac3f3e15",
  measurementId: "G-F1JRHVFFE6",
};
//demo
// const firebaseConfig = {
//   apiKey: "AIzaSyDdNmeEnazxRHPlKg9EEPSimi8aAlc0y2w",
//   authDomain: "leaproad-demo.firebaseapp.com",
//   databaseURL: "https://leaproad-demo-default-rtdb.firebaseio.com",
//   projectId: "leaproad-demo",
//   storageBucket: "leaproad-demo.appspot.com",
//   messagingSenderId: "935669753122",
//   appId: "1:935669753122:web:f25ff48e676806fef0317b",
//   measurementId: "G-XSCH8X5JGE"
// };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
const dataBase = getDatabase(app);
export { dataBase };
