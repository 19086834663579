import React, { useEffect } from "react";
import { Flex, Layout, Menu, Tooltip } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import {
  MenuFoldOutlined,
  AppstoreOutlined,
  TeamOutlined,
  VideoCameraOutlined,
  DoubleRightOutlined,
  DoubleLeftOutlined,
  DatabaseOutlined,
  ProjectOutlined
} from "@ant-design/icons";

const { Sider } = Layout;

const Sidebar = ({ collapsed, setCollapsed }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const onClick = (e) => {
    handleNavigate(e.key);
  };

  const handleNavigate = (path) => {
    navigate(path);
  };

  useEffect(() => {
    if (location.pathname.includes("edit") || location.pathname.includes("view")) {
      setCollapsed(true);
    } else {
      setCollapsed(false);
    }
  }, [location.pathname]);

  // Extract the base path (e.g., "/videos" or "/courses") from location.pathname
  const selectedKey = `/${location.pathname.split("/")[1]}`;

  const menuItems = [
    { key: "/", label: "Journeys", icon: <MenuFoldOutlined /> },
    { key: "/videos", label: "Videos", icon: <VideoCameraOutlined /> },
    { key: "/courses", label: "Courses", icon: <DatabaseOutlined /> },
    { key: "/projects", label: "Projects", icon: <ProjectOutlined /> },
    { key: "/users", label: "Users", icon: <TeamOutlined /> },
    { key: "/categories", label: "Categories", icon: <AppstoreOutlined /> },
  ];

  return (
    <Sider
      className="sidebar"
      width={240}
      style={{
        height: "100%",
        position: "fixed",
      }}
      trigger={null}
      defaultCollapsed={false}
      collapsible
      collapsed={collapsed}
      onCollapse={(value) => setCollapsed(value)}
    >
      <div
        style={{
          height: "100vh",
          maxHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          paddingTop: 20,
        }}
      >
        <Menu
          mode="vertical"
          defaultSelectedKeys={["/"]}
          selectedKeys={[selectedKey]} 
          onClick={onClick}
          items={menuItems}
        />
        <div
          className="ant-layout-sider-trigger collapse"
          style={{ width: collapsed ? 80 : 240 }}
          onClick={() => setCollapsed(!collapsed)}
        >
          <Tooltip title={collapsed ? "Expand" : ""} placement="right">
            <Flex
              align="center"
              justify="center"
              className="collapse-container"
              gap={5}
            >
              {collapsed ? (
                <DoubleRightOutlined />
              ) : (
                <>
                  <DoubleLeftOutlined /> Collapse
                </>
              )}
            </Flex>
          </Tooltip>
        </div>
      </div>
    </Sider>
  );
};

export default Sidebar;
