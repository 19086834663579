import React, { useContext } from "react";
import "./login.css";
import { Form, Input, Button, ConfigProvider } from "antd";
import { useNavigate } from "react-router-dom";
import { loginService } from "../../api/adminService";
import { ReactComponent as Logo } from "../../assets/images/logo.svg";
import { ReactComponent as Email } from "../../assets/images/email.svg";
import NotificationInstance from "../../services/notificationServices";
import AuthContext from "../../services/storageService";
import { PRIMARY_COLOR } from "../../utils/colors";

const Login = () => {
  const navigate = useNavigate();
  const auth = useContext(AuthContext);
  const onFinish = (data) => {
    loginService(data.email, data.password)
      .then((res) => {
        NotificationInstance.success({
          message: "Successful",
          description: "Welcome back! You've successfully logged in.",
        });
        auth.login(res?.data?.accessToken);
        navigate("/");
      })
      .catch((error) => {
        NotificationInstance.error({
          message: "Incorrect username or password.",
          description: "Please verify & try again.",
        });
      });
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <div className="leaproad-logo">
          <Logo />
        </div>
        <p className="login-content">
          Log in to Leap Road using email id and password
        </p>
        <ConfigProvider
          theme={{
            token: {
              fontFamily: '"Poppins", sans-serif',
              Button: {
                colorPrimary: PRIMARY_COLOR,
                colorPrimaryHover: PRIMARY_COLOR,
                colorPrimaryActive: PRIMARY_COLOR,
                primaryShadow: "none",
                controlHeight: 45,
              },
              Input: {
                colorBorder: "transparent",
                hoverBorderColor: PRIMARY_COLOR,
                activeBorderColor: PRIMARY_COLOR,
                activeShadow: "none",
                controlHeight: 45,
                colorTextPlaceholder: "#9EA5AD",
              },
            },
          }}
        >
          <Form
            name="login-form"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            layout="vertical"
            className="login-form-container"
          >
            <Form.Item
              required={false}
              name="email"
              label="Email or Username"
              rules={[
                {
                  required: true,
                  message: "Please enter your email or username",
                },
                {
                  type: "email",
                  message: "Please enter a valid email",
                },
              ]}
            >
              <Input
                prefix={<Email />}
                placeholder="user@leaproad.com"
                width={350}
              />
            </Form.Item>

            <Form.Item
              required={false}
              name="password"
              label="Password"
              rules={[
                { required: true, message: "Please enter your password" },
              ]}
            >
              <Input.Password placeholder="Enter password" />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="submit-button"
                block
              >
                Login
              </Button>
            </Form.Item>
          </Form>
          <div className="forgot-password">
            <a href="/forgot-password">Forgot your Password?</a>
          </div>
        </ConfigProvider>
      </div>
    </div>
  );
};

export default Login;
