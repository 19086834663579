import { Col, Flex, Row, Skeleton } from "antd";
import React from "react";

import "./courseLessons.css";
const CourseLessons = ({
  lessons,
  updateStep,
  setType,
  setsectionStep,
  sectionStep,
  handleChangeSectionStep,
}) => {
  const handleChangeSection = (item) => {
    if (item.id !== sectionStep.id) {
      handleChangeSectionStep(item);
    }
    // setsectionStep(item);
    // setType(item.type);
  };
  const getStatus = (status) => {
    if (status === "CREATED") {
      return "CREATED";
    } else if (
      status === "COMPLETED" ||
      status === "COURSE_BRIEFING_CONFIRMED" ||
      status === "VIDEO_GENERATION_COMPLETED"
    ) {
      return "COMPLETED";
    } else {
      return "In Progress";
    }
  };

  return (
    <Row className="course-lesson">
      <Col span={24} style={{ height: "100%" }}>
        <Flex vertical gap={10} style={{ height: "100%", overflow: "hidden" }}>
          <span className="course-lesson-title">Course Sections</span>
          {lessons?.length > 0 ? (
            <div
              className="lesson-container"
              style={{ flex: 1, overflowY: "auto" }}
            >
              {lessons?.map((item, index) => (
                <Flex
                  key={item.id}
                  gap={10}
                  className={`lesson ${
                    item.id === sectionStep.id ? "selected" : ""
                  }`}
                  onClick={() => handleChangeSection(item)}
                >
                  <div className="selected-lesson" />
                  <Flex vertical gap={10}>
                    <span className="lesson-title">{item.title}</span>
                    <Flex gap={10} align="center">
                      <span className={`status ${item?.status?.toLowerCase()}`}>
                        {getStatus(item?.status)}
                      </span>
                      {/* <span
                      className="course-attachment"
                      onClick={() => setIsDetailBoxOpen(true)}
                    >
                      View script
                    </span> */}
                    </Flex>
                  </Flex>
                </Flex>
              ))}
            </div>
          ) : (
            <Flex
              flex={1}
              vertical
              gap={20}
              align="center"
              className="lesson-container"
            >
              {Array.from({ length: 4 }).map((_, index) => (
                <Skeleton.Button
                  className="skeleton-loader"
                  block
                  active
                  size={100}
                  style={{ width: "100%" }}
                />
              ))}
            </Flex>
          )}
        </Flex>
      </Col>
    </Row>
  );
};

export default CourseLessons;
