import React, { useEffect, useState } from "react";
import "./login.css";
import { Form, Input, Button, ConfigProvider } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { resetPassword, validateToken } from "../../api/adminService";
import { ReactComponent as Logo } from "../../assets/images/logo.svg";
import NotificationInstance from "../../services/notificationServices";
import { PRIMARY_COLOR } from "../../utils/colors";
import ExpiredModal from "../../components/modals/expiredModal";

const ResetPassword = () => {
  const navigate = useNavigate();
  const [password, SetPassword] = useState();
  const [tokenExpired, setTokenExpired] = useState(false);
  const [usedToken, setUsedToken] = useState(false);
  const [invalid, setInvalid] = useState(false);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const token = params.get("token");

  useEffect(() => {
    if (token) {
      validateToken(token)
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
          if (err?.response?.status === 400) {
            setUsedToken(true);
            setTokenExpired(true);
          } else if (err?.response?.status === 401) {
            if (err?.response?.data?.message === "Invalid token") {
              setInvalid(true);
            }
            setTokenExpired(true);
          }
        });
    }
  }, [token]);
  const onChange = (e) => {
    SetPassword(e.target.value);
  };
  const onFinish = (data) => {
    resetPassword(data.password, token)
      .then((res) => {
        NotificationInstance.success({
          message: "Password Reset Successful",
          description: "Your password has been successfully reset.",
        });
        navigate("/login");
      })
      .catch((error) => {
        NotificationInstance.error({
          description: "Please verify & try again.",
        });
      });
  };
  return (
    <div className="login-container">
      <div className="login-box">
        <div className="leaproad-logo">
          <Logo />
        </div>
        {/* <p className="login-content">
          Log in to Leap Road using email id and password
        </p> */}
        <ConfigProvider
          theme={{
            token: {
              fontFamily: '"Poppins", sans-serif',
              Button: {
                colorPrimary: PRIMARY_COLOR,
                colorPrimaryHover: PRIMARY_COLOR,
                colorPrimaryActive: PRIMARY_COLOR,
                primaryShadow: "none",
                controlHeight: 45,
              },
              Input: {
                colorBorder: "transparent",
                hoverBorderColor: PRIMARY_COLOR,
                activeBorderColor: PRIMARY_COLOR,
                activeShadow: "none",
                controlHeight: 45,
                colorTextPlaceholder: "#9EA5AD",
              },
            },
          }}
        >
          <ExpiredModal
            isModalOpen={tokenExpired}
            usedToken={usedToken}
            invalid={invalid}
          />
          {!tokenExpired && (
            <Form
              name="login-form"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              layout="vertical"
              className="login-form-container"
            >
              <Form.Item
                required={false}
                name="password"
                label="Password"
                rules={[
                  {
                    required: true,
                    message: "Please enter a password",
                  },
                  {
                    min: 8,
                    message: "Password must be at least 8 characters",
                  },
                  {
                    validator: (_, value) => {
                      const allowedPattern = /^[a-zA-Z\d!@#$%^&*]+$/;
                      console.log(value, !allowedPattern.test(value));

                      if (value && !allowedPattern.test(value)) {
                        return Promise.reject(
                          "Password contains invalid characters. Only letters, numbers, and !@#$%^&* are allowed."
                        );
                      }
                      const requiredPattern =
                        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])/;
                      if (value && !requiredPattern.test(value)) {
                        return Promise.reject(
                          "Password must include at least 1 number, 1 uppercase letter, 1 lowercase letter, and 1 special character (!@#$%^&*)."
                        );
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input.Password
                  onChange={onChange}
                  placeholder="Enter password"
                />
              </Form.Item>
              <Form.Item
                required={false}
                name="ConfirmPassword"
                label="Confirm Password"
                rules={[
                  {
                    required: true,
                    message: "Please re-enter password!",
                  },
                  {
                    validateTrigger: { onFinish },
                    validator: (_, value) => {
                      if (!value) {
                        return Promise.resolve();
                      } else if (value !== password) {
                        return Promise.reject("Password does not match");
                      } else {
                        return Promise.resolve();
                      }
                    },
                  },
                ]}
              >
                <Input.Password placeholder="Confirm your password" />
              </Form.Item>

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="submit-button"
                  block
                >
                  Reset Password
                </Button>
              </Form.Item>
            </Form>
          )}
        </ConfigProvider>
      </div>
    </div>
  );
};

export default ResetPassword;
