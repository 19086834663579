export const rawContent = {
  blocks: [
    {
      text: "",
      type: "unstyled",
      depth: 0,
      entityRanges: [],
    },
  ],
  entityMap: {
    0: {
      type: "SUBTITLE",
      mutability: "IMMUTABLE",
    },
    1: {
      type: "VIDEO",
      mutability: "IMMUTABLE",
    },
    2: {
      type: "SLIDE",
      mutability: "IMMUTABLE",
    },
    subtitle: {
      type: "SUBTITLE",
      mutability: "IMMUTABLE",
    },
    video: {
      type: "VIDEO",
      mutability: "IMMUTABLE",
    },
    slide: {
      type: "SLIDE",
      mutability: "IMMUTABLE",
    },
  },
};

//  export const createBlocksFromEntities = (entitiesArray) => {
//     console.log(entitiesArray);

//     const blocks = [];
//     const entityMap = {};
//     let currentBlock = { text: "", type: "unstyled", depth: 0, entityRanges: [] };
//     let entityCounter = 0;
//     let currentTextLength = 0; // Track the current text length for entity ranges

//     entitiesArray.forEach((entity, index) => {
//       // Check if the text contains a newline
//       if (entity.text.includes("\n")) {
//         const parts = entity.text.split("\n");

//         // Handle parts before and after \n
//         parts.forEach((part, partIndex) => {
//           if (partIndex > 0) {
//             // Push the current block and reset
//             if (currentBlock.text) {
//               blocks.push(currentBlock);
//             }
//             currentBlock = {
//               text: "",
//               type: "unstyled",
//               depth: 0,
//               entityRanges: [],
//             };
//             currentTextLength = 0; // Reset text length
//           }

//           // Add the text part
//           currentBlock.text += part;

//           // If the current entity is not of type NORMAL, add entity range
//           if (entity.type !== "NORMAL") {
//             const entityKey = entityCounter++;
//             entityMap[entityKey] = {
//               type: entity.type,
//               mutability: "IMMUTABLE",
//               data: {},
//             };

//             // Populate entity data based on type
//             if (entity.type === "subtitle") {
//               entityMap[entityKey].data.text = entity.subtitleText;
//             } else if (entity.type === "video") {
//               entityMap[entityKey].data.text = entity.promptText;
//               entityMap[entityKey].data.video = entity.stockVideoData;
//             } else if (entity.type === "slide") {
//               entityMap[entityKey].data.text = entity.slideNumber;
//             }

//             // Add entity range for this entity in the current block
//             currentBlock.entityRanges.push({
//               offset: currentTextLength,
//               length: part.length,
//               key: entityKey,
//             });
//           }

//           // Update the current text length
//           currentTextLength += part.length;
//         });
//       } else {
//         // If there's no newline, add the text and entity range to the same block
//         currentBlock.text += entity.text;

//         // Add entity range if it's not a NORMAL type
//         if (entity.type !== "NORMAL") {
//           const entityKey = entityCounter++;
//           entityMap[entityKey] = {
//             type: entity.type,
//             mutability: "IMMUTABLE",
//             data: {},
//           };

//           if (entity.type === "subtitle") {
//             entityMap[entityKey].data.text = entity.subtitleText;
//           } else if (entity.type === "video") {
//             entityMap[entityKey].data.text = entity.promptText;
//             entityMap[entityKey].data.video = entity.stockVideoData;
//           } else if (entity.type === "slide") {
//             entityMap[entityKey].data.text = entity.slideNumber;
//           }

//           // Add entity range for this entity in the current block
//           currentBlock.entityRanges.push({
//             offset: currentTextLength,
//             length: entity.text.length,
//             key: entityKey,
//           });
//         }

//         // Update the current text length
//         currentTextLength += entity.text.length;
//       }
//     });

//     // Push the last block if it has text
//     if (currentBlock.text) {
//       blocks.push(currentBlock);
//     }

//     // Return the final block structure and entity map
//     return {
//       blocks,
//       entityMap: entityMap,
//     };
//   };

export const createBlocksFromEntities = (entitiesArray) => {
  console.log(entitiesArray);

  const blocks = [];
  const entityMap = {};
  let currentBlock = { text: "", type: "unstyled", depth: 0, entityRanges: [] };
  let entityCounter = 0;
  let currentTextLength = 0; // Track the current text length for entity ranges

  entitiesArray.forEach((entity, index) => {
    // Check if the text contains a newline
    if (entity.text.includes("\n")) {
      const parts = entity.text.split("\n");

      // Handle parts before and after \n
      parts.forEach((part, partIndex) => {
        if (partIndex > 0) {
          // Push the current block and reset
          if (currentBlock.text) {
            blocks.push(currentBlock);
          }
          currentBlock = {
            text: "",
            type: "unstyled",
            depth: 0,
            entityRanges: [],
          };
          currentTextLength = 0; // Reset text length
        }

        // Add the text part
        currentBlock.text += part;

        // If the current entity is not of type NORMAL, add entity range
        if (entity.type !== "NORMAL") {
          const entityKey = entityCounter++;
          entityMap[entityKey] = {
            type: entity.type,
            mutability: "SEGMENTED",
            data: {},
          };

          // Populate entity data based on type
          if (entity.type === "subtitle") {
            entityMap[entityKey].data.text = entity.subtitleText;
          } else if (entity.type === "video") {
            entityMap[entityKey].data.text = entity.promptText;
            entityMap[entityKey].data.video = entity.stockVideoData;
          } else if (entity.type === "slide") {
            entityMap[entityKey].data.text = entity.slideNumber;
          }

          // Add entity range for this entity in the current block
          currentBlock.entityRanges.push({
            offset: currentTextLength,
            length: part.length,
            key: entityKey,
          });
        }

        // Update the current text length
        currentTextLength += part.length;
      });
    } else {
      // If there's no newline, add the text and entity range to the same block
      currentBlock.text += entity.text;

      // Add entity range if it's not a NORMAL type
      if (entity.type !== "NORMAL") {
        const entityKey = entityCounter++;
        entityMap[entityKey] = {
          type: entity.type,
          mutability: "SEGMENTED",
          data: {},
        };

        if (entity.type === "subtitle") {
          entityMap[entityKey].data.text = entity.subtitleText;
        } else if (entity.type === "video") {
          entityMap[entityKey].data.text = entity.promptText;
          entityMap[entityKey].data.video = entity.stockVideoData;
        } else if (entity.type === "slide") {
          entityMap[entityKey].data.text = entity.slideNumber;
        }

        // Add entity range for this entity in the current block
        currentBlock.entityRanges.push({
          offset: currentTextLength,
          length: entity.text.length,
          key: entityKey,
        });
      }

      // Update the current text length
      currentTextLength += entity.text.length;
    }
  });

  // Push the last block if it has text
  if (currentBlock.text) {
    blocks.push(currentBlock);
  }

  // Return the final block structure and entity map
  return {
    blocks,
    entityMap: entityMap,
  };
};

export const EditorStyles = {
  immutable: {
    backgroundColor: "rgba(0, 0, 0, 0.2)",
    padding: "2px 0",
  },
  mutable: {
    backgroundColor: "rgba(204, 204, 255, 1.0)",
    padding: "2px 0",
  },
  segmented: {
    backgroundColor: "rgba(248, 222, 126, 1.0)",
    padding: "2px 0",
  },
};

export function getDecoratedStyle(mutability) {
  switch (mutability.toLowerCase()) {
    case "subtitle":
      return EditorStyles.immutable;
    case "video":
      return EditorStyles.mutable;
    case "slide":
      return EditorStyles.segmented;
    default:
      return null;
  }
}
export const TokenSpan = (props) => {
  const style = getDecoratedStyle(
    props.contentState.getEntity(props.entityKey).getType()
  );
  return (
    <span data-offset-key={props.offsetkey} style={style}>
      {props.children}
    </span>
  );
};

export const courseStageTypes = {
  CREATED: "CREATED",
  CONTENT_BRIEFING_GENERATED: "CONTENT_BRIEFING_GENERATED",
  COURSE_BRIEFING_CONFIRMED: "COURSE_BRIEFING_CONFIRMED",
  CONTENT_BRIEFING_FAILED: "CONTENT_BRIEFING_FAILED",
  LESSON_GENERATION_FAILED: "LESSON_GENERATION_FAILED",
  LESSON_GENERATION_SUCCESS: "LESSON_GENERATION_SUCCESS",
  LESSON_SCRIPT_COMPLETED: "LESSON_SCRIPT_COMPLETED",
  LESSON_SCRIPT_SUBMITTED: "LESSON_SCRIPT_SUBMITTED",
  LESSON_CREATION_INPROGRESS: "SCRIPT_GENERATION_IN_PROGRESS",
  AUDIO_GENERATION_INPROGRESS: "AUDIO_GENERATION_INPROGRESS",
  AUDIO_GENERATION_COMPLETED: "AUDIO_GENERATION_COMPLETED",
  VIDEO_GENERATION_STARTED: "VIDEO_GENERATION_STARTED",
  VIDEO_GENERATION_COMPLETED: "VIDEO_GENERATION_COMPLETED",
};

export const documentStages = {
  CREATED: "CREATED",
  TEXT_EXTRACTION_COMPLETED: "TEXT_EXTRACTION_COMPLETED",
  CONTEXT_BUILD_COMPLETED: "CONTEXT_BUILD_COMPLETED",
};
