import { Col, Flex, Row, Input, Button, Popconfirm } from "antd";
import React, { useState, useEffect, useCallback } from "react";
import {
  generateProjectLesson,
  generateProjectStep,
} from "../../../api/projectServices";
import CourseOutline from "../../modals/courseOutline";
import "./projectCourse.css";

const ProjectCourse = ({
  courseId,
  setCourse,
  course,
  setContentStep,
  fetchCourseDetail,
}) => {
  const { TextArea } = Input;
  const [courseSteps, setCourseSteps] = useState({});
  const [generatingLesson, setGeneratingLesson] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedStep, setSelectedStep] = useState(null);

  useEffect(() => {
    if (course?.courseStepResults) {
      setCourseSteps(course.courseStepResults);
    }

    if (generatingLesson && course?.courseStepResults?.[generatingLesson]) {
      setGeneratingLesson(null); // Stop loading when the new step data is available
    }
  }, [course, generatingLesson]);

  // Update the value of course steps dynamically
  const updateValue = (val, step) => {
    setCourseSteps((prev) => ({
      ...prev,
      [step]: val,
    }));
  };

  // Common function to generate the next step or lessons
  const generateStep = useCallback(
    async (step, clearedSteps) => {
      setGeneratingLesson(step);

      const { OUTLINE, LESSON } = courseSteps || {};
      const isOutlineFilled = OUTLINE?.trim() !== "";
      const isLessonFilled = LESSON?.trim() !== "";

      if (
        step === "OUTLINE" ||
        (step === "LESSON" && isOutlineFilled) ||
        (step === "LESSON_BRIEFINGS" && isOutlineFilled && isLessonFilled)
      ) {
        setIsModalOpen(false);

        try {
          await generateProjectStep(courseId, {
            courseStepResults: clearedSteps ? clearedSteps : courseSteps,
            step,
          });
        } catch (err) {
          console.error(err);
        } finally {
        }
      }
    },
    [courseSteps, courseId]
  );

  // Generate all lessons
  const generateLesson = useCallback(async () => {
    const { OUTLINE, LESSON, LESSON_BRIEFINGS } = courseSteps || {};

    if (OUTLINE?.trim() && LESSON?.trim() && LESSON_BRIEFINGS?.trim()) {
      setIsModalOpen(false);
      setGeneratingLesson("ALL");

      try {
        await generateProjectLesson(courseId, {
          courseStepResults: courseSteps,
        });
      } catch (err) {
        console.error("Error generating lessons:", err);
      } finally {
      }
    } else {
      console.error(
        "All fields (OUTLINE, LESSON, LESSON_BRIEFINGS) must be filled"
      );
    }
  }, [courseSteps, courseId]);

  const renderContent = () => {
    const { OUTLINE, LESSON, LESSON_BRIEFINGS } =
      course?.courseStepResults || {};

    if (!OUTLINE && !LESSON && !LESSON_BRIEFINGS) {
      return (
        <Flex gap={0} vertical align="center">
          <h3>Loading Course Outline...</h3>
          <p>Please wait while we prepare your course outline.</p>
          <div
            className="video-loader"
            style={{ width: "100%", marginTop: "20px" }}
          >
            <div className="loader"></div>
          </div>
        </Flex>
      );
    }

    const contentBlocks = [
      { title: "Course Outline", content: OUTLINE, step: "OUTLINE" },
      { title: "Course Lessons", content: LESSON, step: "LESSON" },
      {
        title: "Lesson Briefings",
        content: LESSON_BRIEFINGS,
        step: "LESSON_BRIEFINGS",
      },
    ];

    return (
      <Flex
        flex={1}
        gap={20}
        className="course-content"
        vertical
        style={{ width: "100%" }}
      >
        {contentBlocks.map(
          ({ title, content, step }) =>
            (content || step === generatingLesson) && (
              <Flex
                key={step}
                style={{ width: "100%" }}
                className="course-outline"
                align="center"
                gap={20}
              >
                <Flex vertical flex={1}>
                  <span className="course-outline-title">{title}</span>
                  {content ? (
                    <p>
                      {content.slice(0, 200)}
                      {content.length > 200 ? "..." : ""}
                    </p>
                  ) : (
                    <p>
                      Generating <b>{title}...</b>
                    </p>
                  )}
                </Flex>
                <Button
                  type="primary"
                  onClick={() => {
                    setSelectedStep(step);
                    setIsModalOpen(true);
                  }}
                  loading={!content}
                >
                  {content ? "View" : "Generating"}
                </Button>
              </Flex>
            )
        )}
      </Flex>
    );
  };

  const renderButton = () => {
    const { OUTLINE, LESSON, LESSON_BRIEFINGS } = courseSteps || {};

    if (course?.courseStage !== "CREATED") {
      return isModalOpen ? null : (
        <Button onClick={() => setContentStep((prev) => prev + 1)}>Next</Button>
      );
    }

    if (!OUTLINE && !LESSON && !LESSON_BRIEFINGS) return null;

    if (OUTLINE && LESSON && LESSON_BRIEFINGS) {
      return (
        <Popconfirm
          overlayStyle={{ width: 400 }}
          title="Course Outline"
          description="After submitting the course Outline, you won't be able to change the details. Do you want to proceed?"
          onConfirm={() => generateLesson()}
        >
          <Button type="primary" loading={generatingLesson !== null}>
          Confirm & Generate Lessons
          </Button>
        </Popconfirm>
      );
    }

    return (
      <Button
        onClick={() =>
          OUTLINE && LESSON && LESSON_BRIEFINGS
            ? generateLesson()
            : OUTLINE && LESSON
            ? generateStep("LESSON_BRIEFINGS")
            : generateStep("LESSON")
        }
        loading={generatingLesson !== null}
        type="primary"
      >
        {OUTLINE && LESSON && LESSON_BRIEFINGS
          ? generatingLesson !== null
            ? "Generating Lesson..."
            : "Confirm & Generate Lessons"
          : generatingLesson !== null
          ? "Generating Next Step"
          : "Confirm & Generate Next Step"}
      </Button>
    );
  };


  return (
    <Row className="course-detail">
      <Col span={24} style={{ height: "100%" }}>
        <Flex vertical className="course-detail-container">
          <Flex className="course-detail-header" justify="space-between">
            <span className="course-header-title">Course Briefing</span>
          </Flex>
          <Flex
            flex={1}
            className="project-content"
            vertical
            align="center"
            justify="center"
            gap={20}
          >
            {isModalOpen && (
              <CourseOutline
                status={course?.courseStage}
                isModalOpen={isModalOpen}
                handleCancel={() => setIsModalOpen(false)}
                courseData={courseSteps[selectedStep]}
                onChange={updateValue}
                selectedStep={selectedStep}
                courseStepResults={course?.courseStepResults}
                courseSteps={courseSteps}
                generatingLesson={generatingLesson}
                courseBriefing={course?.courseBriefing}
                setCourseSteps={setCourseSteps}
                handleOk={
                  selectedStep === "LESSON_BRIEFINGS"
                    ? generateLesson
                    : generateStep
                }
                setCourse={setCourse}
              />
            )}
            {renderContent()}
          </Flex>
          <Flex className="course-detail-footer" justify="flex-end">
            {renderButton()}
          </Flex>
        </Flex>
      </Col>
    </Row>
  );
};

export default ProjectCourse;
