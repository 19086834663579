import { Avatar, Button, Flex, Form, Input, Modal, Radio, Select } from "antd";
import React, { useEffect, useState } from "react";
import "./modal.css";
import { createVideoUseCase } from "../../api/adminService";
import NotificationInstance from "../../services/notificationServices";
import { videoTypes } from "../../utils/format";
import { getContacts } from "../../api/hubSpotService";
import { uploadProjectTextInput } from "../../api/projectServices";

const customOption = (option) => {
  return (
    <Flex gap={10} align="center">
      <Avatar
        shape={"circle"}
        src={`https://ui-avatars.com/api/?name=${option.data.label}&background=101A24&color=fff`}
        size={30}
      />
      <Flex vertical>
        <b>{option.data.label}</b>
        <span role="img" aria-label={option.data.label}>
          {option.data.email}
        </span>
      </Flex>
    </Flex>
  );
};

const UploadTextDocument = ({
  isModalOpen,
  handleOk,
  handleCancel,
  text,
  projectId,
}) => {

  const [disable, setDisable] = useState(false);
  const [form] = Form.useForm();

  const onSubmit = () => {
    form
      .validateFields()
      .then((val) => {
        setDisable(true);
        uploadProjectTextInput(projectId, val)
          .then((res) => {
            setDisable(false);
            form.resetFields();
            handleCancel();
            const finalData = { ...res?.data, id: res?.data?._id };
            handleOk([finalData]);
            NotificationInstance.success({
              message: "Successful",
              description: "Successfully uploaded new content",
            });
          })
          .catch((err) => {
            console.log(err);
            setDisable(false);
            NotificationInstance.error({
              message: "Text upload failed",
              description: "Please try again.",
            });
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (text) {
      form.setFieldsValue({
        text: text,
      });
    }
  }, [text]);

  return (
    <Modal
      centered
      width={800}
      title="Create New Video"
      open={isModalOpen}
      onCancel={handleCancel}
      footer={() => (
        <div className="action-buttons">
          <Button
            type="primary"
            onClick={onSubmit}
            disabled={disable}
            loading={disable}
          >
            Add Document
          </Button>
          <Button className="cancel-button" onClick={handleCancel}>
            Cancel
          </Button>
        </div>
      )}
    >
      <div className="title-input-wrapper">
        <Form
          form={form}
          onFinish={onSubmit}
          layout="vertical"
          className="add-user"
        >
          <Form.Item
            name="title"
            label="Title"
            required={false}
            rules={[
              {
                required: true,
                message: "Please enter title",
                validateTrigger: onsubmit,
              },
            ]}
          >
            <Input placeholder="Enter the title" />
          </Form.Item>
          <Form.Item
            name="text"
            label="Document Content"
            required={false}
            rules={[
              {
                required: true,
                message: "Please Enter Document Content",
                validateTrigger: onsubmit,
              },
            ]}
          >
            <Input.TextArea autoSize={{ minRows: 15, maxRows: 15 }} />
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default UploadTextDocument;
