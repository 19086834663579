export const diy = {
  en: {
    title:
      "Transform your most important training topic into a lively, motivating training lesson!",
    description:
      "We will now ask you a few questions to get the most important information for your lesson. Afterwards, we will write the training for you, you tell us where we should send it, and 10 minutes later you'll have it in your inbox.<br/><br/>Press the start button now and let yourself be surprised....",
    invalid: "Invalid Link!",
    button: {
      start: "Start now",
      next: "Next",
      previous: "Previous",
      submit: "Submit",
    },
    videoTitle: {
      title: "Title",
      label: "Give a title to your video",
      placeholder: "enter your title here",
      error: "Please enter title",
    },
    videoQuestion: {
      title: "Questions",
      placeholder: "enter your answer here",
      error: "Please enter the answer",
    },
    videoSubmit: {
      description1:
        "Great, thank you very much - now we have everything we need.",
      label:
        "In the meantime, please tell us where we should send your lesson:",
      description2:
        "In the background, we've already started writing your lesson and will send it via email shortly...",
      description3:
        "Your lesson should be in your inbox in 10 minutes at the latest. If you can't find it then, please check your SPAM folder. If it's not there either, please send us an email at <a href='mailto:support@leaplearning.de'>Support@LeapLearning.de</a> and we'll take care of it!",
      placeholder: "Name",
      placeholderEmail: "E-Mail Address",
      error: "Please enter your name",
      errorEmail: "Please enter your email",
    },
    videoResult: {
      title: "Result",
      label: "Here is your video",
      // description:
      //   "Great, we have a surprise for you, we will be creating a video with the first part of the script.",
      // initial: "Preparing...",
      // process: "Generating video... Usually it takes about 5 minutes",
      // final: "Getting ready...",
      description1: "We are writing your lesson in the background!",
      description2:
        "It should be in your inbox in 10 minutes at the latest. If you can't find it then, please check your SPAM folder. If it's not there either, please send us an email at <a href='mailto:support@leaplearning.de'>Support@LeapLearning.de</a> and we'll take care of it!",
      description3:
        "And I have another cool surprise for you, but more about that in my next email :-)",
    },
    videoPreview: {
      title: "Here comes your surprise!",
      detail: [
        "Would you like to see the first part of the lesson 'live' ?",
        "Then click on the start button!",
      ],
      description: [
        "So - did you notice something? That wasn't me, but my digital twin. I never actually said these sentences :-)",

        "Using AI to present your trainings is extremely effective because your digital avatar takes over the trainings while you can continue with your actual job.",

        "Not every leader or expert wants to or can give good trainings, but their digital twin will be able to do it excellently.",

        "Of course, we'd have to create it first, but the strategy of your trainings, writing the scripts, producing the videos for an inspiring training - you could have someone else do all of that.",

        "Us, for example. We could take care of everything for you. From the first idea to the finished complete course - you'll save so much time, energy, and resources, you might not even be able to imagine it yet.",

        "Shall we find out if this solution could help you produce trainings in record time that inspire and create real change for your onboarding, for example?",

        "Then let's talk.",

        "We would produce your first lesson completely finished with everything that goes with it - as a trial and free of charge.",

        "Okay? Then click on the button here and book an appointment for an initial conversation with the 'real' Tom :-)",

        "See you then - Your Tom.",
      ],
      button: "Book initial consultation now...",
      link: "https://calendly.com/tomfreudenthal/strategiecall-mit-tom",
      linkPreview: "calendly.com/tomfreudenthal/strategiecall-mit-tom",
    },
  },
  de: {
    title:
      "Verwandel Dein wichtigstes Schulungsthema in eine lebendige, motivierende Trainings-Lektion!",
    description:
      "Wir stellen dir jetzt einige Fragen, um die wichtigsten Informationen fur Deine Lektion zu bekommen. Danach schreiben wir Dir das Training, Du sagst uns, wo wir das hinschicken sollen und 10 Minuten später hast Du es in Deiner Inbox.<br/><br/>Drücke jetzt auf den Startknopf und lass Dich überraschen.... ",
    invalid: "Ungültiger Link!",
    button: {
      start: "Jetzt starten",
      next: "Weiter",
      previous: "Zurück",
      submit: "Abschicken",
    },
    videoTitle: {
      title: "Name Lektion",
      label: "Gib hier Deiner Trainings-Lektion einen Namen",
      placeholder: "Trage hier den Titel der Lektion ein",
      error: "Bitte geben Sie einen Titel ein",
    },
    videoQuestion: {
      title: "Fragen",
      placeholder: "Trage hier Deine Antwort ein..",
      error: "Bitte geben Sie die Antwort ein",
    },
    videoSubmit: {
      description1:
        "Super, vielen Dank - jetzt haben wir alles was wir brauchen.",
      label:
        "In der Zwischenzeit sage uns bitte, wo wir Deine Lektion hinschicken sollen:",
      description2:
        " Im Hintergrund haben wir schon angefangen, Deine Lektion zu schreiben und senden sie gleich per Email los... ",
      description3:
        "Deine Lektion müsste in spätestens 10 Minuten in Deiner Inbox sein, falls Du sie dann nicht finden kannst, check bitte den SPAM und wenn sie da auch nicht ist, schicke uns bitte eine E-Mail an <a href='mailto:support@leaplearning.de'>Support@LeapLearning.de</a> und wir kümmern uns!",
      placeholder: "Vorname",
      placeholderEmail: "E-Mail-Adresse",
      error: "Bitte geben Sie Ihren Vornamen ein",
      errorEmail: "Bitte geben Sie Ihre E-Mail-Adresse ein",
    },
    videoResult: {
      title: "Ergebnis",
      // label: "Hier ist Ihr Video",
      // description:
      //   "Toll, wir haben eine Überraschung für Sie: Wir werden ein Video mit dem ersten Teil des Skripts erstellen.",
      // initial: "Vorbereitung...",
      // process:
      //   "Video wird generiert... Dies dauert normalerweise etwa 5 Minuten",
      // final: "Wird fertiggestellt...",
      description1: "Wir schreiben im Hintergrund an Deiner Lektion!",
      description2:
        "Sie müsste in spätestens 10 Minuten in Deiner Inbox sein, falls Du sie dann nicht finden kannst, check bitte den SPAM und wenn sie da auch nicht ist, schicke uns bitte eine E-Mail an <a href='mailto:support@leaplearning.de'>Support@LeapLearning.de</a> und wir kümmern uns!",
      description3:
        " Und dann habe ich noch eine coole Überraschung für Dich, aber dazu mehr in meiner nächsten E-Mail :-)",
    },
    videoPreview: {
      title: "Hier kommt Deine Überraschung!",
      detail: [
        "Würdest Du gerne den ersten Teil der Lektion 'live' sehen?",
        "Dann klicke auf den Startknopf!",
      ],
      description: [
        "So - ist Dir etwas aufgefallen? Das war nicht ich, sondern mein  digitaler Zwilling. Ich selbst habe diese Sätze nie gesagt :-)",

        "KI zu nutzen, um Deine Schulungen zu präsentieren, ist extrem  effektiv, weil Dein digitaler Avatar die Trainings übernimmt,  während Du weiter Deinem eigentlichen Job nachgehen kannst.",

        "Nicht jede Führungskraft oder Fachexperte will oder kann gute  Trainings geben, aber sein digitaler Zwilling wird das hervorragend  können.",

        "Den müssten wir natürlich erstmal erstellen, aber die Strategie  Deiner Schulungen, das Schreiben der Manuskripte, die Produktion  der Videos für ein begeisterndes Training - das könntest Du alles  jemand anders machen lassen.",

        "Uns zum Beispiel. Wir könnten das komplett für Euch  übernehmen. Von der ersten Idee bis zum fertigen Gesamt-Kurs -  Du wirst so viel Zeit, Energie und Ressourcen sparen, das kannst  Du vielleicht noch gar nicht vorstellen.",

        "Wollen wir herausfinden, ob diese Lösung Euch helfen könnte, in  Rekordzeit Schulungen zu produzieren, die begeistern und echte  Veränderung bewirken für Euer Onboarding zum Beispiel?",

        "Dann lass uns sprechen.",

        "Wir würden Dir Deine erste Lektion komplett fertig  produzieren mit allem was dazu gehört - zur Probe und gratis.",

        'Ok? Dann klick auf den Knopf hier und buche Dir einen Termin für  ein Erstgespräch mit dem "echten" Tom :-)',

        "Bis dann - Dein Tom.",
      ],
      button: "Jetzt Erstgespräch buchen...",
      link: "https://calendly.com/tomfreudenthal/strategiecall-mit-tom",
      linkPreview: "calendly.com/tomfreudenthal/strategiecall-mit-tom",
    },
  },
};
