import React, { useState, useEffect } from "react";
import { Flex } from "antd";
import { endAt, limitToLast, onChildAdded, ref } from "firebase/database";
import { dataBase } from "../../firebase/firebase";
import { query } from "firebase/firestore";

const DIYResult = ({ videoId, lang, diy }) => {
  const [status, setStatus] = useState(null);
  const [videoUrl, setVideoUrl] = useState(null);

  useEffect(() => {
    let unsubscribe;
    if (videoId) {
      const channelRef = query(
        ref(dataBase, `leads/videos/${videoId}`),
        endAt,
        limitToLast(1)
      );
      unsubscribe = onChildAdded(channelRef, (snapshot) => {
        const message = snapshot.val();
        if (message?.status === "completed") {
          setTimeout(() => {
            setVideoUrl(message?.url);
            setStatus("completed");
          }, 5000);
        } else {
          setStatus(message.status);
        }
      });
    }
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [videoId]);

  return (
    <Flex vertical align="center" justify="center">
      <h2>{diy[lang].videoResult.description1}</h2>
      <p
        dangerouslySetInnerHTML={{ __html: diy[lang].videoResult.description2 }}
      />

      <h3>{diy[lang].videoResult.description3}</h3>
    </Flex>
  );
};

export default DIYResult;
