import { Button, Checkbox, Divider, Drawer, Empty, Input, Select } from "antd";
import React, { useEffect, useState } from "react";
import "./modal.css";
import { getCategories } from "../../api/adminService";
import { courseStageTypes } from "../../utils/constant";
import { getStatusLabel } from "../../utils/format";

const VideosFilterDrawer = ({
  isDrawerOpen,
  handleCancel,
  setfilter,
  typeFilter,
  stateFilter,
  setVideosPage,
}) => {
  const [categories, setCategories] = useState([]);
  const [type, setType] = useState(typeFilter ?? "");
  const [state, setState] = useState(stateFilter ?? []);
  const [loading, setLoading] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  // const [category, setCategory] = useState(categoryFilter ?? []);
  const filterType = ["TRAINING", "PERSONALIZED", "DIY", "LESSON", "DEMO"];
  const [currentPage, setcurrentPage] = useState(1);
  const [total, setTotal] = useState();
  const [initial, setinitial] = useState(true);
  let debounceTimeout;
  const debouncedSearch = (input) => {
    clearTimeout(debounceTimeout);

    debounceTimeout = setTimeout(() => {
      setcurrentPage(1);
      setSearchKey(input);
    }, 500);
  };

  const onSubmit = () => {
    setfilter({
      type: type,
      state: state,
      // category: category,
      active: type || state,
    });
    setVideosPage(1);
    handleCancel();
  };
  const onSearch = (event) => {
    debouncedSearch(event.target.value);
  };
  // const onCategoryChange = (value) => {
  //   setCategory(value);
  // };
  const onModalClose = () => {
    handleCancel();
  };
  const onClearFilter = () => {
    setType("");
    setState([]);
    // setCategory([]);
    setSearchKey("");
    setcurrentPage(1);
    setfilter({ type: "", state: [], active: false });
    fetchCategories();
  };

  const fetchCategories = () => {
    getCategories(searchKey, "", "", currentPage)
      .then((res) => {
        const responseData = res?.data.map((item) => ({
          label: item.name,
          value: item._id,
        }));
        if (initial || ((searchKey || searchKey === "") && currentPage === 1)) {
          setCategories(responseData);
        } else {
          setCategories((prevCategories) => [
            ...prevCategories,
            ...responseData,
          ]);
        }
        setTotal(res?.info?.totalCount);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
    setinitial(false);
  };

  useEffect(() => {
    setLoading(true);
    fetchCategories();
  }, [currentPage, searchKey]);

  return (
    <Drawer
      width={450}
      title="Filter"
      open={isDrawerOpen}
      onClose={onModalClose}
      footer={
        <div className="action-buttons">
          <Button type="primary" onClick={onSubmit}>
            Apply Filter
          </Button>
          <Button className="cancel-button" onClick={onClearFilter}>
            Clear all
          </Button>
        </div>
      }
    >
      <div className="drawer-content-wrapper">
        <div className="type-filter">
          <h3>Type</h3>
          <div className="categories">
            <Checkbox.Group
              options={filterType.map((item) => ({
                label: item, // You can use courseStageLabels to make the labels user-friendly
                value: item,
              }))}
              onChange={(value) => setType(value)}
              value={type}
            />
          </div>
          <Divider />
        </div>
        <div className="state-filter">
          <h3>State</h3>
          <Select
            value={state}
            showSearch
            mode="multiple"
            placeholder="Select states"
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            options={Object.entries(courseStageTypes).map(([key, value]) => ({
              label: getStatusLabel(key), // You can use courseStageLabels to make the labels user-friendly
              value: value,
            }))}
            onChange={(value) => setState(value)}
          />
          {/* <div className="categories">
            <Checkbox.Group
              options={Object.entries(courseStageTypes).map(([key, value]) => ({
                label: getStatusLabel(key), // You can use courseStageLabels to make the labels user-friendly
                value: value,
              }))}
              onChange={(value) => setState(value)}
              value={state}
            />
          </div> */}
        </div>
      </div>
    </Drawer>
  );
};

export default VideosFilterDrawer;
