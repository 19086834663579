import React from "react";
import { ReactComponent as Delete } from "../../../assets/images/trash.svg";
import { ReactComponent as Edit } from "../../../assets/images/editPen.svg";
import { ReactComponent as UnPublish } from "../../../assets/images/unpublish.svg"
import "./categoriesTableActions.css";
import { Tooltip } from "antd";
const CategoriesTableActions = ({ handleEdit, handleDelete, handleStatus, categoryStatus }) => {
  return (
    <div className="table-action">
      <Tooltip title="Delete Category">
        <Delete onClick={handleDelete} />
      </Tooltip>
      <Tooltip title="Edit Category">
        <Edit onClick={handleEdit} />
      </Tooltip>
      <Tooltip title={categoryStatus === "PUBLISHED" ? "Unpublish Category" : "Publish Category"}>
        <UnPublish onClick={handleStatus} />
      </Tooltip>
    </div>
  );
};

export default CategoriesTableActions;
