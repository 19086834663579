import { Button, Input, Modal, Radio } from "antd";
import React, { useState } from "react";
import "./modal.css";
import { textFormat } from "../../utils/format";

const AddCategoryModal = ({
  isModalOpen,
  handleOk,
  handleCancel,
  category,
  setCategory,
  update = false,
  label,
  defaultStatus,
}) => {
  const [type, setType] = useState("DRAFT");
  const onSubmit = () => {
    if (category.value && !update) {
      handleOk(textFormat(category.value), type);
    } else if (update) {
      handleOk(defaultStatus);
    } else {
      setCategory({ ...category, error: true });
    }
  };
  const onCategoryChange = (event) => {
    if (event.target.value.trim() !== "") {
      setCategory({
        value: event.target.value,
        error: event.target.value ? false : true,
      });
    } else {
      setCategory({
        value: "",
        error: true,
      });
    }
  };
  const onModalClose = () => {
    setCategory({ value: update ? label : "", error: false });
    handleCancel();
    setType("DRAFT");
  };
  const onTypeChange = (event) => {
    setType(event.target.value);
  };
  return (
    <Modal
      centered
      width={450}
      title={update ? "Update Category" : "Create New Category"}
      open={isModalOpen}
      onCancel={onModalClose}
      footer={() => (
        <div className="action-buttons">
          <Button type="primary" onClick={onSubmit}>
            {update ? "Update Category" : "Create Category"}
          </Button>
          <Button className="cancel-button" onClick={onModalClose}>
            Cancel
          </Button>
        </div>
      )}
    >
      <div className="title-input-wrapper">
        <span className="input-label">Category Name</span>
        <Input
          value={category.value}
          placeholder="Enter Category Name"
          onChange={(e) => onCategoryChange(e)}
        />
        {category.error && (
          <span className="error-message">Please enter a category name</span>
        )}
        {!update && (
          <>
            <span className="input-label">Status</span>
            <Radio.Group onChange={onTypeChange} value={type}>
              <div
                className={`journey-type ${type === "DRAFT" && "active"}`}
                onClick={() => setType("DRAFT")}
              >
                <Radio value="DRAFT">Draft</Radio>
              </div>
              <div
                className={`journey-type ${type === "PUBLISHED" && "active"}`}
                onClick={() => setType("PUBLISHED")}
              >
                <Radio value="PUBLISHED">Publish</Radio>
              </div>
            </Radio.Group>
          </>
        )}
      </div>
    </Modal>
  );
};

export default AddCategoryModal;
