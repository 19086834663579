import "./journeyConfig.css";
import { useCallback, useEffect, useState } from "react";
import { ReactComponent as More } from "../../assets/images/more.svg";
import { ReactComponent as Delete } from "../../assets/images/delete.svg";
import { ReactComponent as Plus } from "../../assets/images/plus.svg";
import { ReactComponent as Image } from "../../assets/images/image.svg";
import {
  createChannel,
  deleteSelectedJourneys,
  getJourneyDetails,
  updateJourneyConfig,
  uploadImage,
} from "../../api/adminService";
import { Button, Dropdown, Flex, Form, Input, InputNumber } from "antd";
import AddImageModal from "../modals/addImageModal";
import NotificationInstance from "../../services/notificationServices";
import { useBlocker, useNavigate, useParams } from "react-router-dom";
import { timeSinceLastSaved, validateInput } from "../../utils/format";
import { CurrentStatusTag } from "../tags/tags";
import PublishModal from "../modals/publishModal";
import UnSavedModal from "../modals/unSavedChangesModal";
import UnpublishModal from "../modals/unPublishModal";
import DeleteConfirmModal from "../modals/deleteConfirmModal";
import UploadVideoModal from "../modals/uploadVideoUrl";
import UploadFileModal from "../modals/uploadFile";
import Breadcrumbs from "../breadcrumbs/breadcrumbs";

const items = [
  {
    label: "Edit title",
    key: "1",
  },
  {
    label: "Delete",
    key: "2",
  },
];

const JourneyConfig = ({ setDisplayName, setchannel, displayName }) => {
  const fixedSection = {
    section: "",
    milestones: [
      {
        title: "",
        videoIds: [],
        fileIds: [],
        videoURLs: [],
        tasks: [
          {
            task: "",
            estimate: 0,
            ordinal: 0,
            videoIds: [],
            fileIds: [],
            videoURLs: [],
          },
        ],
      },
    ],
  };
  const preDefinedSection = {
    section: "",
    milestones: [{ title: "", videoIds: [], fileIds: [], videoURLs: [] }],
    additionalPrompts: [""],
  };
  const [titleDisabled, setTitleDisabled] = useState(true);
  const [imageUrl, setImageUrl] = useState({
    url: null,
    error: false,
    type: "image",
  });
  const [status, setstatus] = useState("DRAFT");
  const [title, setTitle] = useState({ value: "Journey Name", error: false });
  const [questions, setQuestions] = useState([""]);
  const [sections, setSections] = useState([preDefinedSection]);
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPublishModalOpen, setIsPublishModalOpen] = useState(false);
  const [isUnpublishModalOpen, setIsUnpublishModalOpen] = useState(false);
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
  const [lastSavedTime, setLastSavedTime] = useState(null);
  const [journey, setjourney] = useState([]);
  const [request, setrequest] = useState();
  const [selectedCategory, setSelectedCategory] = useState();
  const [type, setType] = useState("Pre_Defined");
  const [loading, setloading] = useState(false);
  const [isFormTouched, setIsFormTouched] = useState(false);
  const [update, setupdate] = useState(false);
  const [isJourneyDeleteModalOpen, setIsJourneyDeleteModalOpen] =
    useState(false);
  const [isImageUploaded, setisImageUploaded] = useState(false);
  const [videoModalOpen, setVideoModalOpen] = useState(false);
  const [VideoIndex, setVideoIndex] = useState({
    sections: "",
    milestone: "",
    file: "",
  });
  const [VideoDetail, setVideoDetail] = useState({
    uploaded: false,
    index: "",
    task: false,
  });
  const [fileModalOpen, setfileModalOpen] = useState(false);
  const [fileIndex, setfileIndex] = useState({
    sections: "",
    milestone: "",
    file: "",
  });
  const [fileDetail, setfileDetail] = useState({
    uploaded: false,
    index: "",
    task: false,
  });
  const params = useParams();
  const navigate = useNavigate();
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = (url, type) => {
    setImageUrl({
      url: url,
      error: url ? false : true,
      type: type,
    });
    setisImageUploaded(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const onEnable = () => {
    setTitleDisabled(false);
    document?.getElementById("journey-title")?.focus();
  };
  const addNewItem = (item, event, sectionindex, milestoneIndex, taskIndex) => {
    event.preventDefault();
    switch (item) {
      case "question":
        setQuestions([...questions, ""]);
        break;
      case "section":
        const typeSection = type === "Fixed" ? fixedSection : preDefinedSection;
        setSections([...sections, typeSection]);
        break;
      case "milestone":
        const newSection = [...sections];
        if (type === "Pre_Defined") {
          newSection[sectionindex].milestones.push({
            title: "",
            videoIds: [],
            fileIds: [],
            videoURLs: [],
          });
        } else {
          newSection[sectionindex].milestones.push({
            title: "",
            videoIds: [],
            fileIds: [],
            videoURLs: [],
            tasks: [
              {
                task: "",
                estimate: "",
                ordinal: "",
                videoIds: [],
                fileIds: [],
                videoURLs: [],
              },
            ],
          });
        }
        setSections(newSection);
        break;
      case "task":
        const newSectionTask = [...sections];
        newSectionTask[sectionindex].milestones[milestoneIndex].tasks.push({
          task: "",
          estimate: 0,
          ordinal: 0,
          videoIds: [],
          fileIds: [],
          videoURLs: [],
        });
        setSections(newSectionTask);
        break;
      case "prompt":
        const newPrompt = [...sections];
        newPrompt[sectionindex].additionalPrompts.push("");
        setSections(newPrompt);
        break;
      case "video":
        const newMilestones = [...sections];
        const milestone =
          newMilestones[sectionindex]?.milestones[milestoneIndex];

        if (milestone && !milestone.videoURLs) {
          newMilestones[sectionindex].milestones[milestoneIndex].videoURLs = [
            "",
          ];
        } else if (milestone) {
          newMilestones[sectionindex].milestones[milestoneIndex].videoURLs.push(
            ""
          );
        }

        setSections(newMilestones);
        break;
      case "taskVideo":
        const newTasks = [...sections];
        newTasks[sectionindex].milestones[milestoneIndex].tasks[
          taskIndex
        ].videoURLs.push("");
        setSections(newTasks);
        break;
      default:
        break;
    }
  };
  const removeInput = (
    itemIndex,
    type,
    sectionindex,
    milestoneIndex,
    taskIndex
  ) => {
    switch (type) {
      case "question":
        const newQuestions = questions.filter(
          (_, index) => index !== itemIndex
        );
        setQuestions(newQuestions);
        break;
      case "section":
        const newSections = sections.filter((_, index) => index !== itemIndex);
        setSections(newSections);
        break;
      case "milestone":
        const newMilestones = [...sections];
        newMilestones[sectionindex] = {
          ...newMilestones[sectionindex],
          milestones: newMilestones[sectionindex].milestones.filter(
            (_, index) => index !== itemIndex
          ),
        };
        setSections(newMilestones);
        break;
      case "task":
        const newTask = [...sections];
        const sectionToUpdate = newTask[sectionindex];
        const milestoneToUpdate = sectionToUpdate.milestones[milestoneIndex];
        const updatedTasks = milestoneToUpdate.tasks.filter(
          (_, index) => index !== itemIndex
        );
        milestoneToUpdate.tasks = updatedTasks;

        // Update the state with the modified sections
        setSections(newTask);
        break;

      case "prompt":
        const newPrompts = [...sections];
        newPrompts[sectionindex] = {
          ...newPrompts[sectionindex],
          additionalPrompts: newPrompts[sectionindex].additionalPrompts.filter(
            (_, index) => index !== itemIndex
          ),
        };
        setSections(newPrompts);
        break;
      case "video":
        setSections((prevSections) =>
          prevSections.map((section, secIndex) => {
            if (secIndex !== sectionindex) return section;

            return {
              ...section,
              milestones: section.milestones.map((milestone, milIndex) => {
                if (milIndex !== milestoneIndex) return milestone;

                return {
                  ...milestone,
                  videoURLs: milestone.videoURLs.filter(
                    (_, index) => index !== itemIndex
                  ),
                };
              }),
            };
          })
        );
        break;
      case "taskVideo":
        setSections((prevSections) =>
          prevSections.map((section, secIndex) => {
            if (secIndex !== sectionindex) return section;

            return {
              ...section,
              milestones: section.milestones.map((milestone, milIndex) => {
                if (milIndex !== milestoneIndex) return milestone;

                return {
                  ...milestone,
                  tasks: milestone.tasks.map((task, taskIdx) => {
                    if (taskIdx !== taskIndex) return task;

                    return {
                      ...task,
                      videoURLs: task.videoURLs.filter(
                        (_, index) => index !== itemIndex
                      ),
                    };
                  }),
                };
              }),
            };
          })
        );
        break;

      default:
        break;
    }
  };
  const onTitleChange = (event) => {
    setTitle({
      error: event.target.value ? false : true,
      value: event.target.value,
    });
  };
  const onSectionTitleChange = (event, index) => {
    const updatedsections = JSON.parse(JSON.stringify(sections));
    updatedsections[index].section = event.target.value;
    setSections(updatedsections);
  };
  const onQuestionChange = (event, index) => {
    const updatedQuestions = JSON.parse(JSON.stringify(questions));
    updatedQuestions[index] = event.target.value;
    setQuestions(updatedQuestions);
  };
  const onMilestoneChange = (event, sectionIndex, milestoneIndex) => {
    let updatedSections = JSON.parse(JSON.stringify(sections));
    updatedSections[sectionIndex].milestones[milestoneIndex].title =
      event.target.value;
    setSections(updatedSections);
  };
  const onPromptChange = (event, sectionIndex, promptIndex) => {
    const updatedSections = JSON.parse(JSON.stringify(sections));
    updatedSections[sectionIndex].additionalPrompts[promptIndex] =
      event.target.value;
    setSections(updatedSections);
  };
  const onTaskNameChange = (event, sectionIndex, milestoneIndex, taskIndex) => {
    const updatedSections = JSON.parse(JSON.stringify(sections));
    updatedSections[sectionIndex].milestones[milestoneIndex].tasks[
      taskIndex
    ].task = event.target.value;
    setSections(updatedSections);
  };
  const onEstimateChange = (value, sectionIndex, milestoneIndex, taskIndex) => {
    const updatedSections = JSON.parse(JSON.stringify(sections));
    updatedSections[sectionIndex].milestones[milestoneIndex].tasks[
      taskIndex
    ].estimate = value;
    setSections(updatedSections);
  };
  const onOrdinalChange = (value, sectionIndex, milestoneIndex, taskIndex) => {
    const updatedSections = JSON.parse(JSON.stringify(sections));
    updatedSections[sectionIndex].milestones[milestoneIndex].tasks[
      taskIndex
    ].ordinal = value;
    setSections(updatedSections);
  };
  const onVideoUrlChange = (
    type,
    videoId,
    sectionIndex,
    milestoneIndex,
    index
  ) => {
    const updatedSections = JSON.parse(JSON.stringify(sections));
    const milestone = updatedSections[sectionIndex].milestones[milestoneIndex];
    if (type === "Upload") {
      milestone.videoIds = Array.from(
        new Set([...milestone?.videoIds, videoId])
      );
    } else {
      milestone.videoIds = Array.from(
        new Set([...milestone?.videoIds, ...videoId])
      );
    }

    setSections(updatedSections);
    setIsFormTouched(true);
  };

  const onFileChange = (fileId, sectionIndex, milestoneIndex, index) => {
    const updatedSections = JSON.parse(JSON.stringify(sections));
    updatedSections[sectionIndex].milestones[milestoneIndex].fileIds.push(
      fileId
    );

    setSections(updatedSections);
    setIsFormTouched(true);
  };
  const onTaskVideoUrlChange = (
    type,
    videoId,
    sectionIndex,
    milestoneIndex,
    taskIndex
  ) => {
    const updatedSections = JSON.parse(JSON.stringify(sections));
    const task =
      updatedSections[sectionIndex].milestones[milestoneIndex].tasks[taskIndex];

    if (type === "Upload") {
      task.videoIds = Array.from(new Set([...task?.videoIds, videoId]));
    } else {
      task.videoIds = Array.from(new Set([...task?.videoIds, ...videoId]));
    }

    setSections(updatedSections);
    setIsFormTouched(true);
  };
  const onTaskFileChange = (
    fileId,
    sectionIndex,
    milestoneIndex,
    taskIndex
  ) => {
    const updatedSections = JSON.parse(JSON.stringify(sections));
    updatedSections[sectionIndex].milestones[milestoneIndex].tasks[
      taskIndex
    ].fileIds.push(fileId);

    setSections(updatedSections);
    setIsFormTouched(true);
  };
  const onMilestoneVideoChange = (
    event,
    sectionIndex,
    milestoneIndex,
    videoIndex
  ) => {
    let updatedSections = JSON.parse(JSON.stringify(sections));
    updatedSections[sectionIndex].milestones[milestoneIndex].videoURLs[
      videoIndex
    ] = event.target.value;
    setSections(updatedSections);
  };
  const onTaskVideoChange = (
    event,
    sectionIndex,
    milestoneIndex,
    taskIndex,
    videoIndex
  ) => {
    let updatedSections = JSON.parse(JSON.stringify(sections));
    updatedSections[sectionIndex].milestones[milestoneIndex].tasks[
      taskIndex
    ].videoURLs[videoIndex] = event.target.value;
    setSections(updatedSections);
  };
  const handleVideoDelete = (
    videoId,
    sectionIndex,
    milestoneIndex,
    index,
    task,
    taskIndex
  ) => {
    const updatedSections = JSON.parse(JSON.stringify(sections));
    if (task) {
      const videoIds =
        updatedSections[sectionIndex].milestones[milestoneIndex].tasks[
          taskIndex
        ].videoIds;
      if (videoIds[index] === videoId) {
        videoIds.splice(index, 1);
      } else {
        console.error("Video ID does not match the ID at the specified index");
      }
    } else {
      const videoIds =
        updatedSections[sectionIndex].milestones[milestoneIndex].videoIds;
      if (videoIds[index] === videoId) {
        videoIds.splice(index, 1);
      } else {
        console.error("Video ID does not match the ID at the specified index");
      }
    }
    setSections(updatedSections);
    setIsFormTouched(true);
    setVideoModalOpen(false);
  };
  const handlefileDelete = (
    fileId,
    sectionIndex,
    milestoneIndex,
    index,
    task,
    taskIndex
  ) => {
    const updatedSections = JSON.parse(JSON.stringify(sections));
    if (task) {
      const fileIds =
        updatedSections[sectionIndex].milestones[milestoneIndex].tasks[
          taskIndex
        ].fileIds;
      if (fileIds[index] === fileId) {
        fileIds.splice(index, 1);
      } else {
        console.error("file ID does not match the ID at the specified index");
      }
    } else {
      const fileIds =
        updatedSections[sectionIndex].milestones[milestoneIndex].fileIds;
      if (fileIds[index] === fileId) {
        fileIds.splice(index, 1);
      } else {
        console.error("file ID does not match the ID at the specified index");
      }
    }
    setSections(updatedSections);
    setIsFormTouched(true);
    setfileModalOpen(false);
  };

  useEffect(() => {
    setloading(true);
    if (params.journeyId) {
      getJourneyDetails(params.journeyId)
        .then((res) => {
          setloading(false);
          if (res.data.length > 0) {
            const journeyData = JSON.parse(JSON.stringify(res?.data[0]));
            setjourney({
              ...journeyData,
              image: res?.data[0]?.image ? res?.data[0]?.image : null,
            });
            setTitle((prevTitle) => ({
              ...prevTitle,
              value: JSON.parse(JSON.stringify(res?.data[0]?.title)),
            }));
            setImageUrl((prevImageUrl) => ({
              ...prevImageUrl,
              url: res?.data[0]?.image
                ? JSON.parse(JSON.stringify(res?.data[0]?.image))
                : null,
            }));
            if (res?.data[0]?.questions?.length > 0) {
              setQuestions(res?.data[0]?.questions);
            } else {
              setQuestions([""]);
            }
            if (res?.data[0]?.plan?.length > 0) {
              const updatedSections = res?.data[0]?.plan?.map((section) => {
                if (!section.milestones || section.milestones.length === 0) {
                  section.milestones =
                    res?.data[0]?.type === "Fixed"
                      ? [
                          {
                            title: "",
                            videoIds: [],
                            fileIds: [],
                            videoURLs: [],
                            tasks: [
                              {
                                task: "",
                                estimate: 0,
                                ordinal: 0,
                                videoIds: [],
                                fileIds: [],
                                videoURLs: [],
                              },
                            ],
                          },
                        ]
                      : [
                          {
                            title: "",
                            videoIds: [],
                            fileIds: [],
                            videoURLs: [],
                          },
                        ];
                }
                if (
                  !section.additionalPrompts &&
                  res?.data[0]?.type === "Pre_Defined"
                ) {
                  section.additionalPrompts = [""];
                }
                return section;
              });
              setSections(updatedSections);
            } else {
              setSections(
                res?.data[0]?.type === "Pre_Defined"
                  ? [preDefinedSection]
                  : [fixedSection]
              );
            }
            setType(res?.data[0]?.type);
            setstatus(res?.data[0]?.status);
            form.setFieldsValue(res?.data[0]);
            updateFields(res?.data[0], res?.data[0]?.type);
            setSelectedCategory(res?.data[0]?.categoryInfo);
            setDisplayName([
              res?.data[0]?.categoryInfo?.name,
              res?.data[0]?.title,
            ]);
            if (res?.data[0]?.staffChannelId) {
              setchannel(res?.data[0]?.staffChannelId);
            } else {
              createJourneyChannel();
            }
          }
        })
        .catch((error) => {
          console.error(error);
          setloading(false);
        });
    }
  }, [params?.journeyId]);
  useEffect(() => {
    const intervalId = setInterval(() => {
      setLastSavedTime((prevTime) => new Date(prevTime?.getTime() - 60000));
    }, 60000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const setErrorFields = () => {
    const fieldsWithErrorsRaw = form.getFieldsError();
    const fieldsWithErrors = fieldsWithErrorsRaw.map((field) => ({
      name: field.name,
      errors: [],
      value: form.getFieldValue(field.name),
      warnings: field.warnings,
    }));
    form.setFields(fieldsWithErrors);
    setImageUrl({ ...imageUrl, error: false });
  };

  const onSubmit = (status) => {
    if (!title.value || title.value.trim() === "") {
      setTitle({ ...title, error: true });
      NotificationInstance.warning({
        message: "Add a journey title",
      });
    } else if (!imageUrl.url && status !== "DRAFT") {
      setImageUrl({ ...imageUrl, error: true });
      NotificationInstance.warning({
        message: "Add a thumbnail image",
      });
    } else {
      setTitle({ ...title, error: false });
      if (status === "DRAFT") {
        const values =
          type === "Fixed"
            ? {
                plan: sections,
              }
            : {
                questions: questions,
                plan: sections,
              };
        onFinish(values, status);
        setErrorFields();
      } else if (status === "UNPUBLISH") {
        const values = { ...journey, status: "DRAFT" };
        onFinish(values, status);
        setErrorFields();
        form.resetFields();
      } else {
        form
          .validateFields()
          .then((values) => {
            onFinish(values, "PUBLISHED");
          })
          .catch((err) => {
            console.log(err);
            NotificationInstance.warning({
              message: "Fill the required fields",
            });
            if (err?.errorFields?.length > 0) {
              const errorf = err.errorFields[0]?.name;
              console.log(errorf);
              form.scrollToField(errorf, {
                behavior: "smooth",
                block: "center",
                inline: "center",
              });
            }
          });
      }
    }
  };
  const filterFormData = (formData) => {
    const filteredFormData = { questions: [], plan: [] };
    // Filter questions
    filteredFormData.questions =
      formData?.questions?.filter((value) => value !== "") ?? [];

    filteredFormData.plan =
      formData?.plan
        ?.map((item) => {
          const newItem = {};
          if (
            (item?.section && item?.section !== "") ||
            (item.milestones &&
              item.milestones.length > 0 &&
              item.milestones.some((milestone) => milestone.title !== "")) ||
            (item.additionalPrompts &&
              item.additionalPrompts.some((prompt) => prompt !== ""))
          ) {
            if (item?.section && item?.section !== "") {
              newItem.section = item.section;
            }
            if (
              item.milestones &&
              item.milestones.length > 0 &&
              item.milestones.some((milestone) => milestone.title !== "")
            ) {
              newItem.milestones = item.milestones
                .filter((milestone) => milestone.title !== "")
                .map((milestone) => {
                  // Remove empty videoIds
                  const cleanedVideoIds = milestone?.videoIds?.filter(
                    (videoId) => videoId !== ""
                  );
                  const cleanedMilestone = { ...milestone };
                  if (cleanedVideoIds?.length > 0) {
                    cleanedMilestone.videoIds = cleanedVideoIds;
                  } else {
                    delete cleanedMilestone.videoIds;
                  }
                  return cleanedMilestone;
                });
            }
            if (
              item.additionalPrompts &&
              item.additionalPrompts.some((prompt) => prompt !== "")
            ) {
              newItem.additionalPrompts = item.additionalPrompts.filter(
                (prompt) => prompt !== ""
              );
            }
            return newItem;
          }
          return undefined;
        })
        .filter(Boolean) ?? [];
    return filteredFormData;
  };
  const onFinish = (formData, currentStatus) => {
    const newrequest = {
      title: title.value,
      type: type,
      status: currentStatus ? currentStatus : "PUBLISHED",
      categoryId: params.categoryId,
      ...filterFormData(formData),
    };
    if (imageUrl.url) {
      newrequest.image = imageUrl.url;
    }
    if (currentStatus === "DRAFT") {
      updateImage(newrequest, "DRAFT");
    } else if (currentStatus === "UNPUBLISH") {
      newrequest.status = "DRAFT";
      setrequest(newrequest);
      setIsUnpublishModalOpen(true);
    } else {
      setrequest(newrequest);
      status !== "PUBLISHED"
        ? setIsPublishModalOpen(true)
        : updateImage(newrequest, "PUBLISHED");
    }
  };

  const updateImage = (data, status) => {
    if (isImageUploaded) {
      setupdate(true);
      const imageData = new FormData();
      imageData.append("journeyConfigThumbnail", imageUrl?.url?.originFileObj);
      uploadImage(params?.journeyId, imageData)
        .then((res) => {
          setisImageUploaded(false);
          setImageUrl({
            url: res?.data?.image,
            error: false,
            type: "image",
          });
          handleJourneyConfig({ ...data, image: res?.data?.image }, status);
        })
        .catch((err) => {
          setupdate(false);
          NotificationInstance.error({
            message: "Image upload failed",
            description: "Please try again.",
          });
        });
    } else {
      handleJourneyConfig(data, status);
    }
  };
  const handleJourneyConfig = (data, statusValue) => {
    setupdate(true);
    setIsPublishModalOpen(false);
    setIsUnpublishModalOpen(false);
    updateJourneyConfig(params.journeyId, data)
      .then((res) => {
        if (blocker.state === "blocked") {
          blocker?.reset();
        }
        NotificationInstance.success({
          message: "Successful",
          description: "Successfully updated journey",
        });
        setDisplayName([selectedCategory?.name, data?.title]);
        setIsSaveModalOpen(false);
        if (statusValue === "DRAFT") {
          setLastSavedTime(new Date());
          form.setFieldsValue(data);
          if (data?.plan?.length > 0) {
            const updatedSections = data.plan.map((section) => {
              if (!section.milestones || section.milestones.length === 0) {
                section.milestones =
                  data.type === "Fixed"
                    ? [
                        {
                          title: "",
                          videoIds: [],
                          fileIds: [],
                          videoURLs: [],
                          tasks: [
                            {
                              task: "",
                              estimate: 0,
                              ordinal: 0,
                              videoIds: [],
                              fileIds: [],
                              videoURLs: [],
                            },
                          ],
                        },
                      ]
                    : [
                        {
                          title: "",
                          videoIds: [],
                          fileIds: [],
                          videoURLs: [],
                        },
                      ];
              }
              if (!section.additionalPrompts && data.type === "Pre_Defined") {
                section.additionalPrompts = [""];
              }
              return section;
            });
            setSections(updatedSections);
          } else {
            setSections(
              data?.type === "Pre_Defined"
                ? [preDefinedSection]
                : [fixedSection]
            );
          }
          if (data?.questions?.length > 0) {
            setQuestions(data?.questions);
          } else {
            setQuestions([""]);
          }
        }
        setIsFormTouched(false);
        setjourney({
          ...journey,
          // questions: data?.questions ?? [],
          // plan: data?.plan ?? [],
          title: title?.value,
          image: data?.image ?? null,
        });
        const values =
          type === "Fixed"
            ? {
                plan: data?.plan,
              }
            : {
                questions: data?.questions,
                plan: data?.plan,
              };
        form.setFieldsValue(values);
        setupdate(false);
        setstatus(data?.status);
        if (statusValue === "PUBLISHED") {
          setTimeout(() => {
            navigate(`/${selectedCategory?._id}`);
          }, 100);
        }
      })
      .catch((error) => {
        if (blocker.state === "blocked") {
          blocker?.reset();
        }
        NotificationInstance.error({
          message:
            error?.response?.data?.message ?? "Please verify and try again",
        });
        setupdate(false);
      });
  };

  const onBack = (category) => {
    if (
      isFormTouched ||
      title?.value !== journey?.title ||
      imageUrl?.url !== journey?.image
    ) {
      setIsSaveModalOpen(true);
    } else {
      navigate(`/${category}`, { replace: true });
    }
  };
  const handleMenuClick = (e) => {
    switch (e.key) {
      case "1":
        onEnable();
        break;
      case "2":
        setIsJourneyDeleteModalOpen(true);
        break;
      case "3":
        break;
      default:
        break;
    }
  };
  const handleDeleteJourney = (id) => {
    setIsJourneyDeleteModalOpen(false);
    setloading(true);
    deleteSelectedJourneys([id])
      .then((res) => {
        NotificationInstance.success({
          message: "Successful",
          description: "Successfully deleted journey",
        });
        setIsFormTouched(false);
        setjourney({
          ...journey,
          // questions: data?.questions ?? [],
          // plan: data?.plan ?? [],
          title: title?.value,
          image: imageUrl?.url ?? null,
        });
        setTimeout(() => {
          setloading(false);
          navigate(`/${params.categoryId}`, { replace: true });
        }, 100);
      })
      .catch((error) => {
        setloading(false);
        NotificationInstance.error({
          message:
            error?.response?.data?.message ?? "Please verify & try again.",
        });
      });
  };
  const createJourneyChannel = () => {
    createChannel(params.journeyId)
      .then((res) => {
        setchannel(res?.data?._id);
      })
      .catch((err) => {});
  };
  const menuProps = {
    items,
    onClick: handleMenuClick,
  };
  const handleSave = () => {
    setIsSaveModalOpen(false);
    onSubmit(status);
  };
  const updateFields = (data, type) => {
    const tag = type === "Fixed" ? fixedSection : preDefinedSection;
    if (data?.plan?.length === 0 && data?.questions?.length === 0) {
      form.setFieldsValue({
        plan: [tag],
        questions: [""],
      });
    } else if (data?.plan?.length === 0) {
      form.setFieldsValue({
        plan: [tag],
        questions: data?.questions?.length === 0 ? [""] : data?.questions,
      });
    } else if (data?.questions?.length === 0) {
      form.setFieldsValue({
        plan: data?.plan,
        questions: [""],
      });
    } else {
      form.setFieldsValue(data);
    }
  };
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (
        isFormTouched ||
        title?.value !== journey?.title ||
        imageUrl?.url !== journey?.image
      ) {
        event.preventDefault();
        event.returnValue = "";
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isFormTouched, title, journey, imageUrl]);
  let blocker = useBlocker(
    useCallback(
      () =>
        isSaveModalOpen ||
        isFormTouched ||
        title?.value !== journey?.title ||
        imageUrl?.url !== journey?.image,
      [isFormTouched, title, imageUrl, journey, isSaveModalOpen]
    )
  );

  useEffect(() => {
    if (blocker.state === "blocked") {
      setIsSaveModalOpen(true);
    }
  }, [blocker]);
  const handleFieldChange = () => {
    setIsFormTouched(form.isFieldsTouched());
  };
  if (loading) {
    return (
      <div className="loader-container">
        <div className="loader"></div>
      </div>
    );
  }
  return (
    <div className="form-container">
      {update && (
        <div className="form-loader-container">
          <div className="loader"></div>
        </div>
      )}
      <div className="form-configuration">
        <div className="form-header">
          <Flex vertical  style={{width:"100%"}} gap={5}>
            <Breadcrumbs displayName={displayName} config={true} />
            <Flex justify="space-between" gap={20}>
              <div className="title">
                <UnSavedModal
                  isModalOpen={isSaveModalOpen}
                  handleOk={handleSave}
                  handleCancel={() => {
                    setIsSaveModalOpen(false);
                  }}
                  handleDiscard={() => {
                    if (blocker.state === "blocked") {
                      blocker?.proceed();
                    }
                    navigate(`/${params.categoryId}`, { replace: true });
                  }}
                />
                <div className="title-input-wrapper overflow">
                  {titleDisabled ? (
                    <span className="journeys-title">{title.value}</span>
                  ) : (
                    <Input
                      value={title.value}
                      className={`title-input ${
                        !titleDisabled ? "active" : ""
                      }  ${title.error ? "is-invalid" : ""}`}
                      disabled={titleDisabled}
                      defaultValue={"Journey Name"}
                      id="journey-title"
                      onChange={(e) => onTitleChange(e)}
                    />
                  )}
                  {title.error && (
                    <span className="error-message">
                      Please enter a journey name
                    </span>
                  )}
                </div>
                <CurrentStatusTag status={status} />
              </div>
              <div className="journey-action-buttons">
                {lastSavedTime && !isNaN(lastSavedTime) && (
                  <p className="last-update">
                    Changes saved {timeSinceLastSaved(lastSavedTime)}
                  </p>
                )}
                <Button onClick={() => onSubmit(status)} color="white">
                  Save Changes
                </Button>
                {status === "DRAFT" ? (
                  <Button
                    type="primary"
                    onClick={() => onSubmit("PUBLISHED")}
                    className="save-button"
                  >
                    Publish
                  </Button>
                ) : (
                  <Button type="default" onClick={() => onSubmit("UNPUBLISH")}>
                    Unpublish
                  </Button>
                )}
                <UnpublishModal
                  isModalOpen={isUnpublishModalOpen}
                  handleCancel={() => setIsUnpublishModalOpen(false)}
                  handleOk={() => updateImage(request, "DRAFT")}
                />
                {/* <DraftModal
                isModalOpen={isDraftModalOpen}
                handleCancel={() => setIsDraftModalOpen(false)}
                handleOk={() => handleJourneyConfig(request, "DRAFT")}
              /> */}
                <PublishModal
                  isModalOpen={isPublishModalOpen}
                  handleCancel={() => setIsPublishModalOpen(false)}
                  handleOk={() => updateImage(request, "PUBLISHED")}
                />
                <Dropdown menu={menuProps}>
                  <More />
                </Dropdown>
                <DeleteConfirmModal
                  isModalOpen={isJourneyDeleteModalOpen}
                  handleCancel={() => setIsJourneyDeleteModalOpen(false)}
                  handleOk={handleDeleteJourney}
                  type={"Journey"}
                  selectedJourney={params?.journeyId}
                />
              </div>
            </Flex>
          </Flex>
        </div>
        <div className="form-wrapper">
          <Form
            form={form}
            layout="vertical"
            onFieldsChange={handleFieldChange}
          >
            <div
              className="image-input"
              style={{
                backgroundImage: imageUrl.url
                  ? `url('${
                      imageUrl?.type === "object"
                        ? imageUrl.url?.preview
                        : imageUrl.url
                    }')`
                  : "",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <Button icon={<Image />} onClick={() => showModal()}>
                Add Image
              </Button>
            </div>
            {imageUrl.error && (
              <span className="error-message">Please add a image</span>
            )}
            <AddImageModal
              isModalOpen={isModalOpen}
              handleCancel={handleCancel}
              handleOk={handleOk}
              imageUrl={imageUrl.url}
              id={params?.journeyId}
              type={imageUrl?.type}
            />
            <UploadFileModal
              isModalOpen={fileModalOpen}
              handleCancel={() => setfileModalOpen(false)}
              handleOk={onFileChange}
              handleDelete={handlefileDelete}
              journeyType={type}
              VideoIndex={fileIndex.file}
              section={fileIndex.sections}
              milestoneIndex={fileIndex.milestone}
              videoDetail={fileDetail}
              handleTaskUpdation={onTaskFileChange}
            />
            {videoModalOpen && (
              <UploadVideoModal
                isModalOpen={videoModalOpen}
                handleCancel={() => setVideoModalOpen(false)}
                handleOk={onVideoUrlChange}
                handleDelete={handleVideoDelete}
                journeyType={type}
                type={"VIDEO"}
                VideoIndex={VideoIndex.file}
                section={VideoIndex.sections}
                milestoneIndex={VideoIndex.milestone}
                videoDetail={VideoDetail}
                handleTaskUpdation={onTaskVideoUrlChange}
              />
            )}
            {type === "Pre_Defined" && (
              <>
                <h2 className="section-title">Questions</h2>
                {questions?.map((input, index) => (
                  <Form.Item
                    required={false}
                    key={index}
                    name={["questions", index]}
                    label={`Question ${index + 1}`}
                    rules={[
                      {
                        required: true,
                        message: "Please enter a valid question",
                        validateTrigger: onFinish,
                      },
                      {
                        validator: validateInput,
                        message: "Please enter a valid question",
                        validateTrigger: [onFinish],
                      },
                    ]}
                  >
                    <div className="input-wrapper">
                      <Input
                        defaultValue={""}
                        value={input}
                        onChange={(e) => onQuestionChange(e, index)}
                        placeholder={`Type question ${index + 1} here`}
                      />
                      {index !== 0 && (
                        <Delete
                          className="delete"
                          onClick={() => removeInput(index, "question")}
                        />
                      )}
                    </div>
                  </Form.Item>
                ))}
                <button
                  className="add-button"
                  color="light"
                  onClick={(e) => addNewItem("question", e)}
                >
                  <Plus /> <span>Add Question</span>
                </button>
              </>
            )}
            {sections?.map((section, sectionindex) => (
              <div className="section-container" key={sectionindex}>
                <div className="section-title-wrapper">
                  <h2 className="section-title">Section {sectionindex + 1}</h2>
                  {sectionindex !== 0 && (
                    <Delete
                      className="delete"
                      onClick={() => removeInput(sectionindex, "section")}
                    />
                  )}
                </div>
                <Form.Item>
                  <Form.Item
                    required={false}
                    name={["plan", sectionindex, "section"]}
                    rules={[
                      {
                        required: true,
                        message: "Please enter a valid title",
                        validateTrigger: onFinish,
                      },
                      {
                        validator: validateInput,
                        message: "Please enter a valid title",
                        validateTrigger: [onFinish],
                      },
                    ]}
                  >
                    <div className="input-wrapper">
                      <Input
                        value={section?.section}
                        onChange={(e) => onSectionTitleChange(e, sectionindex)}
                        placeholder={`Enter the name of section ${
                          sectionindex + 1
                        } here`}
                      />
                    </div>
                  </Form.Item>
                  <div className="section-content-container">
                    <div className="milestone-form">
                      <h3 className="section-sub-title">Milestones</h3>
                      {section?.milestones?.map((milestone, index) => (
                        <div className="milestone-container" key={index}>
                          <Form.Item
                            required={false}
                            className="section-wrapper"
                            label={`Milestone ${index + 1}`}
                            name={[
                              "plan",
                              sectionindex,
                              "milestones",
                              index,
                              `title`,
                            ]}
                            rules={[
                              {
                                required: true,
                                message: "Please enter a valid milestone",
                                validateTrigger: onFinish,
                              },
                              {
                                validator: validateInput,
                                message: "Please enter a valid milestone",
                                validateTrigger: [onFinish],
                              },
                            ]}
                          >
                            <div className="input-wrapper">
                              <Input
                                value={milestone?.title}
                                onChange={(e) =>
                                  onMilestoneChange(e, sectionindex, index)
                                }
                                placeholder={`Enter the name of milestone ${
                                  index + 1
                                } here`}
                              />
                              {index !== 0 && (
                                <Delete
                                  className="delete"
                                  onClick={() =>
                                    removeInput(
                                      index,
                                      "milestone",
                                      sectionindex
                                    )
                                  }
                                />
                              )}
                            </div>
                          </Form.Item>

                          <Flex wrap gap="small">
                            {milestone?.videoIds?.map((item, videoIndex) => {
                              return (
                                <>
                                  <Form.Item
                                    key={videoIndex}
                                    name={[
                                      "plan",
                                      sectionindex,
                                      "milestones",
                                      index,
                                      `videoIds`,
                                      videoIndex,
                                    ]}
                                    initialValue={item}
                                  >
                                    <div className="input-wrapper">
                                      <Input
                                        value={item}
                                        style={{ display: "none" }}
                                        placeholder={`Enter the name of milestone ${
                                          index + 1
                                        } here`}
                                      />
                                    </div>
                                  </Form.Item>
                                  <Button
                                    disabled={!milestone.title}
                                    className="upload"
                                    onClick={() => {
                                      setVideoModalOpen(true);
                                      setVideoIndex({
                                        sections: sectionindex,
                                        milestone: index,
                                        file: videoIndex,
                                      });
                                      setVideoDetail({
                                        uploaded: item !== "" ? true : false,
                                        index: item !== "" ? item : false,
                                        task: false,
                                      });
                                    }}
                                  >
                                    Video {videoIndex + 1}
                                  </Button>
                                </>
                              );
                            })}
                          </Flex>

                          <button
                            className="add-button video-upload"
                            color="light"
                            disabled={!milestone?.title}
                            onClick={(e) => {
                              e.preventDefault();
                              setVideoModalOpen(true);
                              setVideoIndex({
                                sections: sectionindex,
                                milestone: index,
                                file: "",
                              });
                              setVideoDetail({
                                index: "",
                                uploaded: false,
                                task: false,
                              });
                            }}
                          >
                            <Plus /> <span>Upload / Add Video</span>
                          </button>
                          <Flex vertical gap="small">
                            {milestone?.videoURLs?.map((item, videoIndex) => {
                              return (
                                <Flex justify="space-between">
                                  <Form.Item
                                    key={videoIndex}
                                    name={[
                                      "plan",
                                      sectionindex,
                                      "milestones",
                                      index,
                                      `videoURLs`,
                                      videoIndex,
                                    ]}
                                    style={{ width: "100%" }}
                                    initialValue={item}
                                    rules={[
                                      {
                                        required: true,
                                        message: "Please enter a valid URL",
                                        validateTrigger: onFinish,
                                      },
                                      {
                                        pattern:
                                          /^(http|https):\/\/[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(\/[a-zA-Z0-9-._~:?#@!$&'()*+,;=]*)*$/,
                                        message: "Please enter a valid URL",
                                        validateTrigger: onFinish,
                                      },
                                    ]}
                                  >
                                    <div className="input-wrapper">
                                      <Input
                                        value={item}
                                        onChange={(e) =>
                                          onMilestoneVideoChange(
                                            e,
                                            sectionindex,
                                            index,
                                            videoIndex
                                          )
                                        }
                                        placeholder={`Enter video Url ${
                                          videoIndex + 1
                                        } here`}
                                      />
                                    </div>
                                  </Form.Item>
                                  <Delete
                                    className="delete"
                                    onClick={() =>
                                      removeInput(
                                        videoIndex,
                                        "video",
                                        sectionindex,
                                        index
                                      )
                                    }
                                  />
                                </Flex>
                              );
                            })}
                          </Flex>

                          <button
                            className="add-button video-upload"
                            color="light"
                            disabled={!milestone?.title}
                            onClick={(e) =>
                              addNewItem("video", e, sectionindex, index)
                            }
                          >
                            <Plus /> <span>Add Video Url</span>
                          </button>
                          <Flex wrap gap="small">
                            {milestone?.fileIds?.map((item, FileIndex) => {
                              return (
                                <>
                                  <Form.Item
                                    key={FileIndex}
                                    name={[
                                      "plan",
                                      sectionindex,
                                      "milestones",
                                      index,
                                      `fileIds`,
                                      FileIndex,
                                    ]}
                                    initialValue={item}
                                  >
                                    <div className="input-wrapper">
                                      <Input
                                        value={item}
                                        style={{ display: "none" }}
                                        placeholder={`Enter the name of milestone ${
                                          index + 1
                                        } here`}
                                      />
                                    </div>
                                  </Form.Item>
                                  <Button
                                    disabled={!milestone.title}
                                    className="upload"
                                    onClick={() => {
                                      setfileModalOpen(true);
                                      setfileIndex({
                                        sections: sectionindex,
                                        milestone: index,
                                        file: FileIndex,
                                      });
                                      setfileDetail({
                                        uploaded: item !== "" ? true : false,
                                        index: item !== "" ? item : false,
                                        task: false,
                                      });
                                    }}
                                  >
                                    File {FileIndex + 1}
                                  </Button>
                                </>
                              );
                            })}
                          </Flex>

                          <button
                            className="add-button video-upload"
                            color="light"
                            disabled={!milestone?.title}
                            onClick={(e) => {
                              e.preventDefault();
                              setfileModalOpen(true);
                              setfileIndex({
                                sections: sectionindex,
                                milestone: index,
                                file: "",
                              });
                              setfileDetail({
                                index: "",
                                uploaded: false,
                                task: false,
                              });
                            }}
                          >
                            <Plus /> <span>Upload File</span>
                          </button>
                          {type !== "Pre_Defined" && (
                            <div className="tasks">
                              {milestone?.tasks?.map((task, taskIndex) => (
                                <div className="milestone-tasks">
                                  <div className="section-title-wrapper">
                                    <h4 className="section-tast-title">
                                      Task {taskIndex + 1}
                                    </h4>
                                    {taskIndex !== 0 && (
                                      <Delete
                                        className="delete"
                                        onClick={() =>
                                          removeInput(
                                            taskIndex,
                                            "task",
                                            sectionindex,
                                            index
                                          )
                                        }
                                      />
                                    )}
                                  </div>
                                  <Form.Item
                                    required={false}
                                    className="section-wrapper"
                                    label={`Task`}
                                    name={[
                                      "plan",
                                      sectionindex,
                                      "milestones",
                                      index,
                                      "tasks",
                                      taskIndex,
                                      "task",
                                    ]}
                                    rules={[
                                      {
                                        required: true,
                                        message:
                                          "Please enter a valid task name",
                                        validateTrigger: onFinish,
                                      },
                                      {
                                        validator: validateInput,
                                        message:
                                          "Please enter a valid task name",
                                        validateTrigger: [onFinish],
                                      },
                                    ]}
                                  >
                                    <div className="input-wrapper">
                                      <Input
                                        value={task?.task}
                                        onChange={(e) =>
                                          onTaskNameChange(
                                            e,
                                            sectionindex,
                                            index,
                                            taskIndex
                                          )
                                        }
                                        placeholder={`Enter the name of milestone ${
                                          taskIndex + 1
                                        } here`}
                                      />
                                    </div>
                                  </Form.Item>
                                  <div className="task-estimate">
                                    <Form.Item
                                      min={0}
                                      required={false}
                                      label={`Estimate`}
                                      name={[
                                        "plan",
                                        sectionindex,
                                        "milestones",
                                        index,
                                        "tasks",
                                        taskIndex,
                                        "estimate",
                                      ]}
                                      rules={[
                                        {
                                          required: true,
                                          message: "Please enter a estimate",
                                          validateTrigger: onFinish,
                                          type: "integer",
                                        },
                                        {
                                          min: 1,
                                          message:
                                            "Estimate should be at least 1",
                                          validateTrigger: onFinish,
                                          type: "integer",
                                        },
                                      ]}
                                    >
                                      <InputNumber
                                        value={task?.estimate}
                                        min={1}
                                        onChange={(e) =>
                                          onEstimateChange(
                                            e,
                                            sectionindex,
                                            index,
                                            taskIndex
                                          )
                                        }
                                        placeholder={`Enter the estimate here`}
                                      />
                                    </Form.Item>
                                    <Form.Item
                                      min={0}
                                      required={false}
                                      label={`Ordinal`}
                                      name={[
                                        "plan",
                                        sectionindex,
                                        "milestones",
                                        index,
                                        "tasks",
                                        taskIndex,
                                        "ordinal",
                                      ]}
                                      rules={[
                                        {
                                          required: true,
                                          message: "Please enter a ordinal",
                                          validateTrigger: onFinish,
                                          type: "integer",
                                        },
                                        {
                                          min: 1,
                                          message:
                                            "Ordinal should be at least 1",
                                          validateTrigger: onFinish,
                                          type: "integer",
                                        },
                                      ]}
                                    >
                                      <InputNumber
                                        min={1}
                                        value={task?.ordinal}
                                        onChange={(e) =>
                                          onOrdinalChange(
                                            e,
                                            sectionindex,
                                            index,
                                            taskIndex
                                          )
                                        }
                                        placeholder={`Enter the ordinal here`}
                                      />
                                    </Form.Item>
                                  </div>
                                  <Flex wrap gap="small">
                                    {task?.videoIds?.map(
                                      (item, taskVideoindex) => (
                                        <>
                                          <Form.Item
                                            key={taskVideoindex}
                                            name={[
                                              "plan",
                                              sectionindex,
                                              "milestones",
                                              index,
                                              "tasks",
                                              taskIndex,
                                              `videoIds`,
                                              taskVideoindex,
                                            ]}
                                            initialValue={item}
                                          >
                                            <div className="input-wrapper">
                                              <Input
                                                value={item}
                                                style={{ display: "none" }}
                                                placeholder={`Enter the name of milestone ${
                                                  index + 1
                                                } here`}
                                              />
                                            </div>
                                          </Form.Item>
                                          <Button
                                            disabled={!milestone.title}
                                            className="upload"
                                            onClick={() => {
                                              setVideoModalOpen(true);
                                              setVideoIndex({
                                                sections: sectionindex,
                                                milestone: index,
                                                file: taskVideoindex,
                                              });
                                              setVideoDetail({
                                                uploaded:
                                                  item !== "" ? true : false,
                                                index:
                                                  item !== "" ? item : false,
                                                task: taskIndex,
                                              });
                                            }}
                                          >
                                            Video {taskVideoindex + 1}
                                          </Button>
                                        </>
                                      )
                                    )}
                                  </Flex>
                                  <button
                                    disabled={!task?.task || !milestone?.title}
                                    className="add-button video-upload"
                                    color="light"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setVideoModalOpen(true);
                                      setVideoIndex({
                                        sections: sectionindex,
                                        milestone: index,
                                        file: "",
                                      });
                                      setVideoDetail({
                                        index: "",
                                        uploaded: false,
                                        task: taskIndex,
                                      });
                                    }}
                                  >
                                    <Plus /> <span>Upload / Add Video</span>
                                  </button>
                                  <Flex vertical gap="small">
                                    {task?.videoURLs?.map(
                                      (item, videoIndex) => {
                                        return (
                                          <Flex justify="space-between">
                                            <Form.Item
                                              key={videoIndex}
                                              name={[
                                                "plan",
                                                sectionindex,
                                                "milestones",
                                                index,
                                                "tasks",
                                                taskIndex,
                                                `videoURLs`,
                                                videoIndex,
                                              ]}
                                              initialValue={item}
                                              style={{ width: "100%" }}
                                              rules={[
                                                {
                                                  required: true,
                                                  message:
                                                    "Please enter a valid URL",
                                                  validateTrigger: onFinish,
                                                },
                                                {
                                                  pattern:
                                                    /^(http|https):\/\/[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(\/[a-zA-Z0-9-._~:?#@!$&'()*+,;=]*)*$/,
                                                  message:
                                                    "Please enter a valid URL",
                                                  validateTrigger: onFinish,
                                                },
                                              ]}
                                            >
                                              <div className="input-wrapper">
                                                <Input
                                                  value={item}
                                                  onChange={(e) =>
                                                    onTaskVideoChange(
                                                      e,
                                                      sectionindex,
                                                      index,
                                                      taskIndex,
                                                      videoIndex
                                                    )
                                                  }
                                                  placeholder={`Enter video Url ${
                                                    videoIndex + 1
                                                  } here`}
                                                />
                                              </div>
                                            </Form.Item>
                                            <Delete
                                              className="delete"
                                              onClick={() =>
                                                removeInput(
                                                  videoIndex,
                                                  "taskVideo",
                                                  sectionindex,
                                                  index,
                                                  taskIndex
                                                )
                                              }
                                            />
                                          </Flex>
                                        );
                                      }
                                    )}
                                  </Flex>

                                  <button
                                    className="add-button video-upload"
                                    color="light"
                                    disabled={!milestone?.title}
                                    onClick={(e) =>
                                      addNewItem(
                                        "taskVideo",
                                        e,
                                        sectionindex,
                                        taskIndex,
                                        index
                                      )
                                    }
                                  >
                                    <Plus /> <span>Add Video Url</span>
                                  </button>
                                  <Flex wrap gap="small">
                                    {task?.fileIds?.map(
                                      (item, taskfileindex) => (
                                        <>
                                          <Form.Item
                                            key={taskfileindex}
                                            name={[
                                              "plan",
                                              sectionindex,
                                              "milestones",
                                              index,
                                              "tasks",
                                              taskIndex,
                                              `fileIds`,
                                              taskfileindex,
                                            ]}
                                            initialValue={item}
                                          >
                                            <div className="input-wrapper">
                                              <Input
                                                value={item}
                                                style={{ display: "none" }}
                                                placeholder={`Enter the name of milestone ${
                                                  index + 1
                                                } here`}
                                              />
                                            </div>
                                          </Form.Item>
                                          <Button
                                            disabled={!milestone.title}
                                            className="upload"
                                            onClick={() => {
                                              setfileModalOpen(true);
                                              setfileIndex({
                                                sections: sectionindex,
                                                milestone: index,
                                                file: taskfileindex,
                                              });
                                              setfileDetail({
                                                uploaded:
                                                  item !== "" ? true : false,
                                                index:
                                                  item !== "" ? item : false,
                                                task: taskIndex,
                                              });
                                            }}
                                          >
                                            file {taskfileindex + 1}
                                          </Button>
                                        </>
                                      )
                                    )}
                                  </Flex>
                                  <button
                                    disabled={!task?.task || !milestone?.title}
                                    className="add-button video-upload"
                                    color="light"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setfileModalOpen(true);
                                      setfileIndex({
                                        sections: sectionindex,
                                        milestone: index,
                                        file: "",
                                      });
                                      setfileDetail({
                                        index: "",
                                        uploaded: false,
                                        task: taskIndex,
                                      });
                                    }}
                                  >
                                    <Plus /> <span>Upload File</span>
                                  </button>
                                </div>
                              ))}
                              <button
                                className="add-button"
                                color="light"
                                onClick={(e) =>
                                  addNewItem("task", e, sectionindex, index)
                                }
                              >
                                <Plus /> <span>Add Task</span>
                              </button>
                            </div>
                          )}
                        </div>
                      ))}
                      <button
                        className="add-button"
                        color="light"
                        onClick={(e) =>
                          addNewItem("milestone", e, sectionindex)
                        }
                      >
                        <Plus /> <span>Add Milestone</span>
                      </button>
                    </div>
                    {type === "Pre_Defined" && (
                      <div className="prompt-form">
                        <h3 className="section-sub-title">Prompts</h3>
                        {section?.additionalPrompts?.map((prompt, index) => (
                          <div className="milestone-container" key={index}>
                            <Form.Item
                              required={false}
                              className="section-wrapper"
                              label={`Prompt ${index + 1}`}
                              name={[
                                "plan",
                                sectionindex,
                                "additionalPrompts",
                                index,
                              ]}
                            >
                              <div className="input-wrapper">
                                <Input
                                  value={prompt}
                                  onChange={(e) =>
                                    onPromptChange(e, sectionindex, index)
                                  }
                                  placeholder={`Enter the name of prompt ${
                                    index + 1
                                  } here`}
                                />
                                {index !== 0 && (
                                  <Delete
                                    className="delete"
                                    onClick={() =>
                                      removeInput(index, "prompt", sectionindex)
                                    }
                                  />
                                )}
                              </div>
                            </Form.Item>
                          </div>
                        ))}
                        <button
                          className="add-button"
                          color="light"
                          onClick={(e) => addNewItem("prompt", e, sectionindex)}
                        >
                          <Plus /> <span>Add Prompt</span>
                        </button>
                      </div>
                    )}
                  </div>
                </Form.Item>
              </div>
            ))}
            <button
              className="add-button"
              color="light"
              onClick={(e) => addNewItem("section", e)}
            >
              <Plus /> <span>Add Section</span>
            </button>
          </Form>
        </div>
      </div>
    </div>
  );
};
export default JourneyConfig;
