import AxiosInstance from "./axiosInstance";

export const getUserDetails = async () => {
  try {
    const response = await AxiosInstance.get("/admin/my-account");
    if (response?.data?.data) {
      return response.data;
    } else {
      throw new Error("reset password request failed");
    }
  } catch (error) {
    throw error;
  }
};
export const getGeneralChannel = async () => {
  try {
    const response = await AxiosInstance.get("/admin/my-account/channel");
    if (response?.data?.data) {
      return response.data;
    } else {
      throw new Error("reset password request failed");
    }
  } catch (error) {
    throw error;
  }
};

export const createGeneralChannel = async () => {
  try {
    const response = await AxiosInstance.post("/admin/my-account/channel");
    if (response?.data?.data) {
      return response.data;
    } else {
      throw new Error("reset password request failed");
    }
  } catch (error) {
    throw error;
  }
};
export const sendGeneralMessage = async (channel,thread, data) => {
  let query = "?";
  if (thread) {
    query = query + `threadCtxId=${thread}`;
  }
  try {
    const response = await AxiosInstance.post(
      `/admin/my-account/channel/${channel}/message`+query,
      data
    );
    if (response?.data?.data) {
      return response.data;
    } else {
      throw new Error("reset password request failed");
    }
  } catch (error) {
    throw error;
  }
};
export const getGeneralMessage = async (channel,thread, limit, pageNo) => {
  let query = "?";
  if (thread) {
    query = query + `threadCtxId=${thread}&`;
  }
  if (limit) {
    query = query + `limit=${limit}&`;
  }
  if (pageNo > 0) {
    query = query + `pageNo=${pageNo}&`;
  }
  try {
    const response = await AxiosInstance.get(
      `/admin/my-account/channel/${channel}/thread` + query
    );
    if (response?.data?.data) {
      return response.data;
    } else {
      throw new Error("reset password request failed");
    }
  } catch (error) {
    throw error;
  }
};

export const getLatestGeneralMessage = async (channel,id, limit, pageNo) => {
    let query = "?";
    if (limit) {
      query = query + `limit=${limit}&`;
    }
    if (pageNo > 0) {
      query = query + `pageNo=${pageNo}&`;
    }
    try {
      const response = await AxiosInstance.get(
        `/admin/my-account/channel/${channel}/message/${id}` + query
      );
      if (response?.data?.data) {
        return response.data;
      } else {
        throw new Error("reset password request failed");
      }
    } catch (error) {
      throw error;
    }
  };
  