import {
  Button,
  Col,
  Flex,
  Form,
  Input,
  InputNumber,
  message,
  Popconfirm,
  Row,
} from "antd";
import React, { useEffect, useState } from "react";

import "./courseDetails.css";
import {
  submitCourseBriefingAnswers,
  submitCourseBriefingScript,
} from "../../../api/adminService";
import { useLocation } from "react-router-dom";
const CourseDetails = ({
  type,
  questions,
  step,
  setCourseId,
  courseId,
  course,
  setCourse,
  setContentStep,
  updateLessons,
  sectionStep,
  updateStatus,
  setCourseListner,
}) => {
  const { TextArea } = Input;
  const [form] = Form.useForm();
  const [updated, setUpdated] = useState(false);
  const [scriptUpdated, setScriptUpdated] = useState(false);
  const [courseScript, setCourseScript] = useState(course?.courseBriefing);
  const [answers, setAnswers] = useState(false);
  const location = useLocation();
  const title = location.state?.title;

  useEffect(() => {
    if (title && questions) {
      if (questions.length > 0) {
        form.setFieldValue(questions[0]?._id, title);
      }
    }
  }, [title, questions]);

  const onSubmit = (type, category) => {
    if (type === "course") {
      if (category === "form") {
        form
          .validateFields()
          .then((values) => {
            const answers = {
              answers: Object.keys(values).map((key) => ({
                questionId: key,
                answer: values[key],
              })),
            };
            updateStatus(sectionStep.id, "GENERATING");
            submitCourseBriefingAnswers(answers, courseId)
              .then((res) => {
                setCourseListner(true);
                setUpdated(false);
                setScriptUpdated(false);
                setCourseId(res?.data?._id);
                setCourse(res?.data)
              })
              .catch((err) => {});
          })
          .catch((errorInfo) => {
            handleFinishFailed({ errorFields: errorInfo?.errorFields });
            console.log("Validation Failed:", errorInfo); // Handle validation errors
          });
      } else if (category === "script") {
        if (courseScript?.trim() !== "") {
          updateStatus(sectionStep.id, "SUBMITTING");
          updateLessons("SUBMITTING");
          submitCourseBriefingScript(courseScript, courseId)
            .then((res) => {
              setScriptUpdated(false);
              setUpdated(false);
              setCourse((prev) => ({ ...prev, courseBriefing: courseScript }));
              updateLessons("COMPLETED");
            })
            .catch((err) => {});
        } else {
          message.info("Please enter a script");
        }
      }
    }
  };

  const onScriptChange = (e) => {
    setCourseScript(e.target.value);
    setScriptUpdated(true);
  };

  useEffect(() => {
    if (course?.userAnswers) {
      const initialAnswers = course?.userAnswers?.reduce((acc, answer) => {
        acc[answer.questionId] = answer.answer;
        return acc;
      }, {});
      setAnswers(initialAnswers);
      form.setFieldsValue(initialAnswers);
    }
    if (course?.courseBriefing) {
      setCourseScript(course?.courseBriefing);
    }
  }, [course?.userAnswers, course?.courseBriefing]);

  const handleFinishFailed = ({ errorFields }) => {
    if (errorFields?.length > 0) {
      const errorf = errorFields[0]?.name[0];
      form.scrollToField(errorf, {
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    }
  };
  const handleDiscard = (type) => {
    if (type === "form") {
      form.setFieldsValue(answers);
      setUpdated(false);
    } else {
      setCourseScript(course?.courseBriefing);
      setScriptUpdated(false);
    }
  };

  const renderContent = (type) => {
    if (type === "form") {
      return (
        <Form
          layout="vertical"
          className="course-detail-form"
          form={form}
          onFieldsChange={() => setUpdated(sectionStep.id)}
          initialValues={answers}
        >
          {questions?.map((item) => (
            <Form.Item
              required={false}
              className="title"
              label={<span className="question">{item.questionText}</span>}
              name={item._id}
              rules={[
                {
                  required: true,
                  message: "Please enter the answer",
                },
                ...(item?.questionType === "Number"
                  ? [
                      {
                        validator: (_, value) => {
                          if (value > 5) {
                            return Promise.reject(
                              new Error(
                                "The lessons must not be greater than 5"
                              )
                            );
                          }
                          return Promise.resolve();
                        },
                      },
                    ]
                  : []),
              ]}
            >
              {item?.questionType === "String" ? (
                <Input.TextArea
                  readOnly={course?.courseStage === "COURSE_BRIEFING_CONFIRMED"}
                  autoSize={{ minRows: 6, maxRows: 6 }}
                  className="answer-input"
                  placeholder="Enter your answer here"
                />
              ) : (
                <InputNumber
                  readOnly={course?.courseStage === "COURSE_BRIEFING_CONFIRMED"}
                  className="answer-input"
                  placeholder="Enter your answer here"
                  type="number"
                  min={1}
                  // max={5}
                />
              )}
            </Form.Item>
          ))}
        </Form>
      );
    } else if (type === "script") {
      return (
        <TextArea
          readOnly={course?.courseStage === "COURSE_BRIEFING_CONFIRMED"}
          spellCheck={false}
          className="answer-input"
          defaultValue={courseScript}
          value={courseScript}
          onChange={onScriptChange}
        />
      );
    }
  };

  const renderButton = () => {
    if (
      course &&
      updated !== sectionStep.id &&
      sectionStep.status !== "GENERATING"
    ) {
      return (
        <Button
          type="primary"
          onClick={() => setContentStep((prev) => prev + 1)}
        >
          Next
        </Button>
      );
    } else if (questions) {
      return updated && course?.courseStage === "CONTENT_BRIEFING_GENERATED" ? (
        <Flex gap={20}>
          <Button onClick={() => handleDiscard("form")}>Discard Changes</Button>
          <Popconfirm
            overlayStyle={{ width: 400 }}
            title="Course Briefing"
            description="Submitting this form will delete the existing script and generate a new one. You won't be able to revert the changes. Do you want to proceed?"
            onConfirm={() => onSubmit(type, step.type)}
          >
            <Button
              type="primary"
              loading={sectionStep.status === "GENERATING"}
            >
              {sectionStep.status === "GENERATING"
                ? "Generating Script"
                : "Submit"}
            </Button>
          </Popconfirm>
        </Flex>
      ) : (
        <Button
          type="primary"
          loading={sectionStep.status === "GENERATING"}
          onClick={() => onSubmit(type, step.type)}
        >
          {sectionStep.status === "GENERATING" ? "Generating Script" : "Submit"}
        </Button>
      );
    }
  };
  return (
    <>
      <Row className="course-detail">
        <Col
          span={24}
          style={{
            height: "100%",
          }}
        >
          <Flex vertical className="course-detail-container">
            <Flex className="course-detail-header" justify="space-between">
              <span className="course-header-title">{step.title}</span>
            </Flex>
            {questions ? (
              <Flex flex={1} className="course-content">
                {renderContent(step.type)}
              </Flex>
            ) : (
              <Flex
                flex={1}
                className="course-content"
                justify="center"
                align="center"
                vertical
              >
                <h3>Loading Course Briefing Questions...</h3>
                <p>Please wait while we prepare your questions.</p>
                <div
                  className="video-loader"
                  style={{ width: "100%", marginTop: "20px" }}
                >
                  <div className="loader"></div>
                </div>
              </Flex>
            )}
            <Flex className="course-detail-footer" justify="flex-end">
              {step.type === "script" ? (
                <Flex flex={1} justify="space-between">
                  <Button onClick={() => setContentStep((prev) => prev - 1)}>
                    Back
                  </Button>
                  {(scriptUpdated ||
                    course?.courseStage === "CONTENT_BRIEFING_GENERATED") && (
                    <Flex gap={20}>
                      {scriptUpdated && (
                        <Button onClick={() => handleDiscard("script")}>
                          Discard Changes
                        </Button>
                      )}
                      <Popconfirm
                        overlayStyle={{ width: 400 }}
                        title="Course Briefing"
                        description="After submitting the course briefing script, you won't be able to change the course details. Do you want to proceed?"
                        onConfirm={() => onSubmit(type, step.type)}
                      >
                        <Button
                          type="primary"
                          loading={sectionStep.status === "SUBMITTING"}
                        >
                          {sectionStep.status === "SUBMITTING"
                            ? "Submitting script"
                            : "Confirm Script"}
                        </Button>
                      </Popconfirm>
                    </Flex>
                  )}
                </Flex>
              ) : (
                renderButton()
              )}
            </Flex>
          </Flex>
        </Col>
      </Row>
    </>
  );
};

export default CourseDetails;
