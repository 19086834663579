import { Divider, Flex, Modal } from "antd";
import React, { useEffect, useState } from "react";
import "./modal.css";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import { getFileUrl } from "../../api/adminService";

const ContentPlayer = ({
  data,
  isOpen,
  setIsOpen,
  setselectedContent,
  setVideoUrls,
}) => {
  const [url, setUrl] = useState(data.url || "");
  const [expiryTime, setExpiryTime] = useState(null);

  useEffect(() => {
    if (data && data._id) {
      const urlParams = new URLSearchParams(data.url?.split("?")[1] ?? "");
      let expiryTimeString = urlParams.get("se");
      let expiryTimestamp;

      if (!expiryTimeString) {
        expiryTimeString = urlParams.get("expires");
        if (!expiryTimeString) {
          console.error("URL does not contain valid expiry time parameters.");
          return;
        }
        expiryTimestamp = parseInt(expiryTimeString) * 1000;
      } else {
        expiryTimestamp = new Date(expiryTimeString).getTime();
      }
      setExpiryTime(expiryTimestamp);
      const fetchNewUrl = async () => {
        try {
          const newFile = await getFileUrl([data?._id]);
          if (newFile?.length > 0) {
            const file = newFile[0];
            setUrl(file?.url);
            setVideoUrls((prevUrls) => ({
              ...prevUrls,
              [data?._id]: file,
            }));
          }
        } catch (error) {
          console.error("Error fetching files:", error);
        }
      };
      const checkAndRefreshUrl = () => {
        const currentTime = Date.now();
        if (expiryTime && expiryTime - currentTime <= 5 * 60 * 1000) {
          fetchNewUrl();
        }
      };
      checkAndRefreshUrl();
      const intervalId = setInterval(checkAndRefreshUrl, 20 * 1000);

      return () => clearInterval(intervalId);
    }
  }, [url, expiryTime, data, isOpen]);

  function convertToEmbedUrl(url) {
    // Regex to match YouTube watch URLs
    const videoIdMatch = url.match(
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/watch\?v=|youtu\.be\/)([a-zA-Z0-9_-]{11})/
    );

    // If it's a YouTube URL, convert to embed URL
    if (videoIdMatch && videoIdMatch[1]) {
      const videoId = videoIdMatch[1];
      const embedUrl = `https://www.youtube.com/embed/${videoId}?rel=0`;
      return embedUrl;
    }

    // Return the original URL for non-YouTube links
    return url;
  }
  return (
    <Modal
      title={data.title || ""}
      open={isOpen}
      footer={false}
      width={700}
      maskClosable={true}
      className="content-display"
      onCancel={() => {
        setIsOpen(false);
        setselectedContent();
      }}
    >
      {data?.fileType === "IMAGE" ? (
        <Flex align="center" justify="center">
          <img src={url} alt="" />
        </Flex>
      ) : data?.fileType === "PPT" || data?.fileType === "DOC" ? (
        <>
          <Divider />
          <DocViewer
            documents={[
              {
                uri: url,
                fileType: data?.fileType === "PPT" ? "ppt" : "docx",
              },
            ]}
            theme={{
              primary: "#000000",
              secondary: "#000000",
              tertiary: "#000000",
              text_primary: "#000000",
              text_secondary: "#000000",
              text_tertiary: "#000000",
              disableThemeScrollbar: true,
            }}
            config={{
              header: {
                disableHeader: true,
              },
            }}
            pluginRenderers={DocViewerRenderers}
          />
        </>
      ) : (
        <>
          <Divider />
          <iframe src={convertToEmbedUrl(url)} frameborder="0"></iframe>
        </>
      )}
    </Modal>
  );
};

export default ContentPlayer;
