import { Button, Modal } from "antd";
import React from "react";
import "./modal.css";

const UnpublishModal = ({ isModalOpen, handleOk, handleCancel,type="Journey" }) => {
  return (
    <Modal
      centered
      width={450}
      title="Confirm Unpublish"
      open={isModalOpen}
      onCancel={handleCancel}
      footer={() => (
        <div className="action-buttons">
          <Button type="primary" onClick={handleCancel}>
            Cancel
          </Button>
          <Button className="cancel-button shadow" onClick={handleOk}>
            Yes, Unpublish
          </Button>
        </div>
      )}
    >
      <div className="title-input-wrapper">
        <span className="input-label">
          Are you sure you want to unpublish this {type}? Users will no longer
          be able to access it.
        </span>
      </div>
    </Modal>
  );
};

export default UnpublishModal;
