import { Button, Input, Modal, Progress, Upload } from "antd";
import React, { useState } from "react";
import "./modal.css";
import { ReactComponent as UploadIcon } from "../../assets/images/upload.svg";
import { ReactComponent as Image } from "../../assets/images/placeholder.svg";
import NotificationInstance from "../../services/notificationServices";
import { uploadPPT } from "../../api/hubSpotService";

const UploadVideoPresentation = ({
  isModalOpen,
  handleOk,
  handleCancel,
  file,
  setFile,
  setloading,
  loading,
}) => {
  const [uploadFileData, setUploadFileData] = useState({
    file: file,
    updated: false,
  });
  console.log(uploadFileData);

  const [progress, setProgress] = useState("");
  const [uploading, setUploading] = useState(false);
  console.log(progress);

  const handleChange = async (info) => {
    if (
      !info.file.type.match(
        /application\/(vnd\.ms-powerpoint|vnd\.openxmlformats-officedocument\.presentationml\.presentation)/
      )
    ) {
      return;
    }
    const fileSize = info.file.size / 1024 / 1024;
    if (fileSize > 10) {
      return;
    }
    setUploadFileData({ file: info.file, updated: true });
    if (info.file.status === "uploading") {
      setUploading(true);
      setProgress(info.file.percent);
    }
    if (info.file.status === "done") {
      setProgress(100);
      setTimeout(() => {
        setUploading(false);
      }, 500);
    } else if (info.file.status === "error") {
      setUploading(false);
      setProgress(0);
    }
  };

  const customRequest = async ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };
  const beforeUpload = (file) => {
    const isPPT =
      file.type === "application/vnd.ms-powerpoint" ||
      file.type ===
        "application/vnd.openxmlformats-officedocument.presentationml.presentation";
    const fileSize = file.size / 1024 / 1024;

    if (!isPPT) {
      NotificationInstance.warning({
        message: "Please upload only PPT or PPTX files!",
      });
      return false;
    }

    if (fileSize > 10) {
      NotificationInstance.warning({
        message: "File must be smaller than 10MB!",
      });
      return false;
    }

    return true;
  };

  const onSubmit = () => {
    if (uploadFileData.file) {
      const fileData = new FormData();
      fileData.append("videoPresentation", uploadFileData?.file?.originFileObj);
      setFile(uploadFileData.file);
      setUploadFileData({ ...uploadFileData, updated: false });
      setloading(true);
      handleOk(fileData);
    } else {
      NotificationInstance.warning({
        message: "Please upload a PPT or PPTX file!",
      });
    }
  };
  const formatSize = (size) => {
    if (size < 1024) {
      return `${size}B`;
    } else if (size < 1024 * 1024) {
      return `${Math.floor(size / 1024)}Kb`;
    } else {
      return `${Math.floor(size / (1024 * 1024))}Mb`;
    }
  };

  const onModalClose = () => {
    handleCancel();
    setUploadFileData({ file: file, updated: false });
  };

  return (
    <Modal
      centered
      width={450}
      title="Add Presentation"
      open={isModalOpen}
      onCancel={onModalClose}
      footer={() => (
        <div className="action-buttons">
          <Button
            type="primary"
            onClick={onSubmit}
            disabled={!uploadFileData.updated}
          >
            Upload
          </Button>
          <Button className="cancel-button" onClick={onModalClose}>
            Cancel
          </Button>
        </div>
      )}
    >
      {loading ? (
        <div className="thumbnail-upload">
          <div className="video-loader">
            <div className="loader"></div>
          </div>
        </div>
      ) : (
        <div className="thumbnail-upload">
          {uploadFileData.file ? (
            <>
              <Image />
              <div className="image-details">
                {uploadFileData?.file?.name && (
                  <span className="image-name">
                    {uploadFileData?.file?.name}
                  </span>
                )}
                {uploadFileData?.file?.size && (
                  <span className="image-size">
                    {formatSize(uploadFileData?.file?.size)}
                  </span>
                )}
              </div>
            </>
          ) : (
            <>
              <UploadIcon />
              <span className="browse">Browse and upload a presentation</span>
              <span className="supported-files">PPT or PPTX formats</span>
            </>
          )}

          <Upload
            listType="picture"
            maxCount={1}
            multiple={false}
            onChange={handleChange}
            customRequest={customRequest}
            beforeUpload={beforeUpload}
            showUploadList={false}
            accept=".ppt,.pptx"
          >
            {uploading ? (
              <Progress
                size={[200, 10]}
                showInfo={false}
                strokeColor={"#262528"}
                percent={progress}
              />
            ) : (
              <Button>
                {uploadFileData.file ? "Change File" : "Browse File"}
              </Button>
            )}
          </Upload>
        </div>
      )}
    </Modal>
  );
};

export default UploadVideoPresentation;
