import React, { useEffect, useRef, useState } from "react";
import "./lessonAudio.css";
import { Button, Col, Flex, Input, message, Row } from "antd";
import {
  PlayCircleOutlined,
  ReloadOutlined,
  PauseCircleOutlined,
} from "@ant-design/icons";

import ReactAudioPlayer from "react-audio-player";
import { LoadingOutlined } from "@ant-design/icons";
import {
  deleteAudios,
  generateAudio,
  generateAudioBatch,
} from "../../../api/hubSpotService";

import NotificationInstance from "../../../services/notificationServices";
import {
  generateUseCaseAudio,
  submitLessonAudios,
  updateLessonAudios,
  updateUseCaseAudio,
} from "../../../api/adminService";
import {
  audioStages,
  scriptUpdateStages,
  videoStages,
} from "../../../utils/format";
import VoiceSettings from "../../modals/voiceSettings";
import { courseStageTypes } from "../../../utils/constant";

const LessonAudio = ({
  chunks,
  setChunks,
  videoId,
  setVideoId,
  voiceId,
  title,
  courseId,
  lessonId,
  setcurrentStatus,
  currentStatus,
  addVideoId,
  lesson,
  setContentStep,
  voiceSetting,
  setVoiceSetting,
}) => {
  const { TextArea } = Input;
  const [audioUrls, setAudioUrls] = useState([]);
  const [currentAudio, setCurrentAudio] = useState(null);
  const [playingChunkIndex, setPlayingChunkIndex] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState();
  const [updateItem, setupdateItem] = useState();

  let currentVideoId = "";
  const audioRefs = useRef([]);
  const generateAudioForChunk = async (chunk, index) => {
    // setDisableNext(true);

    const body = {
      videoId: currentVideoId ? currentVideoId : videoId,
      videoType: "Course",
      audio: chunk,
    };

    try {
      const response = await updateLessonAudios(courseId, lessonId, body);
      setcurrentStatus("AUDIO_REGENERATION_INPROGRESS");
      addVideoId(response.info.videoId);
      setVideoId(response.info.videoId);
      if (response && response.data) {
        currentVideoId = response.data.videoId;
        // setDisableNext(false);
        const { order, url } = response.data;
        return { order, url, videoId };
      } else {
        // setDisableNext(false);
        console.error("Unexpected response format:", response);
        return { order: index, url: "" };
      }
    } catch (error) {
      // setDisableNext(false);
      console.error("Error generating audio:", error);
      NotificationInstance.error({
        message: "Error generating audios",
        description: error.response.data.error,
      });
      return { order: index, url: "" };
    }
  };

  const generateAudioForChunksBatched = async (chunks) => {
    // setDisableNext(true);

    const body = {
      videoId: currentVideoId ? currentVideoId : videoId,
      videoType: "Course",
      voiceId: voiceId,
      audioData: chunks,
      title: title,
    };
    try {
      const response = await submitLessonAudios(courseId, lessonId, body);
      setcurrentStatus("AUDIO_GENERATION_INPROGRESS");
      addVideoId(response.info.videoId);
      setVideoId(response.info.videoId);
      if (response && response.data && Array.isArray(response.data)) {
        currentVideoId = response.info.videoId;
        // setDisableNext(false);
        return response.data.map((item) => ({
          order: item.order,
          url: item.url,
          videoId: response.info.videoId,
        }));
      } else {
        console.error("Unexpected response format:", response);
        // setDisableNext(false);
        return chunks.map(({ index }) => ({ order: index, url: "" }));
      }
    } catch (error) {
      // setDisableNext(false);
      console.error("Error generating audio:", error);
      NotificationInstance.error({
        message: "Error generating audios",
        description: error.response?.data?.error || "Unknown error",
      });
      return chunks.map(({ index }) => ({ order: index, url: "" }));
    }
  };

  const handlePlay = (index) => {
    // Pause the previous audio if there was one playing
    if (playingChunkIndex !== null && playingChunkIndex !== index) {
      const previousAudio = audioRefs.current[playingChunkIndex];
      if (previousAudio) {
        previousAudio.audioEl.current.pause();
      }
    }
    setPlayingChunkIndex(index); // Update the current playing index
  };

  const playNext = (current, next) => {
    if (audioUrls[next] === null) {
      return;
    }
    if (current === chunks.length - 1) {
      audioRefs.current[current].audioEl.current.pause();
      setCurrentAudio(null);
      setPlayingChunkIndex(null);
    } else if (current < chunks.length - 1) {
      // audioRefs.current[current].audioEl.current.pause();
      audioRefs.current[next]?.audioEl?.current?.play();
      setPlayingChunkIndex(next);
      setCurrentAudio(next);
      if (audioRefs.current[next].audioEl) {
        audioRefs.current[next].audioEl.current.onended = () => {
          playNext(next, next + 1);
        };
      }
    } else if (playingChunkIndex === chunks.length - 1) {
      audioRefs.current[playingChunkIndex].audioEl.current.pause();
      setPlayingChunkIndex(0);
      audioRefs.current[0].audioEl.current.play();
      setCurrentAudio(0);
      audioRefs.current[0].audioEl.current.onended = () => {
        playNext(0, 1);
      };
    }
  };

  const handleStartOver = () => {
    if (currentAudio !== null) {
      audioRefs.current[currentAudio].audioEl.current.pause();
      setCurrentAudio(null);
      setPlayingChunkIndex(null);
    }
    playNext(0, 0);
  };

  const handleSentenceChange = async (index, e) => {
    if (chunks[index]) {
      const updatedScriptParts = chunks.map((part, i) =>
        i === index ? { ...part, text: e.target.value } : part
      );

      setChunks(updatedScriptParts);
    }
  };

  const handleRefresh = async (index, settings) => {
    if (chunks[index]) {
      const text = chunks[index].text;

      if (!text || text.trim() === "") {
        NotificationInstance.error({
          message: "Please enter the audio text",
        });
        return;
      }
      const updatedScriptParts = chunks.map((part, i) =>
        i === index ? { ...part, url: null, voiceSettings: settings } : part
      );
      setChunks(updatedScriptParts);
      const data = {
        audioData: {
          text: updatedScriptParts[index]?.text,
        },
        voiceSettings: settings,
        audioId: updatedScriptParts[index]?._id,
      };
      updateUseCaseAudio(lessonId, data)
        .then((res) => {
          setcurrentStatus("AUDIO_GENERATION_INPROGRESS");
        })
        .catch((err) => {});
    }
  };

  const handleUpdateAllAudios = async (settings) => {
    // Check if any chunk has empty text or text that contains only whitespace
    const invalidChunk = chunks.find(
      (part) => !part.text || part.text.trim() === ""
    );

    if (invalidChunk) {
      // Show error if any chunk has invalid text
      NotificationInstance.error({
        message:
          "Please enter the audio text for all sections before updating.",
      });
      return; // Stop execution if there's an invalid text
    }

    // Proceed if all chunks have valid text
    const updatedScriptParts = chunks.map((part) => ({
      ...part,
      url: null,
    }));

    setChunks(updatedScriptParts);

    const data = {
      voiceSettings: settings,
    };

    // Call your API to generate audio
    generateUseCaseAudio(lessonId, "", data)
      .then((res) => {
        setVoiceSetting(settings);
        setcurrentStatus("AUDIO_GENERATION_INPROGRESS");
      })
      .catch((err) => {
        console.error(err); // Handle error as needed
      });
  };

  const updateAudios = (type, settings) => {
    setIsModalOpen(false);
    if (type === "all") {
      handleUpdateAllAudios(settings);
    } else {
      handleRefresh(type, settings);
    }
  };

  useEffect(() => {
    if (lesson && currentStatus === "LESSON_SCRIPT_SUBMITTED") {
      generateUseCaseAudio(lessonId)
        .then((res) => {
          setcurrentStatus("AUDIO_GENERATION_INPROGRESS");
        })
        .catch((err) => {});
    }
  }, [lesson, currentStatus]);

  return (
    <Row className="course-detail" gutter={[10, 10]}>
      <Col span={24} className="lesson-detail-container">
        <Row>
          <Col span={24}>
            <Flex className="course-detail-header" justify="space-between">
              <span className="course-header-title">Lesson Audio</span>
              {!videoStages.includes(currentStatus) &&
                currentStatus !==
                  courseStageTypes.AUDIO_GENERATION_INPROGRESS && (
                  <Flex gap={10} align="center">
                    <Button
                      onClick={() => {
                        setIsModalOpen(true);
                        setupdateItem({
                          item: "all",
                          settings: voiceSetting,
                        });
                      }}
                    >
                      Voice Settings
                    </Button>
                  </Flex>
                )}
            </Flex>
          </Col>
          <VoiceSettings
            isModalOpen={isModalOpen}
            handleCancel={() => setIsModalOpen()}
            updateItem={updateItem}
            handleOk={updateAudios}
          />
        </Row>
        <Row vertical className="course-content" style={{ flex: 1 }}>
          <Col span={24}>
            <Flex vertical gap={20}>
              {chunks?.map((sentence, index) => (
                <React.Fragment key={index}>
                  <Flex vertical gap={20} className="lesson-chunk-container">
                    <TextArea
                      spellCheck={false}
                      readOnly={videoStages.includes(currentStatus)}
                      autoSize={{ minRows: 5, maxRows: 6 }}
                      value={sentence.text}
                      onChange={(e) => handleSentenceChange(index, e)}
                    />

                    {sentence?.url ? (
                      <>
                        <Flex gap={10} align="center">
                          <ReactAudioPlayer
                            src={sentence?.url} // Ensure you're accessing the correct property (url)
                            ref={(element) =>
                              (audioRefs.current[index] = element)
                            }
                            onPlay={() => handlePlay(index)}
                            controls
                            controlsList="noplaybackrate nodownload"
                            style={{
                              // display: currentAudio === index ? "block" : "none",
                              minHeight: 54,
                            }}
                          />
                          {!videoStages.includes(currentStatus) && (
                            <Button
                              gap={10}
                              onClick={() => handleRefresh(index)}
                              style={{
                                cursor: "pointer",
                                display: "flex",
                              }}
                            >
                              <ReloadOutlined
                                style={{
                                  fontSize: "20px",
                                  cursor: "pointer",
                                }}
                              />
                              <span>Regenerate</span>
                            </Button>
                          )}
                          {!videoStages.includes(currentStatus) && (
                            <Flex gap={10} align="center">
                              <Button
                                onClick={() => {
                                  setIsModalOpen(true);
                                  setupdateItem({
                                    item: index,
                                    settings: sentence?.voiceSettings,
                                  });
                                }}
                              >
                                Voice Settings
                              </Button>
                            </Flex>
                          )}
                        </Flex>
                      </>
                    ) : (
                      <Flex gap={20}>
                        <LoadingOutlined
                          disabled
                          style={{
                            fontSize: "20px",
                            cursor: "not-allowed",
                            marginLeft: "10px",
                          }}
                        />
                        <span className="generating">Generating Audio...</span>
                      </Flex>
                    )}
                  </Flex>
                </React.Fragment>
              ))}
            </Flex>{" "}
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Flex
              flex={1}
              className="course-detail-footer"
              justify="space-between"
            >
              {scriptUpdateStages.includes(currentStatus) &&
                currentStatus !== "AUDIO_GENERATION_INPROGRESS" && (
                  <Button onClick={() => setContentStep((prev) => prev - 1)}>
                    Back
                  </Button>
                )}
              {currentStatus === "AUDIO_GENERATION_COMPLETED" && (
                <Button
                  type="primary"
                  onClick={() => setContentStep((prev) => prev + 1)}
                >
                  Generate Video
                </Button>
              )}
              {videoStages.includes(currentStatus) && (
                <Button type="primary" onClick={() => setContentStep((prev) => prev + 1)}>
                  Next
                </Button>
              )}
            </Flex>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default LessonAudio;
