import { Button, Flex, Modal } from "antd";
import React from "react";
import "./modal.css";
import { useNavigate } from "react-router-dom";

const ExpiredModal = ({ isModalOpen, usedToken, invalid }) => {
  const navigate = useNavigate();
  return (
    <Modal
      centered
      width={450}
      title={usedToken || invalid ? "Invalid Link" : "Token Expired"}
      open={isModalOpen}
      footer={() => (
        <Flex justify="flex-end">
          <Button type="primary" onClick={() => navigate("/login")}>
            Back To Login
          </Button>
        </Flex>
      )}
    >
      <div className="title-input-wrapper">
        <span className="input-label">
          {usedToken
            ? "Invalid! This link has already been used."
            : invalid
            ? "Invalid! This link is incorrect or expired."
            : "Oops! The password reset token has expired. Please request a new one."}
        </span>
      </div>
    </Modal>
  );
};

export default ExpiredModal;
