import { Button, Pagination } from "antd";
import React from "react";
import "./customPagination.css";
import { ReactComponent as Prev } from "../../assets/images/leftArrow.svg";
import { ReactComponent as Next } from "../../assets/images/rightArrow.svg";

const CustomPagination = ({ currentPage, pageSize, setCurrentPage, total }) => {
  const onCurrentPageChange = (page) => {
    setCurrentPage(page);
  };
  const totalPages = Math.ceil(total / 8);
  const itemRender = (current, type, originalElement) => {
    if (type === "next") {
      return null;
    } else if (type === "prev") {
      return null;
    }
    return originalElement;
  };
  return (
    <div className="pagination-wrapper">
      <Button
        onClick={() => setCurrentPage((prev) => prev - 1)}
        disabled={currentPage === 1}
        icon={<Prev />}
      >
        Previous
      </Button>
      <div className="pagination-container">
        <Pagination
          showLessItems={true}
          className="pagination-item"
          current={currentPage}
          pageSize={pageSize}
          onChange={onCurrentPageChange}
          total={total}
          itemRender={itemRender}
          hideOnSinglePage={true}
          showSizeChanger={false}
        />
      </div>
      <Button
        onClick={() => setCurrentPage((prev) => prev + 1)}
        disabled={currentPage === totalPages}
        className="next"
        icon={<Next />}
      >
        Next
      </Button>
    </div>
  );
};

export default CustomPagination;
