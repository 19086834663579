import { Button, Checkbox, Divider, Drawer, Empty, Input } from "antd";
import React, { useEffect, useState } from "react";
import "./modal.css";
import { getCategories } from "../../api/adminService";

const FilterDrawer = ({
  isDrawerOpen,
  handleCancel,
  setfilter,
  typeFilter,
  statusFilter,
  categoryFilter = [],
  all = true,
  setJourneyPage,
}) => {
  const [categories, setCategories] = useState([]);
  const [type, setType] = useState(typeFilter ?? "");
  const [status, setStatus] = useState(statusFilter ?? "");
  const [loading, setLoading] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const [category, setCategory] = useState(categoryFilter ?? []);

  const [currentPage, setcurrentPage] = useState(1);
  const [total, setTotal] = useState();
  const [initial, setinitial] = useState(true);
  let debounceTimeout;
  const debouncedSearch = (input) => {
    clearTimeout(debounceTimeout);

    debounceTimeout = setTimeout(() => {
      setcurrentPage(1);
      setSearchKey(input);
    }, 500);
  };

  const onSubmit = () => {
    setfilter({
      type: type,
      status: status,
      category: category,
      active: type || status || category.length > 0,
    });
    setJourneyPage(1);
    handleCancel();
  };
  const onSearch = (event) => {
    debouncedSearch(event.target.value);
  };
  const onCategoryChange = (value) => {
    setCategory(value);
  };
  const onModalClose = () => {
    handleCancel();
  };
  const onClearFilter = () => {
    setType("");
    setStatus("");
    setCategory([]);
    setfilter({ type: "", status: "", category: [], active: false });
  };
  const fetchCategories = () => {
    getCategories(searchKey, "", "", currentPage)
      .then((res) => {
        const responseData = res?.data.map((item) => ({
          label: item.name,
          value: item._id,
        }));
        if (initial || ((searchKey || searchKey === "") && currentPage === 1)) {
          setCategories(responseData);
        } else {
          setCategories((prevCategories) => [
            ...prevCategories,
            ...responseData,
          ]);
        }
        setTotal(res?.info?.totalCount);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
    setinitial(false);
  };
  useEffect(() => {
    setLoading(true);
    fetchCategories();
  }, [currentPage, searchKey]);
  const onscroll = (e) => {
    const { target } = e;
    const bottom =
      target.scrollHeight - target.scrollTop <= target.clientHeight + 1;
    if (bottom && categories?.length < total && !loading) {
      setcurrentPage((prev) => prev + 1);
    }
  };
  return (
    <Drawer
      width={450}
      title="Filter"
      open={isDrawerOpen}
      onClose={onModalClose}
      footer={
        <div className="action-buttons">
          <Button type="primary" onClick={onSubmit}>
            Apply Filter
          </Button>
          <Button className="cancel-button" onClick={onClearFilter}>
            Clear all
          </Button>
        </div>
      }
    >
      <div className="drawer-content-wrapper">
        <div className="type-filter">
          <h3>Type</h3>
          <div className="type-filter-buttons">
            <Button
              type={`${type === "Pre_Defined" ? "primary" : "default"}`}
              onClick={() => setType("Pre_Defined")}
            >
              Pre-Defined
            </Button>
            <Button
              type={`${type === "Fixed" ? "primary" : "default"}`}
              onClick={() => setType("Fixed")}
            >
              Fixed Journeys
            </Button>
          </div>
          <Divider />
        </div>
        <div className="type-filter">
          <h3>Status</h3>
          <div className="type-filter-buttons">
            <Button
              type={`${status === "DRAFT" ? "primary" : "default"}`}
              onClick={() => setStatus("DRAFT")}
            >
              DRAFT
            </Button>
            <Button
              type={`${status === "PUBLISHED" ? "primary" : "default"}`}
              onClick={() => setStatus("PUBLISHED")}
            >
              PUBLISHED
            </Button>
          </div>
          <Divider />
        </div>
        {all && (
          <div className="category-filter">
            <h3>Categories</h3>
            <Input
              placeholder="Search Categories"
              onChange={(e) => onSearch(e)}
            />
            <div className="categories" onScroll={onscroll}>
              {categories?.length > 0 ? (
                <Checkbox.Group
                  options={categories}
                  onChange={onCategoryChange}
                  value={category}
                />
              ) : (
                <Empty description={loading ? "Loading..." : "No catgories"} />
              )}
              {loading && !initial && currentPage !== 1 && (
                <span>Loading...</span>
              )}
            </div>
          </div>
        )}
      </div>
    </Drawer>
  );
};

export default FilterDrawer;
