import React from "react";
import { ConfigProvider, Empty, Table } from "antd";
import "./categoriesList.css";
import { useNavigate } from "react-router-dom";
const columns = (handleCategoryClick) => [
  {
    title: "Name",
    dataIndex: "name",
    width: "25%",
    render: (text, record) => (
      <span
        style={{ fontWeight: "bold", cursor: "pointer", color: "black"}}
        onClick={() => handleCategoryClick(record)}
      >
        {text}
      </span>
    ),
  },
  {
    title: "Status",
    dataIndex: "status",
    width: "20%",
  },
  {
    title: "Created by",
    dataIndex: "createdBy", 
    width: "25%",
  },
  {
    title: "Created at",
    dataIndex: "createdAt",
    width: "20%",
  },
  {
    title: "",
    dataIndex: "action",
    width: "10%",
  },
];
const CategoriesTable = ({
  data,
  loading,
  selectedRowKeys,
  setSelectedRowKeys,
}) => {
  const navigate = useNavigate()
  const handleCategoryClick = (item) => {
    navigate(`/${item.key}`);
  };
  return (
    <div className="category-content-list">
      <ConfigProvider
        theme={{
          token: {
            controlInteractiveSize: 20,
          },
          components: {
            Table: {
              headerBorderRadius: "8px",
              headerColor: "#6A6A6A",
            },
            // Checkbox: {
            //   colorPrimary: "#6A6A6A",
            //   colorPrimaryHover: "#6A6A6A",
            // },
          },
        }}
      >
        <Table
          pagination={false}
          columns={columns(handleCategoryClick)}
          // rowSelection={rowSelection}
          // columns={columns}
          dataSource={data}
          // scroll={{ y: 400 }}
          loading={{
            spinning: data?.length > 0 ? loading : false,
            indicator: (
              <div style={{ height: 40, width: 40 }} className="loader"></div>
            ),
          }}
          locale={{
            emptyText: loading ? (
              <Empty description="Loading...." />
            ) : (
              <Empty description="No Categories Found" />
            ),
          }}
        />
      </ConfigProvider>
    </div>
  );
};
export default CategoriesTable;
