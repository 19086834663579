import React from 'react'
import DIYSteps from '../../components/generateDIYVideo/diySteps/diySteps'

const DIYDemoVideo = () => {
  return (
    <DIYSteps/>
    // <></>
  )
}

export default DIYDemoVideo
