import { useEffect, useState } from "react";
import { Modal, Button, Row, Col, Card } from "antd";
import { getAvatarForVideo } from "../../api/adminService";
import "./modal.css";

const AvatarProfileModal = ({ visible, onClose, onSelect }) => {
  const [avatarProfiles, setAvatarProfiles] = useState([]);
  const [selectedAvatar, setSelectedAvatar] = useState(null);

  useEffect(() => {
    const fetchAudioProfiles = async () => {
      try {
        const res = await getAvatarForVideo();
        setAvatarProfiles(res.data);
      } catch (error) {
        console.error("Error fetching audio profiles:", error);
      }
    };
    fetchAudioProfiles();
  }, []);

  const handleSelectAvatar = (profile) => {
    if (selectedAvatar?.avatarId === profile.avatarId) {
      setSelectedAvatar(null);
    } else {
      setSelectedAvatar(profile);
    }
  };

  const handleContinue = () => {
    if (selectedAvatar) {
      onSelect(selectedAvatar);
      onClose();
    }
  };

  return (
    <>
      <Modal
        title="Avatar Profiles"
        visible={visible}
        onCancel={onClose}
        footer={[
          <Button key="cancel" onClick={onClose}>
            Cancel
          </Button>,
          <Button
            key="continue"
            type="primary"
            onClick={handleContinue}
            disabled={!selectedAvatar}
          >
            {selectedAvatar ? "Continue" : "Select"}
          </Button>,
        ]}
        width={800}
        bodyStyle={{
          maxHeight: "500px",
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        <Row gutter={[16, 16]} className="avatar-profiles-row">
          {avatarProfiles.map((profile) => (
            <Col key={profile?.avatarId} span={12} className="avatar-col">
              <Card
                hoverable
                onClick={() => handleSelectAvatar(profile)}
                className={`avatar-card ${
                  selectedAvatar?.avatarId === profile?.avatarId
                    ? "selected"
                    : ""
                }`}
              >
                <img
                  alt={profile?.name}
                  src={profile?.thumbnail}
                  className="avatar-image"
                />
                <Card.Meta title={profile?.name} className="avatar-meta" />
              </Card>
            </Col>
          ))}
        </Row>
      </Modal>
    </>
  );
};

export default AvatarProfileModal;
