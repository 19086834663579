import { Button, Checkbox, Divider, Drawer, Empty, Input } from "antd";
import React, { useEffect, useState } from "react";
import "./modal.css";
import { getOrganizations } from "../../api/adminService";

const UserFilterDrawer = ({
  isDrawerOpen,
  handleCancel,
  setfilter,
  roleFilter,
  orgRoleFilter,
  statusFilter,
  categoryFilter = [],
  all = true,
  setJourneyPage,
}) => {
  const [categories, setCategories] = useState([]);
  const [role, setRole] = useState(roleFilter ?? "");
  const [orgRole, setOrgRole] = useState(orgRoleFilter ?? "");
  const [status, setStatus] = useState(statusFilter ?? "");
  const [loading, setLoading] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const [category, setCategory] = useState(categoryFilter ?? []);

  const [currentPage, setcurrentPage] = useState(1);
  const [total, setTotal] = useState();
  const [initial, setinitial] = useState(true);
  let debounceTimeout;
  const debouncedSearch = (input) => {
    clearTimeout(debounceTimeout);

    debounceTimeout = setTimeout(() => {
      setcurrentPage(1);
      setSearchKey(input);
    }, 500);
  };

  const onSubmit = () => {
    setfilter({
      role: role,
      orgRole: orgRole,
      status: status,
      category: category,
      active: role || orgRole || status || category.length > 0,
    });
    setJourneyPage(1);
    handleCancel();
  };
  const onSearch = (event) => {
    debouncedSearch(event.target.value);
  };
  const onCategoryChange = (value) => {
    setCategory(value);
  };
  const onModalClose = () => {
    handleCancel();
  };
  const onClearFilter = () => {
    setRole("");
    setStatus("");
    setCategory([]);
    setfilter({ type: "", status: "", category: [], active: false });
  };
  const fetchOrganizations = () => {
    getOrganizations(searchKey, "", "", currentPage)
      .then((res) => {
        const responseData = res?.data.map((item) => ({
          label: item.name,
          value: item._id,
        }));
        if (initial || ((searchKey || searchKey === "") && currentPage === 1)) {
          setCategories(responseData);
        } else {
          setCategories((prevCategories) => [
            ...prevCategories,
            ...responseData,
          ]);
        }
        setTotal(res?.info?.totalCount);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
    setinitial(false);
  };
  useEffect(() => {
    setLoading(true);
    fetchOrganizations();
  }, [currentPage, searchKey]);
  const onscroll = (e) => {
    const { target } = e;
    const bottom =
      target.scrollHeight - target.scrollTop <= target.clientHeight + 1;
    if (bottom && categories?.length < total && !loading) {
      setcurrentPage((prev) => prev + 1);
    }
  };
  return (
    <Drawer
      width={450}
      title="Filter"
      open={isDrawerOpen}
      onClose={onModalClose}
      footer={
        <div className="action-buttons">
          <Button type="primary" onClick={onSubmit}>
            Apply Filter
          </Button>
          <Button className="cancel-button" onClick={onClearFilter}>
            Clear all
          </Button>
        </div>
      }
    >
      <div className="drawer-content-wrapper">
        <div className="type-filter">
          <h3>App Role</h3>
          <div className="type-filter-buttons">
            <Button
              type={`${role === "SUPER_ADMIN" ? "primary" : "default"}`}
              onClick={() => setRole("SUPER_ADMIN")}
            >
              Super Admin
            </Button>
            <Button
              type={`${role === "STAFF" ? "primary" : "default"}`}
              onClick={() => setRole("STAFF")}
            >
              Staff
            </Button>
          </div>
          <Divider />
        </div>
        <div className="type-filter">
          <h3>Organization Role</h3>
          <div className="type-filter-buttons">
            <Button
              type={`${orgRole === "ADMIN" ? "primary" : "default"}`}
              onClick={() => setOrgRole("ADMIN")}
            >
              Admin
            </Button>
            <Button
              type={`${orgRole === "JOURNEY_DESIGNER" ? "primary" : "default"}`}
              onClick={() => setOrgRole("JOURNEY_DESIGNER")}
            >
              Journey Designer
            </Button>
          </div>
          <Divider />
        </div>
        <div className="type-filter">
          <h3>Status</h3>
          <div className="type-filter-buttons">
            <Button
              type={`${status === "ACTIVE" ? "primary" : "default"}`}
              onClick={() => setStatus("ACTIVE")}
            >
              ACTIVE
            </Button>
            <Button
              type={`${status === "INACTIVE" ? "primary" : "default"}`}
              onClick={() => setStatus("INACTIVE")}
            >
              INACTIVE
            </Button>
            <Button
              type={`${status === "INVITED" ? "primary" : "default"}`}
              onClick={() => setStatus("INVITED")}
            >
              INVITED
            </Button>
          </div>
          <Divider />
        </div>
        {all && (
          <div className="category-filter">
            <h3>Organizations</h3>
            <Input
              placeholder="Search Organizations"
              onChange={(e) => onSearch(e)}
            />
            <div className="categories" onScroll={onscroll}>
              {categories?.length > 0 ? (
                <Checkbox.Group
                  options={categories}
                  onChange={onCategoryChange}
                  value={category}
                />
              ) : (
                <Empty description={loading ? "Loading..." : "No catgories"} />
              )}
              {loading && !initial && currentPage !== 1 && (
                <span>Loading...</span>
              )}
            </div>
          </div>
        )}
      </div>
    </Drawer>
  );
};

export default UserFilterDrawer;
