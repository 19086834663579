import { Button, Modal } from "antd";
import React from "react";
import "./modal.css";

const PublishModal = ({ isModalOpen, handleOk, handleCancel,type="Journey" }) => {
  return (
    <Modal
      centered
      width={450}
      title="Confirm Publish"
      open={isModalOpen}
      onCancel={handleCancel}
      footer={() => (
        <div className="action-buttons">
          <Button type="primary" onClick={handleOk}>
            Yes, Publish
          </Button>
          <Button className="cancel-button shadow" onClick={handleCancel}>
            Cancel
          </Button>
        </div>
      )}
    >
      <div className="title-input-wrapper">
        <span className="input-label">
          Are you sure you want to Publish this {type}?
        </span>
      </div>
    </Modal>
  );
};

export default PublishModal;
