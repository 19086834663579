import React from "react";
import { ConfigProvider, Empty, Table } from "antd";
import "./customTable.css";
import CustomPagination from "../customPagination/customPagination";
const CustomTable = ({
  columns,
  data,
  loading,
  selectedRowKeys,
  setSelectedRowKeys,
  item,
  total,
  pageSize,
  setCurrentPage,
  currentPage,
  onRow,
}) => {
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  return (
    <div className="journey-wrapper">
      <div className="item-list">
        <ConfigProvider
          theme={{
            token: {
              controlInteractiveSize: 20,
            },
            components: {
              Table: {
                headerBorderRadius: "8px",
                headerColor: "#6A6A6A",
              },
              Checkbox: {
                colorPrimary: "#6A6A6A",
                colorPrimaryHover: "#6A6A6A",
              },
            },
          }}
        >
          <Table
            pagination={false}
            rowSelection={item === "Journey" ? rowSelection : false}
            columns={columns}
            dataSource={data}
            loading={{
              spinning: data?.length > 0 ? loading : false,
              indicator: (
                <div style={{ height: 40, width: 40 }} className="loader"></div>
              ),
            }}
            locale={{
              emptyText: loading ? (
                <Empty description="Loading...." />
              ) : (
                <Empty description={`No ${item} Found`} />
              ),
            }}
            onRow={onRow}
          />
        </ConfigProvider>
      </div>
      {total > pageSize && (
        <CustomPagination
          pageSize={pageSize}
          currentPage={currentPage}
          total={total}
          setCurrentPage={setCurrentPage}
        />
      )}
    </div>
  );
};
export default CustomTable;
