import { Button, Input, Modal, Radio, Select } from "antd";
import React, { useEffect, useState } from "react";
import "./modal.css";
import { getCategories, journeyConfig } from "../../api/adminService";
import { useParams } from "react-router-dom";
import NotificationInstance from "../../services/notificationServices";

const AddJourneyModal = ({
  isModalOpen,
  handleOk,
  handleCancel,
  categoryItem = "",
  journeyName = "",
  categorytype = "",
  categoryList = false,
}) => {
  const params = useParams();
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState({
    value: params.categoryId ? params.categoryId : null,
    error: false,
  });
  const [total, setTotal] = useState();
  const [currentPage, setcurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [initial, setinitial] = useState(true);
  const [journey, setJourney] = useState({
    value: journeyName ? journeyName : "",
    error: false,
  });
  const [type, setType] = useState({
    value: categorytype ? categorytype : "Pre_Defined",
    error: false,
  });
  const [disable, setDisable] = useState(false);
  const onSubmit = () => {
    if (category.value && journey.value) {
      setDisable(true);
      const request = {
        title: journey.value,
        type: type.value,
        categoryId: category.value,
        status: "DRAFT",
      };
      journeyConfig(request)
        .then((res) => {
          setDisable(false);
          NotificationInstance.success({
            message: "Successful",
            description: "Successfully created journey",
          });
          setCategory({ value: "", error: false });
          setJourney({ value: "", error: false });
          setType({ value: "Pre_Defined", error: false });
          handleOk(res?.data?._id, res?.data?.categoryId);
        })
        .catch((error) => {
          setDisable(false);
          NotificationInstance.error({
            message:
              error?.response?.data?.message ?? "Please verify and try again",
          });
        });
    } else {
      if (!journey.value) {
        setJourney({ ...journey, error: true });
      }
      if (!category.value) {
        setCategory({ ...category, error: true });
      }
      if (!type.value) {
        setType({ ...type, error: true });
      }
    }
  };
  const onTypeChange = (event) => {
    setType({
      value: event.target.value,
      error: event.target.value ? false : true,
    });
  };
  const onJourneyChange = (event) => {
    if (event.target.value.trim() !== "") {
      setJourney({
        value: event.target.value,
        error: event.target.value ? false : true,
      });
    } else {
      setJourney({
        value: "",
        error: true,
      });
    }
  };
  const onCategoryChange = (value) => {
    setCategory({
      value: value,
      error: value ? false : true,
    });
  };
  const onModalClose = () => {
    setType({ value: "Pre_Defined", error: false });
    setJourney({ value: "", error: false });
    setCategory({
      value: params.categoryId ? params.categoryId : null,
      error: false,
    });
    handleCancel();
  };
  const fetchCategories = () => {
    getCategories("", "", "", currentPage)
      .then((res) => {
        const responseData = res?.data.map((item) => ({
          label: item.name,
          value: item._id,
        }));
        if (initial) {
          setCategories(responseData);
        } else {
          setCategories((prevCategories) => [
            ...prevCategories,
            ...responseData,
          ]);
        }
        setTotal(res?.info?.totalCount);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
    setinitial(false);
  };
  useEffect(() => {
    setLoading(true);
    fetchCategories();
  }, [currentPage]);
  useEffect(() => {
    if (params.categoryId) {
      setCategory({
        value: params.categoryId,
        error: false,
      });
    }
  }, [params]);
  const onscroll = (e) => {
    const { target } = e;
    const bottom =
      target.scrollHeight - target.scrollTop === target.clientHeight;
    if (bottom && categories?.length < total) {
      setcurrentPage((prev) => prev + 1);
    }
  };
  return (
    <Modal
      centered
      width={450}
      title="Create New Journey"
      open={isModalOpen}
      onCancel={onModalClose}
      footer={() => (
        <div className="action-buttons">
          <Button type="primary" onClick={onSubmit} disabled={disable}>
            Create journey
          </Button>
          <Button className="cancel-button" onClick={onModalClose}>
            Cancel
          </Button>
        </div>
      )}
    >
      <div className="title-input-wrapper">
        <span className="input-label">Journey Name</span>
        <Input
          value={journey.value}
          placeholder="Enter journey Name"
          onChange={(e) => onJourneyChange(e)}
        />
        {journey.error && (
          <span className="error-message">Please enter a journey name</span>
        )}
        <span className="input-label">Journey Type</span>
        <Radio.Group onChange={onTypeChange} value={type.value}>
          <div
            className={`journey-type ${
              type.value === "Pre_Defined" && "active"
            }`}
            onClick={() =>
              setType({
                value: "Pre_Defined",
                error: false,
              })
            }
          >
            <Radio value="Pre_Defined">Pre-defined</Radio>
          </div>
          <div
            className={`journey-type ${type.value === "Fixed" && "active"}`}
            onClick={() =>
              setType({
                value: "Fixed",
                error: false,
              })
            }
          >
            <Radio value="Fixed">Fixed</Radio>
          </div>
        </Radio.Group>
        {!categoryList && (
          <>
            <span className="input-label">Category</span>
            <Select
              placeholder="Select a category"
              optionFilterProp="children"
              onChange={onCategoryChange}
              variant="borderless"
              popupMatchSelectWidth={true}
              allowClear
              popupClassName="sort-options"
              options={categories}
              onPopupScroll={onscroll}
              value={category.value}
              loading={loading}
            />
            {category.error && (
              <span className="error-message">Please select a category</span>
            )}
          </>
        )}
      </div>
    </Modal>
  );
};

export default AddJourneyModal;
