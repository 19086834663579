import { Flex } from "antd";
import React from "react";
import "./placeholder.css";

const PlaceHolder = () => {
  return (
    <Flex className="warning-message" justify="center" align="center">
      <h1>
        This application is not supported on smaller screen sizes. Please access
        it on a device with a larger screen for the best experience.
      </h1>
    </Flex>
  );
};

export default PlaceHolder;
