import React from "react";
import { ConfigProvider, Empty, Table } from "antd";
import "./categoryJourneyList.css";
const columns = [
  {
    title: "Name",
    dataIndex: "name",
    width: "30%",
  },
  {
    title: "Last Updated",
    dataIndex: "lastUpdated",
    width: "15%",
  },
  {
    title: "Created on",
    dataIndex: "createdOn",
    width: "15%",
  },
  {
    title: "Status",
    dataIndex: "status",
    width: "15%",
  },
  {
    title: "Type",
    dataIndex: "type",
    width: "15%",
  },
  {
    title: "",
    dataIndex: "action",
    width: "10%",
  },
];
const CategoryJourneyTable = ({
  data,
  loading,
  selectedRowKeys,
  setSelectedRowKeys,
}) => {
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  return (
    <div className="career-journey-list">
      <ConfigProvider
        theme={{
          token: {
            controlInteractiveSize: 20,
          },
          components: {
            Table: {
              headerBorderRadius: "0px",
              headerColor: "#6A6A6A",
            },
            Checkbox: {
              colorPrimary: "#6A6A6A",
              colorPrimaryHover: "#6A6A6A",
            },
          },
        }}
      >
        <Table
          pagination={false}
          rowSelection={rowSelection}
          columns={columns}
          dataSource={data}
          loading={{
            spinning: data?.length > 0 ? loading : false,
            indicator: (
              <div style={{ height: 40, width: 40 }} className="loader"></div>
            ),
          }}
          locale={{
            emptyText: loading ? (
              <Empty description="Loading...." />
            ) : (
              <Empty description="No Journeys Found" />
            ),
          }}
        />
      </ConfigProvider>
    </div>
  );
};
export default CategoryJourneyTable;
