import { Button, Col, ConfigProvider, Flex, Row } from "antd";
import React, { useEffect, useState } from "react";
import "./DIYDemo.css";
import { PRIMARY_COLOR } from "../../utils/colors";
import { ArrowRightOutlined } from "@ant-design/icons";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { getDemoUrl, verifyDemoToken } from "../../api/hubSpotService";
import { diy } from "../../components/generateDIYVideo/diy";
import Logo from "../../assets/images/leaplerning.png";

const DIYDemo = () => {
  const navigate = useNavigate();
  // const [searchParams] = useSearchParams();
  // const demoId = searchParams.get("demoId");
  // const token = searchParams.get("referenceToken");
  const [verifying, setVerifying] = useState(false);
  const [valid, setValid] = useState(false);
  const { language } = useParams();
  const [lang, setLang] = useState(language === "de" ? "de" : "en");

  // useEffect(() => {
  //   if (demoId && token) {
  //     setVerifying(true);
  //     verifyDemoToken(demoId, token)
  //       .then((res) => {
  //         console.log(res);
  //         setVerifying(false);
  //         setValid(true);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //         setVerifying(false);
  //         setValid(false);
  //       });
  //   }
  // }, [demoId, token]);

  // const renderInvalidLink = () => {
  //   return (
  //     <Flex vertical gap={30} className="diy-content" justify="center">
  //       <h1 className="diy-title">{diy[lang].invalid}</h1>
  //     </Flex>
  //   );
  // };

  const onClick = () => {
    // navigate(`/demo/${lang}/${demoId}?referenceToken=${token}`, {
    //   state: { verified: true },
    // })
    setVerifying(true);
    getDemoUrl()
      .then((res) => {
        console.log(res);
        const parsedUrl = new URL(res?.data?.demoUrl);
        const searchParams = new URLSearchParams(parsedUrl?.search);
        const demoId = searchParams.get("demoId");
        const referenceToken = searchParams.get("referenceToken");
        navigate(`/demo/${lang}/${demoId}?referenceToken=${referenceToken}`, {
          state: { verified: true },
        });
        setVerifying(false);
      })
      .catch((err) => {
        console.log(err);
        setVerifying(false);
      });
  };

  return (
    <ConfigProvider
      theme={{
        token: {
          Button: {
            colorBgContainer: PRIMARY_COLOR,
            colorText: "#ffffff",
            colorPrimaryHover: "#ffffff",
            colorPrimaryActive: "#ffffff",
            primaryShadow: "none",
            controlHeight: 60,
            fontSize: 16,
            fontWeight: 600,
            borderRadis: 8,
          },
        },
      }}
    >
      <div className="diy-bg" />
      <Row className="diy-home" align="center">
        <Col xl={{ span: 9 }} md={{ span: 15 }} sm={{ span: 20 }}>
          {/* {demoId && token ? (
            verifying ? ( */}
          {/* <Flex
                vertical
                gap={30}
                className="diy-content"
                justify="center"
                align="center"
              >
                <div className="loader-container">
                  <div className="loader"></div>
                </div>
              </Flex>
            ) : valid ? ( */}
          <Flex vertical gap={50} className="diy-content" justify="center">
            <Flex justify="center" align="center" className="diy-logo-container">
              {/* <Logo /> */}
              <img src={Logo} alt="" className="leap-learning"/>
            </Flex>
            <Flex vertical gap={30} justify="center">
              <div>
                <h1 className="diy-title">{diy[lang].title}</h1>
                <p
                  className="diy-detail"
                  dangerouslySetInnerHTML={{ __html: diy[lang].description }}
                />
              </div>
              <Button onClick={onClick} className="question-actions">
                {verifying ? (
                  <div className="chat-loader"></div>
                ) : (
                  <Flex align="center" justify="center" gap={5}>
                    <span>{diy[lang].button.start}</span>
                    <ArrowRightOutlined />
                  </Flex>
                )}
              </Button>
            </Flex>
          </Flex>
          {/* ) : (
              renderInvalidLink()
            )
          ) : (
            renderInvalidLink()
          )} */}
        </Col>
      </Row>
    </ConfigProvider>
  );
};

export default DIYDemo;
