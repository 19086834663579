import React from "react";
import { useLocation } from "react-router-dom";
import { Breadcrumb, Flex } from "antd";
import { ReactComponent as HomeOutlined } from "../../assets/images/home.svg";

const Breadcrumbs = ({ displayName = "Journey", config = false }) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const pathSnippets = [];
  for (const segment of location?.pathname.split("/").filter((i) => i) || []) {
    pathSnippets.push(segment);
    if (segment === "edit" || segment === "view" || segment === "create") {
      break;
    }
  }
  const breadcrumbItems = [
    {
      href: "/",
      title: (
        <Flex
          align="center"
          justify="center"
          className="home-breadcrumb"
        >
          <HomeOutlined />
        </Flex>
      ),
    },
  ];
  let currentPath = "/";
  pathSnippets?.forEach((snippet, index) => {
    if (snippet === "edit" || snippet === "view" || snippet === "create") {
      currentPath = location.pathname;
    } else {
      currentPath += `${snippet}/`;
    }
    breadcrumbItems.push({
      href:
        index === pathSnippets.length - 1
          ? currentPath +
            (queryParams.toString() ? `?${queryParams.toString()}` : "")
          : currentPath,
      title: config ? displayName[index] : displayName,
    });
  });

  return <Breadcrumb className="breadcrumb-wrapper" items={breadcrumbItems} />;
};

export default Breadcrumbs;
