import React, { useCallback, useEffect, useState } from "react";
import "./courseList.css";
import { Button, Flex } from "antd";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import { timeCreatedOn } from "../../utils/format";
import { getAllCourses } from "../../api/adminService";
import CustomTable from "../../components/customTable/customTable";
import CreateCourseModal from "../../components/modals/createCourseModal";

const CourseVideoList = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [, , setDisplayName] = useOutletContext();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(8);

  const [total, setTotal] = useState();
  useEffect(() => {
    setDisplayName(["Courses"]);
  }, []);
  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (text, record) => (
        <Link to={`/courses/${record?._id}`}>
          <span className="course-title">{text}</span>
        </Link>
      ),
      width: "60%",
    },
    {
      title: "Created By",
      dataIndex: "createdByStaff",
      key: "createdBy",
      render: (text) => <p>{`${text?.firstName} ${text?.lastName}`}</p>,
      width: "20%",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => <p>{timeCreatedOn(text)}</p>,
      width: "20%",
    },
  ];

  // const fetchCourseList = async () => {
  //   setIsLoading(true);
  //   getAllCourses(currentPage, pageSize)
  //     .then((res) => {
  //       setData(res?.data);
  //       setIsLoading(false);
  //       setTotal(res?.info?.totalCount);
  //       console.log("here", res);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       setIsLoading(false);
  //     });
  // };

  const handleAddVideo = () => {
    // navigate("/courses/create");
    setIsModalOpen(true);
  };

  const handleOk = (id, type) => {
    navigate(`/courses/${id}`);
    handleCancel();
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const fetchCourseList = useCallback(async () => {
    setIsLoading(true);
    try {
      const res = await getAllCourses(currentPage, pageSize);
      setData(res?.data);
      setTotal(res?.info?.totalCount);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  }, [currentPage, pageSize]);

  useEffect(() => {
    fetchCourseList();
  }, [fetchCourseList]);

  return (
    <>
      <Flex justify="space-between" align="center" gap={20}>
        <div className="journey-title">
          <h1>All Courses</h1>
        </div>

        <Button
          className="add-training-video"
          type="primary"
          onClick={handleAddVideo}
        >
          Create New Course
        </Button>
        <CreateCourseModal
          isModalOpen={isModalOpen}
          handleCancel={handleCancel}
          handleOk={handleOk}
        />
      </Flex>
      <CustomTable
        data={data}
        loading={isLoading}
        columns={columns}
        total={total}
        pageSize={pageSize}
        item={"Course"}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
      />
    </>
  );
};

export default CourseVideoList;
