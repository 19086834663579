import { Button, Modal } from "antd";
import React from "react";
import "./modal.css";

const UnSavedModal = ({
  isModalOpen,
  handleOk,
  handleCancel,
  handleDiscard,
}) => {
  return (
    <Modal
      centered
      width={450}
      title="Unsaved Changes"
      open={isModalOpen}
      onCancel={handleCancel}
      footer={() => (
        <div className="action-buttons">
          <Button type="primary" onClick={handleOk}>
            Save
          </Button>
          <Button className="cancel-button shadow" onClick={handleCancel}>
            Cancel
          </Button>
          <Button className="discard-button" onClick={handleDiscard}>
            Discard
          </Button>
        </div>
      )}
    >
      <div className="title-input-wrapper">
        <span className="input-label">
          You have unsaved changes on this page. Are you sure you want to leave?
        </span>
      </div>
    </Modal>
  );
};

export default UnSavedModal;
