import React from "react";
import { ReactComponent as Delete } from "../../assets/images/trash.svg";
import { ReactComponent as Edit } from "../../assets/images/editPen.svg";
import "./action.css";
import { Tooltip } from "antd";
const TableActions = ({ handleEdit, handleDelete }) => {
  return (
    <div className="table-action">
      <Tooltip title="Delete Journey">
        <Delete onClick={handleDelete} />
      </Tooltip>
      <Tooltip title="Edit Journey">
        <Edit onClick={handleEdit}></Edit>
      </Tooltip>
    </div>
  );
};

export default TableActions;
