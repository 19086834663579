import React, { useState } from "react";

const AuthContext = React.createContext({
  token: null,
  refresh_token: null,
  login: (token) => {},
  logout: () => {},
});

export const AuthContextProvider = (props) => {
  const initialToken = localStorage.getItem("access_token");
  if (initialToken === "undefined") {
    localStorage.removeItem("access_token");
  }
  const [tokens, setTokens] = useState(initialToken);
  const logoutHandler = () => {
    setTokens(null);
    window.location.pathname = "/login";
    localStorage.clear();
  };
  const loginHandler = (token) => {
    setTokens(token);
  };
  const authContextValue = {
    token: tokens,
    login: loginHandler,
    logout: logoutHandler,
  };
  return (
    <AuthContext.Provider value={authContextValue}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;

export const getToken = () => {
  if (typeof window !== "undefined") {
    return localStorage.getItem("access_token");
  }
};
export const setToken = (accessToken = "", refreshToken = "") => {
  if (typeof window !== "undefined") {
    localStorage.setItem("access_token", accessToken);
    localStorage.setItem("refresh_token", refreshToken);
  }
};
export const logout = () => {
  window.location.pathname = "/login";
  localStorage.clear();
};